import { takeLatest, put } from "redux-saga/effects";

import {
  TRIGGER_FETCH_PRODUCTS_DETAILS,
  TriggerFetchProductsDetails,

  RESET_SHOPPING_CART_VIEW
} from "./types";

import {
  fetchProductsDetailsPending,
  fetchProductsDetailsSuccess,
  fetchProductsDetailsError,
} from "./actions";

import { createCancellableSaga } from "../../CancellableSaga";
import { StoreProductDetails } from "../../../entities/StoreProductDetails";
import { productRepository } from "../../../repositories";



function* handleTriggerFetchProductsDetails(action: TriggerFetchProductsDetails) {
  const { payload } = action;

  yield put(fetchProductsDetailsPending());

  try {
    const productsDetails: StoreProductDetails[] = yield productRepository.getProductsDetails(payload);

    yield put(fetchProductsDetailsSuccess({
      productsDetails: productsDetails
    }));
  } catch (err) {
    yield put(fetchProductsDetailsError());
    return;
  }
}



export function* watchShoppingCartView() {
  yield takeLatest(TRIGGER_FETCH_PRODUCTS_DETAILS, handleTriggerFetchProductsDetails);
}


export const shoppingCartViewSaga = createCancellableSaga(watchShoppingCartView, RESET_SHOPPING_CART_VIEW);