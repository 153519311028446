import React, { useState } from 'react';
import { ColorTheme } from '../../styling/constants';
import { SummaryCard, CardRow, LabelValue } from '../SummaryCard';
import { CartItem } from '../../store/cart/types';
import { getFormattedPrice } from '../../util/currencyUtil';

interface Props {
  cartItems: CartItem[];
}

export default function OrderSummaryContent(props: Props) {
  const { cartItems } = props;

  let priceTotal = 0;
  let itemsTotal = 0;

  cartItems.map(cartItem => {
    const { quantity, productPrice } = cartItem;

    priceTotal += productPrice * quantity;
    itemsTotal += quantity;
  });

  const { currencyCode } = cartItems[0];

  return (
    <SummaryCard>

      <CardRow>
        <LabelValue>
          <h6>Order Summary</h6>
          <h6>{itemsTotal} Item(s)</h6>
        </LabelValue>
      </CardRow>

      <CardRow>
        <LabelValue>
          <p>Subtotal</p>
          <p>{getFormattedPrice(priceTotal, currencyCode)}</p>
        </LabelValue>
      </CardRow>

      <CardRow>
        <LabelValue>
          <p>Delivery Charges:</p>
          <p>Add your delivery address at checkout to see delivery charges</p>
        </LabelValue>
      </CardRow>

      <CardRow>
        <LabelValue>
          <h4>Total</h4>
          <h4>{getFormattedPrice(priceTotal, currencyCode)}</h4>
        </LabelValue>
      </CardRow>

    </SummaryCard>
  );
}