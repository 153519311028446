import { takeLatest, put } from "redux-saga/effects";
import jwt_decode from 'jwt-decode';

import {
  TRIGGER_HYDRATE_AUTH_STORE,
  TriggerHydrateAuthStore,

  TRIGGER_SET_AUTH_STATE,
  TriggerSetAuthState,

  TRIGGER_CLEAR_AUTH_STATE,
  TriggerClearAuthState,

  DecodedTokenParams
} from "./types";

import {
  setAuthState,
  clearAuthState,
} from "./actions";
import { TokenStorageManager } from "../../../core/api/TokenStorageManager";


function* handleTriggerHydrateAuthStore(action: TriggerHydrateAuthStore) {
  const token = TokenStorageManager.getToken();

  if (!token) return;

  try {
    const decoded: DecodedTokenParams = jwt_decode(token);
    const { email, mobileNo } = decoded;

    yield put(setAuthState({
      email, mobileNo
    }));
  } catch (err) {
    TokenStorageManager.deleteToken();
  }
}

function* handleTriggerSetAuthState(action: TriggerSetAuthState) {
  const { payload } = action;
  const { accessToken, userProfile } = payload;

  TokenStorageManager.setToken(accessToken);

  yield put(setAuthState(userProfile));
}

function* handleTriggerClearAuthState(action: TriggerClearAuthState) {
  TokenStorageManager.deleteToken();
  yield put(clearAuthState());
}



export function* authSaga() {
  yield takeLatest(TRIGGER_HYDRATE_AUTH_STORE, handleTriggerHydrateAuthStore);
  yield takeLatest(TRIGGER_SET_AUTH_STATE, handleTriggerSetAuthState);
  yield takeLatest(TRIGGER_CLEAR_AUTH_STATE, handleTriggerClearAuthState);
}
