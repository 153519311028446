import * as React from "react";
import styled from "styled-components";

import BundleLayout from "./components/BundleLayout";
import BundleHeader from "./components/BundleHeader";
import useGetProductBundle from "./hooks/useGetProductBundle";
import BundleProducts from "./components/BundleProducts";
import NotificationWidget from "components/NotificationWidget";
import LoadingIndicator from "components/LoadingIndicator";
import { ColorTheme } from "styling/constants";
import useGetBundleProducts from "./hooks/useGetBundleProducts";

export default function StoreProductBundle() {
  const {
    loading: loadingBundle,
    error: bundleError,
    response: productBundle,
    fetchBundle,
  } = useGetProductBundle();

  const {
    loading: loadingBundleProducts,
    error: bundleProductsError,
    bundleProducts,
    fetchBundleProducts,
    updateBundleProductPrice,
  } = useGetBundleProducts();

  const refetchData = React.useCallback(() => {
    if (bundleError) {
      fetchBundle();
    }
    if (bundleProductsError) {
      fetchBundleProducts();
    }
  }, [bundleError, fetchBundle, bundleProductsError, fetchBundleProducts]);

  if (loadingBundle || loadingBundleProducts)
    return (
      <Container>
        <LoadingIndicator size={40} color={ColorTheme.primaryColorDark} />
      </Container>
    );

  if (bundleError || bundleProductsError)
    return (
      <Container>
        <NotificationWidget
          type="ERROR"
          title="Something went wrong!"
          message="We couldn’t get the store's product bundle. Please refresh."
          actionText={"Refresh"}
          action={refetchData}
        />
      </Container>
    );

  return productBundle && bundleProducts ? (
    <BundleLayout
      header={
        <BundleHeader
          productBundle={productBundle}
          bundleProducts={bundleProducts}
        />
      }
    >
      <BundleProducts
        bundleProducts={bundleProducts}
        updateBundleProductPrice={updateBundleProductPrice}
      />
    </BundleLayout>
  ) : null;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 160px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
