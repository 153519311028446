import { SERVICE_BASE_URL } from '../config/properties';
import { HttpClient } from '../core/api/HttpClient';

export interface CustomerAddress {
  id: number;
  customerEmail: string;
  customerName: string;
  customerPhoneNumber: string;
  address: string;
  additionalAddressInfo: string;
  landmark: string;
  lga: string;
  state: string;
  country: string;
  area: string;
  isDefault: boolean;
  createdOn: string;
  updatedOn: string | null;
}

export interface GetCustomerAddressesRequest {
  email: string;
}

export interface GetCustomerAddressesResponse {
  statusCode: number;
  message: string;
  data: CustomerAddress[];
  validation: null;
}

export interface AddCustomerAddressRequest {
  country: string;
  state: string;
  area: string;
  address: string;
  customerName: string;
  customerEmail: string;
  customerPhoneNumber: string;

  additionalAddressInfo?: string;
  landmark?: string;
  isDefault?: boolean;
}

export interface AddCustomerAddressResponse {
  statusCode: number;
  message: string;
  data: CustomerAddress;
  validation: null
}

export interface UpdateCustomerAddressRequest extends CustomerAddress { }

export interface UpdateCustomerAddressResponse extends AddCustomerAddressResponse { }

export interface DefaultAddressRequest { }

export interface DefaultAddressResponse {
  statusCode: number;
  message: string;
  data: boolean;
  validation: null
}

export interface DeleteCustomerAddressRequest {
  id: number;
  customerEmail: string;
}

export interface DeleteCustomerAddressResponse {
  statusCode: number;
  message: string;
  data: boolean;
  validation: null
}


export interface ICustomerRepository {
  getCustomerAddresses(request: GetCustomerAddressesRequest): Promise<GetCustomerAddressesResponse>;
  addCustomerAddress(request: AddCustomerAddressRequest): Promise<AddCustomerAddressResponse>;
  updateCustomerAddress(request: UpdateCustomerAddressRequest): Promise<UpdateCustomerAddressResponse>;
  deleteCustomerAddress(request: DeleteCustomerAddressRequest): Promise<DeleteCustomerAddressResponse>;
  setDefaultCustomerAddress(request: DefaultAddressRequest): Promise<DefaultAddressResponse>;
}

export class CustomerRepository implements ICustomerRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getCustomerAddresses(
    request: GetCustomerAddressesRequest
  ): Promise<GetCustomerAddressesResponse> {
    const response = await this.httpClient.get(
      `${SERVICE_BASE_URL}/storefront/api/v1/customers/addresses/${request.email}`
    );

    return response.data
  }

  async addCustomerAddress(
    request: AddCustomerAddressRequest
  ): Promise<AddCustomerAddressResponse> {
    const response = await this.httpClient.post(
      `${SERVICE_BASE_URL}/storefront/api/v1/customers/addresses`,
      { ...request, isDefault: false}
    );

    return response.data
  }

  async updateCustomerAddress(
    request: UpdateCustomerAddressRequest
  ): Promise<UpdateCustomerAddressResponse> {
    const response = await this.httpClient.put(
      `${SERVICE_BASE_URL}/storefront/api/v1/customers/addresses`,
      request
    );

    return response.data
  }

  async deleteCustomerAddress(
    request: DeleteCustomerAddressRequest
  ): Promise<DeleteCustomerAddressResponse> {
    const response = await this.httpClient.delete(
      `${SERVICE_BASE_URL}/storefront/api/v1/customers/addresses`,
      { data: request }
    );

    return response.data
  }

  async setDefaultCustomerAddress(request: DefaultAddressRequest): Promise<DefaultAddressResponse> {
    const response = await this.httpClient.put(
      `${SERVICE_BASE_URL}/storefront/api/v1/customers/addresses/default`,
      request
    );

    return response.data
  }
}

