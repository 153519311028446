const TOKEN_KEY = 'accessToken';

export class TokenStorageManager {
  static getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  static setToken(token: string) {
    return localStorage.setItem(TOKEN_KEY, token);
  }

  static deleteToken() {
    return localStorage.removeItem(TOKEN_KEY);
  }
}