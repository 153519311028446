export const regexBase = {
  onlyNumbers: /^[0-9]{1,}$/,
  amountRegex: /^[0-9]+(\.[0-9]{1,2})?$/,
  emailRegex: /^[\w!#$%&'*-/=?^`{|}~]+@([\w-]+\.)+[\w]{2,4}$/,
  wholeValueRegex: /^[0-9]+$/,
};

const path = window.location.hostname;

export function getPageTitlteSuffix(storeName: string) {
  return (path.includes('zivastores')) ? `${storeName} - Powered By Ziva Stores` : `${storeName} - Powered By Interswitch`;
}
