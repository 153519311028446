import React, { useState, CSSProperties } from "react";
import styled from "styled-components";

import { ReactComponent as ImagePlaceholder } from "./icons/image-placeholder-icon.svg";

const Container = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s linear;

  opacity: ${(props: { loaded: boolean }) => {
    return props.loaded ? 0 : 1;
  }};
  transition: opacity 0.3s linear;

  svg {
    width: 25%;
    max-width: 45px;
    fill: #aaa;
    opacity: 0.5;
  }
`;

interface ImageContainerProps {
  imageLoaded: boolean;
}

const ImageContainer = styled.div<ImageContainerProps>`
  display: ${({ imageLoaded }) => imageLoaded ? 'block' : 'none'};
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;


interface Props {
  src?: string;
  alt: string;
  imageStyle?: CSSProperties;
}

export default function AsyncImage(props: Props) {
  const { src, alt, imageStyle } = props;

  const [loaded, setLoaded] = useState(false);

  return (
    <Container>
      <Overlay loaded={loaded}>
        <ImagePlaceholder />
      </Overlay>

      <ImageContainer imageLoaded={loaded}>
        <img
          src={src}
          alt={alt}
          onLoad={() => setLoaded(true)}
          style={{ ...imageStyle }}
        />
      </ImageContainer>

    </Container>
  );
}
