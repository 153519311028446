import unionBy from 'lodash/unionBy';

import {
  RESTORE_CART_ITEMS,
  SET_CART_ITEMS,
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  SET_CART_ITEM_QUANTITY,
  CLEAR_CART,
  SET_ADD_TO_CART_NOTIFICATION,
  CLEAR_ADD_TO_CART_NOTIFICATION,
  CartActionType,
  CartState,
  CartItem,
  ADD_CART_ITEMS
} from "./types";



const initialState: CartState = {
  cartItems: [],
  cartInitialised: false,
  addToCartNotificationDetails: null
}

export function CartReducer(
  state: CartState = initialState,
  action: CartActionType
): CartState {
  switch (action.type) {

    case RESTORE_CART_ITEMS: {
      const { payload: { cartItems } } = action;

      return {
        ...state,
        cartItems,
        cartInitialised: true
      };
    }

    case SET_CART_ITEMS: {
      const { payload: { cartItems } } = action;

      return {
        ...state,
        cartItems
      };
    }

    case ADD_CART_ITEM: {
      const { payload: { cartItem } } = action;

      const { cartItems } = state;

      let isNewCartItem = true;

      let newCartItems = cartItems.map(existingCartItem => {
        const { productData: { id: existingCartItemId } } = existingCartItem;

        // if item already exists increment its quantity
        if (
          existingCartItemId === cartItem.productData.id &&
          existingCartItem.variantName == cartItem.variantName &&
          existingCartItem.variantValue == cartItem.variantValue
        ) {
          isNewCartItem = false;
          existingCartItem.quantity += cartItem.quantity;
        }

        return existingCartItem
      });

      if (isNewCartItem) {
        newCartItems = [...cartItems, cartItem];
      }

      return {
        ...state,
        cartItems: newCartItems
      };
    }

    case ADD_CART_ITEMS: {
      const { payload: { cartItems: addedCartItems } } = action;

      const { cartItems } = state;

      const newCartItems = cartItems.map(existingCartItem => {
        const { productData: { id: existingCartItemId } } = existingCartItem;

        const cartItem = addedCartItems.find(item => item.productData.id === existingCartItemId);

        // if item already exists increment its quantity
        if (
          cartItem &&
          existingCartItemId === cartItem.productData.id &&
          existingCartItem.variantName == cartItem.variantName &&
          existingCartItem.variantValue == cartItem.variantValue
        ) {
          existingCartItem.quantity += cartItem.quantity;
        }

        return existingCartItem
      });

      return {
        ...state,
        cartItems: unionBy(newCartItems, addedCartItems, "productData.id")
      };
    }

    case REMOVE_CART_ITEM: {
      const { payload: { cartItemId } } = action;

      const { cartItems } = state;

      const newCartItems = cartItems.filter(cartItem => {
        return cartItem.id !== cartItemId;
      });

      return {
        ...state,
        cartItems: newCartItems
      };
    }

    case CLEAR_CART: {
      return {
        ...state,
        cartItems: []
      };
    }

    case SET_CART_ITEM_QUANTITY: {
      const { payload: { cartItemId, quantity } } = action;

      const { cartItems } = state;

      const newCartItems = cartItems.map(cartItem => {
        if (cartItem.id !== cartItemId) return cartItem;

        const newCartItem: CartItem = {
          ...cartItem, quantity
        }

        return newCartItem;
      });

      return {
        ...state,
        cartItems: newCartItems
      };
    }

    case SET_ADD_TO_CART_NOTIFICATION: {
      const { payload: { cartItem } } = action;

      return {
        ...state,
        addToCartNotificationDetails: cartItem
      };
    }

    case CLEAR_ADD_TO_CART_NOTIFICATION: {
      return {
        ...state,
        addToCartNotificationDetails: null
      }
    }

    default: {
      return state;
    }
  }
}
