import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import TextControl from '../../../components/form-controls/TextControl';
import { Button } from '../../../components/Button';

import { regexBase } from '../../util';
import { ScreenSize } from '../../../styling/constants';
import { FormControlLabel } from '../../../components/Layout';
import { SubmitControlContainer } from '../style';
import { SignInRequest } from '../../../repositories/AuthRepository';

const emailValid = (value: string) => {
  return !(value === '' || !regexBase.emailRegex.test(value));
};

const LoginContainer = styled.div`
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  
  @media (min-width: ${ScreenSize.XS}px) {
    width: 400px;
    padding: 25px 30px;
  }
`;

const ControlGroup = styled.div`
  margin-bottom: 20px;
`;

const ControlLabel = styled(FormControlLabel)`
  color: #003c58;
  font-size: 1.2rem;
`;

const DividerContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
`;

const DividerLine = styled.div`
  border-top: solid 1px #DFDFDF;
  width: 45%;
`;

const DividerText = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 1.4rem;
  color: #003c58;
`;

interface OwnProps {
  loading: boolean;
  continueAsGuest: () => void;
  loginUser: (request: SignInRequest) => void;
}

const LoginFormContent = (props: OwnProps) => {
  const { loading, loginUser, continueAsGuest } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showFormErrors, setShowFormErrors] = useState(false);

  const isValidEmail = emailValid(email);
  const isValidPassword = password !== '';

  const submitButtonHandler = () => {
    if (
      !isValidEmail || !isValidPassword
    ) {
      setShowFormErrors(true);
      return;
    }

    loginUser({ username: email, password });
  };

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) submitButtonHandler();
  };

  return (
    <LoginContainer>
      <form>
        <ControlGroup>
          <ControlLabel>Email</ControlLabel>
          <TextControl
            type="email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={evt => setEmail(evt.target.value)}
            onKeyUp={onKeyUpHandler}
            containerStyle={{ width: '100%' }}
            error={showFormErrors && !isValidEmail}
          />
        </ControlGroup>

        <ControlGroup>
          <ControlLabel>Password</ControlLabel>
          <TextControl
            type="password"
            name="password"
            autoComplete="password"
            value={password}
            onChange={evt => setPassword(evt.target.value)}
            onKeyUp={onKeyUpHandler}
            containerStyle={{ width: '100%' }}
            error={showFormErrors && !isValidPassword}
          />
        </ControlGroup>

        <SubmitControlContainer>
          <Button
            text="Sign in"
            color="PRIMARY"
            onClick={submitButtonHandler}
            loading={loading}
            containerStyle={{ width: '100%', marginTop: '15px', marginBottom: '30px' }}
          />
        </SubmitControlContainer>

        <DividerContainer>
          <DividerLine />
          <DividerText>OR</DividerText>
          <DividerLine />
        </DividerContainer>

        <SubmitControlContainer>
          <Button
            text="Continue as a Guest"
            color="PRIMARY"
            type="OUTLINE"
            onClick={continueAsGuest}
            containerStyle={{ width: '100%', marginBottom: '15px' }}
          />
        </SubmitControlContainer>
      </form>
    </LoginContainer>
  );
};

export default LoginFormContent;
