import React from 'react';
import NotificationWidget from "../../components/NotificationWidget";
import { ReactComponent as CartIcon } from '../../assets/icons/shopping-cart.svg';


export default function EmptyCartView() {
  return (
    <NotificationWidget
      title="No items in your cart"
      message="Go ahead and add something!"
      icon={<CartIcon />}
    />
  );
}