import React, { ReactNode, CSSProperties } from "react";
import styled from "styled-components";

const sampleResults = [
  'Apple Watch Series 4 Space Gray',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
  'Apple Homekit - Ecobee3 Lite Smart hermostat',
];

const Container = styled.div`
  background-color: white;
  max-height: 200px;
  overflow: auto;
`;

const ResultItemContainer = styled.div`
  height: 50px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function DropdownContent() {
  return (
    <Container>
      {/* {sampleResults.map(resultItem => {
        return (
          <ResultItemContainer>
            <p>{resultItem}</p>
          </ResultItemContainer>
        );
      })} */}
    </Container>
  );
}