import React, { useState, CSSProperties, RefObject, forwardRef } from 'react';
import DropdownWrapper from '../../../components/DropdownWrapper';
import { ControlContent } from './ControlContent';
import DropdownContent from './DropdownContent';
import { useViewport } from '../../../providers/ViewportProvider';
import { ScreenSize } from '../../../styling/constants';

interface Props {
  inputRef: RefObject<HTMLInputElement>;
  searchValue: string;
  onChangeHandler: (value: string) => void;
  onSubmitHandler: (isClearAction?: boolean) => void
  dismissHandler: () => void;
}

export const SearchControl = (props: Props) => {
  const { inputRef, searchValue, onChangeHandler, onSubmitHandler, dismissHandler } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const viewportSize = useViewport();
  const isMobileView = viewportSize.width <= ScreenSize.XS;

  const containerStyle: CSSProperties = isMobileView
    ? { width: '100%', boxShadow: '0 17px 15px 0 rgba(0, 0, 0, 0.07)' }
    : { width: '100%' };

  const dropdownContainerStyle: CSSProperties = isMobileView
    ? { width: '100%', border: 'none' }
    : { width: '400px' };

  const handleOnSubmit = (isClearAction?: boolean) => {
    setShowDropdown(false);
    onSubmitHandler(isClearAction);
  }

  const handleOnFocus = () => {
    setShowDropdown(true);
  }

  const handleOnBlur = () => {
    setShowDropdown(false);
    dismissHandler();
  }

  const onSelectOption = (value: string) => {
    onChangeHandler(value);
    handleOnSubmit();
  }

  return (
    <DropdownWrapper
      controlContent={
        <ControlContent
          inputRef={inputRef}
          value={searchValue}
          onChangeHandler={onChangeHandler}
          onFocusHandler={handleOnFocus}
          onBlurHandler={handleOnBlur}
          onSubmit={handleOnSubmit}
        />
      }
      dropdownContent={<DropdownContent />}
      showDropdown={showDropdown}
      containerStyle={{
        background: 'white',
        ...containerStyle
      }}
      dropdownContainerStyle={{
        border: 'none',
        ...dropdownContainerStyle
      }}
      rightAlign
    />
  );
}
