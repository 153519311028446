import {
  TRIGGER_HYDRATE_AUTH_STORE,
  TriggerHydrateAuthStore,

  TriggerSetAuthStatePayload,
  TRIGGER_SET_AUTH_STATE,
  TriggerSetAuthState,

  TRIGGER_CLEAR_AUTH_STATE,
  TriggerClearAuthState,

  SET_AUTH_STATE,
  SetAuthState,

  CLEAR_AUTH_STATE,
  ClearAuthState,

  UserProfile
} from './types';

export const triggerHydrateAuthStore = (): TriggerHydrateAuthStore => ({
  type: TRIGGER_HYDRATE_AUTH_STORE,
});

export const triggerSetAuthState = (payload: TriggerSetAuthStatePayload): TriggerSetAuthState => ({
  type: TRIGGER_SET_AUTH_STATE,
  payload: payload
});

export const triggerClearAuthState = (): TriggerClearAuthState => ({
  type: TRIGGER_CLEAR_AUTH_STATE
});

export const setAuthState = (userProfile: UserProfile): SetAuthState => ({
  type: SET_AUTH_STATE,
  payload: { userProfile }
});

export const clearAuthState = (): ClearAuthState => ({
  type: CLEAR_AUTH_STATE
});
