import styled, { css } from 'styled-components';
import { PAGE_TOP_PADDING, PAGE_SIDE_PADDING, ScreenSize, MAX_STORE_VIEW_WIDTH } from '../../styling/constants';
import { RouteView } from '../../components/Layout';

export const Container = styled(RouteView)`
  position: relative;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;

  @media (min-width: ${ScreenSize.XS}px) {
    max-width: ${MAX_STORE_VIEW_WIDTH}px;
  }
`;

export const StoreViewBody = styled.section`
  flex-grow: 1;
`;

export const StoreViewCategoriesContainer = styled.aside`
  flex-grow: 0;
  flex-shrink: 0;
  width: 239px;
  position: sticky;
  top: 0px;

  @media (max-width: ${ScreenSize.XS}px) {
    display: none;
  }
`;

export const SearchTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  p {
    text-align: center;
    font-weight: bold;
    color: #003c58;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    display: none;
  }
`;

export const StoreItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface StoreItemContainerProps {
  compressedView: boolean;
}

export const StoreItemContainer = styled.div<StoreItemContainerProps>`
  margin-bottom: 100px;
  padding: 0px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 60px;

  ${({ compressedView }) => !compressedView && css`
    width: 100%;
    
    @media (min-width: ${ScreenSize.XS}px) {
      width: ${100 / 3}%;
    }
  `}

  ${({ compressedView }) => compressedView && css`
    width: 50%;

    @media (min-width: ${ScreenSize.XS}px) {
      width: 25%;
    }
  `}
`;

export const PaginatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 320px;
  margin: auto;
  margin-top: 35px;
`;

export const PaginatorTextContainer = styled.div`
  margin-bottom: 15px;

  p {
    color: #1aa7e8;
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

export const PaginatorButtonContainer = styled.div`
  width: 100%;
  max-width: 250px;
  margin-top: 20px;
`;



export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 55px;
`;

export const SortControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ItemCountContainer = styled.div`
  margin-right: 15px;
  p {

  }
`;

export const ProductLayoutControlContainer = styled.div`
  margin-right: 15px;
`;

interface SearchControlContainerProps {
  showWidget: boolean;
}

export const SearchControlContainer = styled.div<SearchControlContainerProps>`
  position: absolute;
  left: 0px;
  top: ${({ showWidget }) => showWidget ? '0px' : '-1000px'};
  width: 100%;

  @media (min-width: ${ScreenSize.XS}px) {
    position: relative;
    top: 0px;
    display: flex;
    width: 260px;
  }
`;

export const SearchWidgetTriggerContainer = styled.div`
  display: flex;

  @media (min-width: ${ScreenSize.XS}px) {
    display: none;
  }
`;

export const EmptyStoreViewContainer = styled.div`
  margin-top: 80px;
`;