import {
  FETCH_PRODUCTS_DETAILS_PENDING,
  FETCH_PRODUCTS_DETAILS_SUCCESS,
  FETCH_PRODUCTS_DETAILS_ERROR,

  RESET_SHOPPING_CART_VIEW,
  ShoppingCartViewActionType,
  ShoppingCartViewState,
} from "./types";

const initialState: ShoppingCartViewState = {
  fetchingProductsDetails: false,
  fetchingProductsDetailsError: false,
  productsDetails: null
}

export function ShoppingCartViewReducer(
  state: ShoppingCartViewState = initialState,
  action: ShoppingCartViewActionType
): ShoppingCartViewState {
  switch (action.type) {

    case FETCH_PRODUCTS_DETAILS_PENDING: {
      return {
        ...state,
        fetchingProductsDetails: true,
        fetchingProductsDetailsError: false
      };
    }

    case FETCH_PRODUCTS_DETAILS_SUCCESS: {
      const { payload } = action;
      const { productsDetails } = payload;

      return {
        ...state,
        fetchingProductsDetails: false,
        fetchingProductsDetailsError: false,
        productsDetails: productsDetails
      };
    }

    case FETCH_PRODUCTS_DETAILS_ERROR: {
      return {
        ...state,
        fetchingProductsDetails: false,
        fetchingProductsDetailsError: true
      };
    }



    case RESET_SHOPPING_CART_VIEW: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

