import {
  TRIGGER_FETCH_SUPPORTED_COUNTRIES,
  FETCH_SUPPORTED_COUNTRIES_PENDING,
  FETCH_SUPPORTED_COUNTRIES_SUCCESS,
  FETCH_SUPPORTED_COUNTRIES_ERROR,
  TriggerFetchSupportedCountries,
  FetchSupportedCountriesPending,
  FetchSupportedCountriesSuccess,
  FetchSupportedCountriesError,

  TRIGGER_FETCH_SUPPORTED_STATES,
  FETCH_SUPPORTED_STATES_PENDING,
  FETCH_SUPPORTED_STATES_SUCCESS,
  FETCH_SUPPORTED_STATES_ERROR,
  TriggerFetchSupportedStates,
  FetchSupportedStatesPending,
  FetchSupportedStatesSuccess,
  FetchSupportedStatesError,

  TRIGGER_FETCH_SUPPORTED_CITIES,
  FETCH_SUPPORTED_CITIES_PENDING,
  FETCH_SUPPORTED_CITIES_SUCCESS,
  FETCH_SUPPORTED_CITIES_ERROR,
  TriggerFetchSupportedCities,
  FetchSupportedCitiesPending,
  FetchSupportedCitiesSuccess,
  FetchSupportedCitiesError,

  TRIGGER_FETCH_DELIVERY_METHODS,
  FETCH_DELIVERY_METHODS_PENDING,
  FETCH_DELIVERY_METHODS_SUCCESS,
  FETCH_DELIVERY_METHODS_ERROR,
  TriggerFetchDeliveryMethods,
  FetchDeliveryMethodsPending,
  FetchDeliveryMethodsSuccess,
  FetchDeliveryMethodsError,

  TRIGGER_INITIATE_PAYMENT,
  INITIATE_PAYMENT_PENDING,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_ERROR,
  TriggerInitiatePayment,
  InitiatePaymentPending,
  InitiatePaymentSuccess,
  InitiatePaymentError,

  RESET_CHECKOUT_VIEW,
  ResetCheckoutView,
} from "./types";

import { SupportedCountry, SupportedState, SupportedCity, GetDeliveryMethodsRequest, DeliveryMethodsData, InitiatePaymentRequest, InitiatePaymentResponseData } from "../../../repositories/OrderRepository";





export const triggerFetchSupportedCountries = (): TriggerFetchSupportedCountries => {
  return {
    type: TRIGGER_FETCH_SUPPORTED_COUNTRIES
  };
};

export const fetchSupportedCountriesPending = (): FetchSupportedCountriesPending => {
  return {
    type: FETCH_SUPPORTED_COUNTRIES_PENDING
  };
};

export const fetchSupportedCountriesSuccess = (payload: { countries: SupportedCountry[] }): FetchSupportedCountriesSuccess => {
  return {
    type: FETCH_SUPPORTED_COUNTRIES_SUCCESS,
    payload: payload
  };
};

export const fetchSupportedCountriesError = (): FetchSupportedCountriesError => {
  return {
    type: FETCH_SUPPORTED_COUNTRIES_ERROR
  };
};





export const triggerFetchSupportedStates = (payload: { countryCode: string }): TriggerFetchSupportedStates => {
  return {
    type: TRIGGER_FETCH_SUPPORTED_STATES,
    payload
  };
};

export const fetchSupportedStatesPending = (): FetchSupportedStatesPending => {
  return {
    type: FETCH_SUPPORTED_STATES_PENDING
  };
};

export const fetchSupportedStatesSuccess = (payload: { states: SupportedState[] }): FetchSupportedStatesSuccess => {
  return {
    type: FETCH_SUPPORTED_STATES_SUCCESS,
    payload: payload
  };
};

export const fetchSupportedStatesError = (): FetchSupportedStatesError => {
  return {
    type: FETCH_SUPPORTED_STATES_ERROR
  };
};





export const triggerFetchSupportedCities = (payload: { city: string }): TriggerFetchSupportedCities => {
  return {
    type: TRIGGER_FETCH_SUPPORTED_CITIES,
    payload
  };
};

export const fetchSupportedCitiesPending = (): FetchSupportedCitiesPending => {
  return {
    type: FETCH_SUPPORTED_CITIES_PENDING
  };
};

export const fetchSupportedCitiesSuccess = (payload: { cities: SupportedCity[] }): FetchSupportedCitiesSuccess => {
  return {
    type: FETCH_SUPPORTED_CITIES_SUCCESS,
    payload: payload
  };
};

export const fetchSupportedCitiesError = (): FetchSupportedCitiesError => {
  return {
    type: FETCH_SUPPORTED_CITIES_ERROR
  };
};



export const triggerFetchDeliveryMethods = (request: GetDeliveryMethodsRequest): TriggerFetchDeliveryMethods => {
  return {
    type: TRIGGER_FETCH_DELIVERY_METHODS,
    payload: {
      request
    }
  };
};

export const fetchDeliveryMethodsPending = (): FetchDeliveryMethodsPending => {
  return {
    type: FETCH_DELIVERY_METHODS_PENDING
  };
};

export const fetchDeliveryMethodsSuccess = (
  payload: { deliveryMethodsData: DeliveryMethodsData }
): FetchDeliveryMethodsSuccess => {
  const { deliveryMethodsData } = payload;

  return {
    type: FETCH_DELIVERY_METHODS_SUCCESS,
    payload: {
      deliveryMethodsData
    }
  };
};

export const fetchDeliveryMethodsError = (): FetchDeliveryMethodsError => {
  return {
    type: FETCH_DELIVERY_METHODS_ERROR
  };
};





export const triggerInitiatePayment = (request: InitiatePaymentRequest): TriggerInitiatePayment => {
  return {
    type: TRIGGER_INITIATE_PAYMENT,
    payload: { request }
  };
};

export const initiatePaymentPending = (): InitiatePaymentPending => {
  return {
    type: INITIATE_PAYMENT_PENDING
  };
};

export const initiatePaymentSuccess = (data: InitiatePaymentResponseData): InitiatePaymentSuccess => {
  return {
    type: INITIATE_PAYMENT_SUCCESS,
    paylods: { data }
  };
};

export const initiatePaymentError = (): InitiatePaymentError => {
  return {
    type: INITIATE_PAYMENT_ERROR
  };
};





export const resetCheckoutView = (): ResetCheckoutView => {
  return {
    type: RESET_CHECKOUT_VIEW
  };
};