import {
  FETCH_STORE_DETAILS_PENDING,
  FETCH_STORE_DETAILS_SUCCESS,
  FETCH_STORE_DETAILS_ERROR,
  StoreActionType,
  StoreState
} from "./types";

const initialState: StoreState = {
  storeDetails: null,
  fetchingStoreDetails: true,
  fetchingStoreDetailsError: false
}

export function StoreReducer(
  state: StoreState = initialState,
  action: StoreActionType
): StoreState {
  switch (action.type) {

    case FETCH_STORE_DETAILS_PENDING: {
      return {
        ...state,
        fetchingStoreDetails: true
      };
    }

    case FETCH_STORE_DETAILS_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        storeDetails: payload,
        fetchingStoreDetails: false,
        fetchingStoreDetailsError: false
      };
    }

    case FETCH_STORE_DETAILS_ERROR: {
      return {
        ...state,
        fetchingStoreDetails: false,
        fetchingStoreDetailsError: true
      };
    }

    default: {
      return state;
    }
  }
}
