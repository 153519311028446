import React, { CSSProperties, useRef, ReactNode, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ColorTheme, FormControlsTheme } from '../../../styling/constants';

import { ReactComponent as ReloadIcon } from './icons/reload.svg';
import { ReactComponent as DropdownArrowIcon } from './icons/dropdown.svg';


const Container = styled.div`
  position: relative;
`;

interface ValueContainerProps {
  error: boolean;
}

const ValueContainer = styled.div<ValueContainerProps>`
  height: ${FormControlsTheme.controlHeight.default}px;
  border: 1px solid ${ColorTheme.formControls.borderColor};  
  cursor: pointer;

  ${({ error }) => error && css`
      border-color: red;
  `}
`;

const ValueContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 0px ${FormControlsTheme.padding.side}px;
  padding-right: 40px;
  
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: ${FormControlsTheme.fontSize.default}px;
  }
`;

const CustomValueContent = styled.div`
  padding-right: 40px;
  height: 100%;
`;

const ControlsContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
`;

const IconControl = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
    fill: #8c8c8c;
  }

  &:last-child {
    margin-right: 0px;
  }
`;


const Select = styled.select`
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  box-sizing: border-box;
  opacity: 0;
`;

const DropdownContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: white;
  border: 1px solid ${ColorTheme.formControls.borderColor};
  border-top: none;
  z-index: 10;
`;

const DropdownOption = styled.div`
  border-bottom: 1px solid #ececec;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(238, 242, 247, 0.39);
  }

  
`;

export interface SelectionOption {
  value: any;
  label: string;
}

interface Props {
  value: any;
  options: SelectionOption[];
  placeholder?: string;
  loading?: boolean;
  showReloadControl?: boolean;
  error?: boolean;
  onChange: (value: any) => void;
  valueBuilder?: (value: any) => ReactNode;
  optionBuilder?: (value: any, label: string, index: number) => ReactNode;
  onReload?: () => void;
  containerStyle?: CSSProperties;

}

export default function SelectDropdown(props: Props) {
  const { value, options, placeholder, loading, showReloadControl, error = false, onChange, valueBuilder, optionBuilder, onReload, containerStyle } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onValueContainerClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!optionBuilder) return;

    event.nativeEvent.stopImmediatePropagation();

    setDropdownOpen(true);
  };

  const dismissDropdown = () => {
    setDropdownOpen(false);
  };


  useEffect(() => {
    document.addEventListener("click", dismissDropdown);

    return () => document.removeEventListener("click", dismissDropdown);
  }, []);


  const selectedOption = options.find((option => {
    return option.value == value;
  }));

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  }

  const onReloadControlClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.nativeEvent.stopImmediatePropagation();
    if (onReload != null) onReload();
  };

  let selectElOptions: SelectionOption[] = [
    { label: placeholder || 'Select an option', value: "" },
    ...options,
  ];

  let valuePlaceholderText = placeholder || 'Select an option';
  if(loading) {
    valuePlaceholderText = 'Loading...';
  }

  return (
    <Container>
      <ValueContainer onClick={onValueContainerClick} error={error}>

        {!selectedOption && (
          <ValueContent>
            <p>{valuePlaceholderText}</p>
          </ValueContent>
        )}

        {selectedOption && !valueBuilder && (
          <ValueContent>
            <p>{selectedOption.label}</p>
          </ValueContent>
        )}

        {valueBuilder && selectedOption && (
          <CustomValueContent>
            {valueBuilder(selectedOption.value)}
          </CustomValueContent>
        )}

        <ControlsContainer>

          {showReloadControl && (
            <IconControl onClick={onReloadControlClick}>
              <ReloadIcon />
            </IconControl>
          )}

          <IconControl>
            <DropdownArrowIcon />
          </IconControl>

        </ControlsContainer>


      </ValueContainer>

      {!optionBuilder && !loading && !showReloadControl && (
        <Select
          placeholder={placeholder || 'Select an option'}
          value={value}
          style={{ ...containerStyle }}
          onChange={handleOnChange}
        >
          {selectElOptions.map(option => {
            const { value, label } = option;
            return <option value={value} disabled={!value}>{label}</option>
          })}
        </Select>
      )}

      {optionBuilder && dropdownOpen && !showReloadControl && (
        <DropdownContainer>
          {options.map((option, index) => {
            const { value, label } = option;

            return (
              <DropdownOption onClick={() => onChange(value)}>
                {optionBuilder(value, label, index)}
              </DropdownOption>
            );
          })}
        </DropdownContainer>
      )}

    </Container>
  );
}