export enum SupportedScreenSize {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  HD = "HD",
  FHD = "FHD"
}

export const ScreenSize: { [key in SupportedScreenSize]: number } = {
  XS: 480,
  S: 768,
  M: 992,
  L: 1200,
  XL: 1440,
  HD: 1660,
  FHD: 1660
};

export const MediaQueries = {
  XS: `(min-width: ${ScreenSize.XS}px)`,
  S: `(min-width: ${ScreenSize.S}px)`,
  M: `(min-width: ${ScreenSize.M}px)`,
  L: `(min-width: ${ScreenSize.L}px)`,
  XL: `(min-width: ${ScreenSize.XL}px)`,
  HD: `(min-width: ${ScreenSize.HD}px)`,
  FHD: `(min-width: ${ScreenSize.FHD}px)`,
};

export const MAX_STORE_VIEW_WIDTH = 1160;

export const PAGE_TOP_PADDING = 30;
export const PAGE_TOP_PADDING_XS = 50;
export const PAGE_SIDE_PADDING = 20;

export const BACK_NAVIGATOR_BOTTOM_MARGIN = 50;


export const HEADER_HEIGHT = 60;
export const HEADER_HEIGHT_XS = 80;

export const ColorTheme = {
  primaryColor: '#1aa7e8',
  primaryColorLight: '#007e9b',
  primaryColorDark: '#003c58',
  backgroundColor: '#eef2f7',
  font: {

  },
  formControls: {
    borderColor: '#dad8d8'
  }
};

export const FormControlsTheme = {
  controlHeight: {
    default: 45,
    xs: 45
  },
  padding: {
    side: 15
  },
  fontSize: {
    default: 16
  },
  borderRadius: 4
};
