import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
    font-family: kumbh_sans_regular, sans-serif;
  }

  body {
    margin: 0px;
  }

  html, body, #root {
  }

  p, h1, h2, h3, h4, h5, h6 {
    padding: 0px;
    margin: 0px;
    line-height: 2rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #757575;
  }

  input, select {  
    font-size: 1.6rem;
    -webkit-appearance: none;
  }

  a {
    text-decoration: none;
    color: #1aa7e8;
    font-size: 1.6rem;
  }
`;