import React, { ReactNode, CSSProperties } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "../styling/constants";

interface ContainerProps {
  active: boolean;
  disabled: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-sizing: border-box;
  cursor: pointer;
  

  border: 2px solid ${ColorTheme.formControls.borderColor};
  ${({ active }) => active && css`
    border-color: ${ColorTheme.primaryColor};
  `}

  background-color: white;
  ${({ disabled }) => disabled && css`
    background-color: #eeeeee;
  `}

  div {
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 100%;

    ${({ active }) => active && css`
      background: #1aa7e8;
    `} 

    ${({ disabled }) => disabled && css`
      background-color: transparent;
    `} 
  }
`;

interface Props {
  active: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export default function RadialButton(props: Props) {
  const { active, disabled = false, onClick } = props;

  return (
    <Container active={active} disabled={disabled} onClick={onClick}>
      <div />
    </Container>
  );
}
