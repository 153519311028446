import { takeLatest, put } from "redux-saga/effects";

import { TRIGGER_SIGN_IN, TriggerSignInUser } from "./types";

import {
  signInPending,
  signInSuccess,
  signInError
} from "./actions";
import { authRepository } from "../../../repositories";
import { SignInResponse } from "../../../repositories/AuthRepository";


function* handleTriggerSignin(action: TriggerSignInUser) {
  const { payload } = action;

  yield put(signInPending());

  try {
    const response: SignInResponse = yield authRepository.signInUser(
      payload
    );

    yield put(signInSuccess(response));
  } catch (err) {
    yield put(signInError());
    return;
  }
}


export function* signinSaga() {
  yield takeLatest(TRIGGER_SIGN_IN, handleTriggerSignin);
}
