import React from 'react';
import styled from "styled-components";
import { CARD_BORDER_COLOR } from '../style';
import { ScreenSize } from '../../../styling/constants';
import { OrderStatusOrderItem } from '../../../repositories/OrderRepository';
import { useViewport } from '../../../providers/ViewportProvider';
import Content from './Content';
import MobileContent from './MobileContent';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${CARD_BORDER_COLOR};
`;


interface Props {
  orderItems: OrderStatusOrderItem[];
  currencyCode: string;
}

export default function OrderItemsContent(props: Props) {
  const { orderItems, currencyCode } = props;

  const viewportSize = useViewport();
  const mobileView = viewportSize.width <= ScreenSize.XS;

  return (
    <Container>

      {mobileView && (
        <MobileContent
          orderItems={orderItems}
          currencyCode={currencyCode}
        />
      )}

      {!mobileView && (
        <Content
          orderItems={orderItems}
          currencyCode={currencyCode}
        />
      )}


    </Container>
  );
}