import React, { useState } from 'react';
import styled from "styled-components";

import { CustomerAddress } from '../../../../repositories/CustomerRepository';
import { ColorTheme } from '../../../../styling/constants';

import AddressEntry from './AddressEntry';

const Contaier = styled.div``;

const AddControlContainer = styled.div`
  margin-bottom: 40px;
  cursor: pointer;
  p {
    color: ${ColorTheme.primaryColor};
  }
`;

const AddresesListContainer = styled.div``;

interface Props {
  customerAddresses: CustomerAddress[];
  selectedAddressId: number | null;
  deleteAddressId: number | null;
  deletingAddress: boolean;
  onAddressSelect: (addressId: number) => void;
  createAddressButtonHandler: () => void;
  deleteAddressButtonHandler: (addressId: number) => void;
}


export default function SelectAddressContent(props: Props) {
  const {
    customerAddresses,
    selectedAddressId,
    deleteAddressId,
    deletingAddress,
    onAddressSelect,
    createAddressButtonHandler,
    deleteAddressButtonHandler,
  } = props;

  return (
    <Contaier>
      <AddControlContainer onClick={createAddressButtonHandler}>
        <p>+ Add new address</p>
      </AddControlContainer>

      {customerAddresses.length === 0 && (
        <p>Your adress book empty</p>
      )}

      <AddresesListContainer>
        {customerAddresses.map(customerAddress => {
          const { id: addressId } = customerAddress;

          return (
            <AddressEntry
              customerAddress={customerAddress}
              selected={addressId === selectedAddressId}
              deleting={
                deletingAddress && deleteAddressId === addressId
              }
              onSelect={onAddressSelect}
              onDelete={() => deleteAddressButtonHandler(customerAddress.id)}
            />
          );
        })}
      </AddresesListContainer>
    </Contaier>
  );
}