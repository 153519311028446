import { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import {isAfter as isDateAfter} from 'date-fns';

import { TokenStorageManager } from './TokenStorageManager';

function isTokenExpired(token: string) {
  try {
    const decoded: any = jwt_decode(token);
    const { exp } = decoded;

    // if token does not have exp let it live
    if(!exp) return false;

    // convert to milliseconds
    const expMs = parseInt(exp) * 1000;

    if (isNaN(expMs)) return true;

    const expiryDate = new Date(expMs);

    const currentDate = new Date();

    return isDateAfter(currentDate, expiryDate);
  } catch (err) {
    // if token decoding failed mark as expired
    return true;
  }
}

export function tokenInterceptor(config: AxiosRequestConfig) {
  const token = TokenStorageManager.getToken();

  if (!token) return config;

  // Check if token has expired
  if (isTokenExpired(token)) {
    TokenStorageManager.deleteToken();
    return config;
  }

  config.headers['Authorization'] = `Bearer ${token}`;

  return config;
}