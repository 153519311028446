import { QTB_BASE_URL } from '../config/properties';
import { HttpClient } from '../core/api/HttpClient';

export interface SignInRequest {
  username: string;
  password: string;
}

export interface SignInResponse {
  errors: null,
  data: {
    mobileNo: string;
    email: string;
    jti: string;
    access_token: string;
  }
}

export interface IAuthRepository {
  signInUser(request: SignInRequest): Promise<SignInResponse>;
}

export class AuthRepository implements IAuthRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async signInUser(request: SignInRequest): Promise<SignInResponse> {
    const response = await this.httpClient.post<SignInResponse>(
      `${QTB_BASE_URL}/signin`,
      request
    );

    return response.data;
  }
}
