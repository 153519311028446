import React, { useState } from 'react';
import styled, { css } from "styled-components";
import { ColorTheme } from '../styling/constants';


interface ContainerProps {
  active: boolean;
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid ${ColorTheme.formControls.borderColor};
  border-radius: 2px;
  
  height: 30px;
  padding: 0px 10px;
  box-sizing: border-box;

  margin-right: 10px;
  margin-bottom: 10px;

  cursor: pointer;

  ${({ active }) => active && css`
    border-color: ${ColorTheme.primaryColor};
  `};

  ${({ disabled }) => disabled && css`
    background-color: #f2f1f1;
    cursor: not-allowed;
  `};

  p {
    font-size: 1.4rem;
    ${({ active }) => active && css`
      color: ${ColorTheme.primaryColor};
    `};
  }
`;

interface Props {
  text: string;
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export default function VariationOption(props: Props) {
  const { text, active, disabled, onClick } = props;

  return (
    <Container active={active} disabled={disabled} onClick={onClick}>
      <p>{text}</p>
    </Container>
  );
}