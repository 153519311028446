import React, { useState, useEffect } from 'react';
import { SupportedCountry, SupportedState, SupportedCity } from '../../../repositories/OrderRepository';

import { FormControl, FormControlLabel, FormControlGroup } from '../../../components/Layout';
import SelectDropdown from '../../../components/form-controls/SelectDropdown';
import TextControl from '../../../components/form-controls/TextControl';
import { connect } from 'react-redux';
import { AppState } from '../../../store/RootReducer';
import { CheckoutViewActionType } from '../../../store/ui/CheckoutView/types';
import { triggerFetchSupportedCities, triggerFetchSupportedCountries, triggerFetchSupportedStates } from '../../../store/ui/CheckoutView/actions';
import { DeliveryAddressFormState } from '..';

const supportedCountries: SupportedCountry[] = [
  { id: 159, code: "NG", name: "Nigeria" },
  { id: 404, code: "KE", name: "Kenya" },
  { id: 800, code: "UG", name: "Uganda" },
];


interface StoreStateProps {
  // supportedCountries: SupportedCountry[];
  fetchingSupportedCountries: boolean;
  errorFetchingSupportedCountries: boolean;

  supportedStates: SupportedState[];
  fetchingSupportedStates: boolean;
  errorFetchingSupportedStates: boolean;

  supportedCities: SupportedCity[];
  fetchingSupportedCities: boolean;
  errorFetchingSupportedCities: boolean;
}

interface StoreDispatchProps {
  fetchSupportedCountries: () => void;
  fetchSupportedStates: (countryCode: string) => void;
  fetchSupportedCities: (city: string) => void;
}

interface OwnProps {
  initialState?: DeliveryAddressFormState;
  showFormErrors: boolean;
  emailEnabled: boolean;
  onFormChange: (formState: DeliveryAddressFormState) => void;
}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;


export function NewAddressForm(props: Props) {
  const {
    initialState,
    showFormErrors,
    emailEnabled,
    onFormChange,

    // supportedCountries,
    fetchingSupportedCountries,
    errorFetchingSupportedCountries,

    supportedStates,
    fetchingSupportedStates,
    errorFetchingSupportedStates,

    supportedCities,
    fetchingSupportedCities,
    errorFetchingSupportedCities,

    fetchSupportedCountries,
    fetchSupportedStates,
    fetchSupportedCities,
  } = props;

  const [country, setCountry] = useState(initialState?.country || supportedCountries[0].name);
  const [state, setState] = useState(initialState?.state || '');
  const [city, setCity] = useState(initialState?.city || '');

  const [fullName, setFullName] = useState(initialState?.fullName || '');
  const [phoneNumber, setPhoneNumber] = useState(initialState?.phoneNumber || '');
  const [address, setAddress] = useState(initialState?.address || '');
  const [email, setEmail] = useState(initialState?.email || '');


  let activeCountryCode = '';
  let activeStateCode = '';


  const isValidCountry = country !== '';
  const isValidState = state !== '';
  const isValidCity = city !== '';

  const isValidFullName = fullName !== '';
  const isValidPhoneNumber = phoneNumber !== '';
  const isValidAddress = address !== '';
  const isValidEmail = email !== '';


  const onCountryCodeChange = (countryName: string) => {
    setCountry(countryName);
    setState('');
    setCity('');
  };

  useEffect(() => {
    const selectedCountry = supportedCountries.find((_country => {
      return _country.name === country;
    })) as SupportedCountry;

    activeCountryCode = selectedCountry.code;
    fetchSupportedStates(activeCountryCode);
  }, [country]);

  const onStateCodeChange = (stateName: string) => {
    setState(stateName);
    setCity('');


    const selectedState = supportedStates.find((state => {
      return state.name === stateName;
    })) as SupportedState;

    activeStateCode = selectedState.code;
    fetchSupportedCities(activeStateCode);
  };

  const getCountryCode = (countryName: string) => {
    const selectedCountry = supportedCountries.find((country => {
      return country.name === countryName;
    })) as SupportedCountry;

    if (!selectedCountry) return '';
    return selectedCountry.code;
  };

  useEffect(() => {
    // fetchSupportedCountries();
  }, []);

  useEffect(() => {
    onFormChange({
      country, state, city, fullName, phoneNumber, address, email
    });
  }, [country]);

  useEffect(() => {
    onFormChange({
      country, state, city, fullName, phoneNumber, address, email
    });
  }, [state]);

  useEffect(() => {
    onFormChange({
      country, state, city, fullName, phoneNumber, address, email
    });
  }, [city]);

  useEffect(() => {
    onFormChange({
      country, state, city, fullName, phoneNumber, address, email
    });
  }, [fullName]);

  useEffect(() => {
    onFormChange({
      country, state, city, fullName, phoneNumber, address, email
    });
  }, [phoneNumber]);

  useEffect(() => {
    onFormChange({
      country, state, city, fullName, phoneNumber, address, email
    });
  }, [address]);

  useEffect(() => {
    onFormChange({
      country, state, city, fullName, phoneNumber, address, email
    });
  }, [email]);

  return (
    <>
      <FormControl>
        <FormControlLabel><p>Country</p></FormControlLabel>
        <SelectDropdown
          placeholder="Select country..."
          value={country}
          options={supportedCountries.map(country => {
            const { name } = country;
            return { label: name, value: name };
          })}
          containerStyle={{ width: '100%' }}
          onChange={onCountryCodeChange}
          showReloadControl={errorFetchingSupportedCountries}
          onReload={() => fetchSupportedCountries()}
          error={showFormErrors && !isValidCountry}
        />
      </FormControl>

      <FormControl>
        <FormControlLabel><p>Full Name</p></FormControlLabel>
        <TextControl
          name="name"
          autoComplete="name"
          value={fullName}
          onChange={evt => setFullName(evt.target.value)}
          containerStyle={{ width: '100%' }}
          error={showFormErrors && !isValidFullName}
        />
      </FormControl>

      {emailEnabled && (
        <FormControl>
          <FormControlLabel><p>Email</p></FormControlLabel>
          <TextControl
            name="email"
            autoComplete="email"
            value={email}
            onChange={evt => setEmail(evt.target.value)}
            containerStyle={{ width: '100%' }}
            error={showFormErrors && !isValidEmail}
          />
        </FormControl>
      )}

      <FormControl>
        <FormControlLabel><p>Phone Number</p></FormControlLabel>
        <TextControl
          type="tel"
          name="phone"
          autoComplete="tel"
          value={phoneNumber}
          onChange={evt => setPhoneNumber(evt.target.value)}
          containerStyle={{ width: '100%' }}
          error={showFormErrors && !isValidPhoneNumber}
        />
      </FormControl>


      <FormControlGroup>

        <FormControl>
          <FormControlLabel><p>State</p></FormControlLabel>
          <SelectDropdown
            placeholder="Select state..."
            value={state}
            options={supportedStates.map(state => {
              const { name } = state;
              return { label: name, value: name };
            })}
            containerStyle={{ width: '100%' }}
            onChange={onStateCodeChange}
            loading={fetchingSupportedStates}
            showReloadControl={errorFetchingSupportedStates}
            onReload={() => {
              const countryCode = getCountryCode(country);
              fetchSupportedStates(activeCountryCode || countryCode)
            }}
            error={showFormErrors && !isValidState}
          />
        </FormControl>

        <FormControl>
          <FormControlLabel><p>Area</p></FormControlLabel>
          <SelectDropdown
            placeholder="Select area..."
            value={city}
            options={supportedCities.map(city => {
              const { lgaName, name } = city;
              return { label: name, value: name };
            })}
            containerStyle={{ width: '100%' }}
            onChange={(value) => setCity(value)}
            loading={fetchingSupportedCities}
            showReloadControl={errorFetchingSupportedCities}
            onReload={() => fetchSupportedCities(activeStateCode)}
            error={showFormErrors && !isValidCity}
          />
        </FormControl>

      </FormControlGroup>

      <FormControl>
        <FormControlLabel><p>Address</p></FormControlLabel>
        <TextControl
          name="address"
          autoComplete="street-address"
          value={address}
          onChange={evt => setAddress(evt.target.value)}
          containerStyle={{ width: '100%' }}
          error={showFormErrors && !isValidAddress}
        />
      </FormControl>
    </>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  // supportedCountries: state.ui.checkoutView.supportedCountries,
  fetchingSupportedCountries: state.ui.checkoutView.fetchingSupportedCountries,
  errorFetchingSupportedCountries: state.ui.checkoutView.errorFetchingSupportedCountries,

  supportedStates: state.ui.checkoutView.supportedStates,
  fetchingSupportedStates: state.ui.checkoutView.fetchingSupportedStates,
  errorFetchingSupportedStates: state.ui.checkoutView.errorFetchingSupportedStates,

  supportedCities: state.ui.checkoutView.supportedCities,
  fetchingSupportedCities: state.ui.checkoutView.fetchingSupportedCities,
  errorFetchingSupportedCities: state.ui.checkoutView.errorFetchingSupportedCities,
});

const mapDispatchToProps = (
  dispath: (action: CheckoutViewActionType) => void
): StoreDispatchProps => ({
  fetchSupportedCountries() {
    dispath(triggerFetchSupportedCountries());
  },
  fetchSupportedStates(countryCode: string) {
    dispath(triggerFetchSupportedStates({ countryCode }));
  },
  fetchSupportedCities(city: string) {
    dispath(triggerFetchSupportedCities({ city }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAddressForm);