import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { CARD_BORDER_COLOR, CARD_CONTENT_PADDING } from '../style';
import AsyncImage from '../../../components/AsyncImage';
import { ColorTheme, ScreenSize } from '../../../styling/constants';
import { OrderStatusOrderItem } from '../../../repositories/OrderRepository';
import { getFormattedPrice } from '../../../util/currencyUtil';



const ItemsTable = styled.table`
  width: 100%;
  
  thead td h6 {
    font-size: 1.6rem;
  }
  
  td {
    padding: ${CARD_CONTENT_PADDING}px;
  }
`;

const CenterAlignCell = styled.td`
  text-align: center;
`;

const RightAlignCell = styled.td`
  text-align: right;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  margin-right: 20px;
`;

const ItemDetailsContainer = styled.div``;

const NameContainer = styled.div`
  p {
    color: ${ColorTheme.primaryColorLight};
  }
`;

const VariantContainer = styled.div`
  display: flex;
  flex-direction: row;

  p {
    font-size: 1.4rem;
  }

  p:first-child {
    margin-right: 5px;
  }
`;

const AmountText = styled.p`
  white-space: nowrap;
`;

interface Props {
  orderItems: OrderStatusOrderItem[];
  currencyCode: string;
}

export default function Content(props: Props) {
  const { orderItems, currencyCode } = props;

  return (
    <ItemsTable>

      <thead>
        <tr>
          <td>
            <h6>Order Details</h6>
          </td>

          <RightAlignCell>
            <p>Price</p>
          </RightAlignCell>

          <CenterAlignCell>
            <p>Qty</p>
          </CenterAlignCell>

          <RightAlignCell>
            <p>Subtotal</p>
          </RightAlignCell>
        </tr>
      </thead>

      <tbody>
        {orderItems.map(item => {
          const { name, imageFileUrl, quantity, amount, variantName, variantValue } = item;

          return (
            <tr>
              <td style={{ width: '100%' }}>
                <DescriptionContainer>

                  <ImageContainer>
                    <AsyncImage
                      src={imageFileUrl}
                      alt="Product Image"
                      imageStyle={{ objectFit: 'contain' }}
                    />
                  </ImageContainer>

                  <ItemDetailsContainer>
                    <NameContainer>
                      <p>{name}</p>
                    </NameContainer>

                    {variantValue && (
                      <VariantContainer>
                        <p>{variantName}</p>
                        <p>{variantValue}</p>
                      </VariantContainer>
                    )}
                  </ItemDetailsContainer>


                </DescriptionContainer>
              </td>

              <RightAlignCell>
                <AmountText>{getFormattedPrice(amount / quantity, currencyCode)}</AmountText>
              </RightAlignCell>

              <CenterAlignCell>
                <p>{quantity}</p>
              </CenterAlignCell>

              <RightAlignCell>
                <AmountText>{getFormattedPrice(amount, currencyCode)}</AmountText>
              </RightAlignCell>
            </tr>
          );
        })}
      </tbody>

    </ItemsTable>
  );
}