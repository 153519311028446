import { StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { StoreReducer } from './store/reducer';
import { CartReducer } from './cart/reducer';
import { OrderReducer } from './order/reducer';
import { UiReducer } from './ui';
import CombinedAuthReducer from './auth';
import CustomerAddressReducer from './customerAddresses/reducer';


const RootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    store: StoreReducer,
    cart: CartReducer,
    order: OrderReducer,
    ui: UiReducer,
    auth: CombinedAuthReducer,
    customerAddresses: CustomerAddressReducer
  });

export default RootReducer;


export type AppState = StateType<ReturnType<typeof RootReducer>>;
