import styled from "styled-components";
import { ColorTheme } from "../styling/constants";


export const SummaryCard = styled.div`
  background-color: #fdfdfd;
  border: solid 1px rgba(151, 151, 151, 0.3);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

export const CardRow = styled.div`
  padding: 20px;
  border-bottom: solid 1px rgba(151, 151, 151, 0.3);

  &:last-child {
    border-bottom: none;
  }
`;

export const LabelValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0px;
  }

  & > h6 {
    font-size: 1.4rem;
    color: ${ColorTheme.primaryColorDark};
  }

  & > h4 {
    font-size: 1.4rem;
    color: black;
  }

  & > p {
    line-height: 2rem;
  }

  & > p:first-child, & >  h4:first-child, & >  h6:first-child {
    white-space: nowrap;
    margin-right: 30px;
  }

  & >  p:last-child, & >  h4:last-child, & >  h6:last-child {
    text-align: right;
  }
`;