import React from 'react';

import AppLogo from '../../assets/images/app-logo.png';

import {
  Container,
  ContentContainer,
  InitialsContainer,
  LogoContainer,
  DetailsContainer,
  TitleContainer,
  ProviderContainer,
  ControlsContainer,
  ActionControl
} from './style';

import ShoppingCartWidget from '../ShoppingCartWidget';
import { AppState } from '../../store/RootReducer';
import { connect } from 'react-redux';
import { StoreDetails } from '../../entities/StoreDetails';
import { useHistory } from 'react-router-dom';


interface StoreStateProps {
  storeDetails: StoreDetails;
}


interface OwnProps {

}

type Props = StoreStateProps & OwnProps;



function StoreHeader(props: Props) {
  const { storeDetails } = props;

  const history = useHistory();

  const navigateToStoreHome = () => {
    const { urlEndpoint } = storeDetails;
    history.push({ pathname: `/${urlEndpoint}` });
  }


  const { name, initials, whiteLabelAcquirer } = storeDetails;

  return (
    <Container>

      <ContentContainer>
        {/* <LogoContainer>
          <img src={DummyImage} />
        </LogoContainer> */}

        <InitialsContainer  style={whiteLabelAcquirer && { backgroundColor: whiteLabelAcquirer.primaryColor }}>
          <p>{initials}</p>
        </InitialsContainer>

        <DetailsContainer>

          <TitleContainer onClick={navigateToStoreHome}>
            <h2 style={whiteLabelAcquirer && { color: whiteLabelAcquirer.primaryColor }}>{name}</h2>
          </TitleContainer>

          <ProviderContainer>
            <p style={whiteLabelAcquirer && { color: whiteLabelAcquirer.secondaryColor }}>powered by <b>{whiteLabelAcquirer?.shortName || 'Interswitch'}</b></p>
            <img src={AppLogo} />
          </ProviderContainer>

        </DetailsContainer>

        <ControlsContainer>
          <ActionControl>
            <ShoppingCartWidget />
          </ActionControl>

        </ControlsContainer>
      </ContentContainer>

    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails
});


export default connect(mapStateToProps, {})(StoreHeader);