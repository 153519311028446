import React, { useState } from 'react';
import styled from "styled-components";
import { getFormattedPrice } from '../../../util/currencyUtil';
import { FormControlsTheme, ScreenSize } from '../../../styling/constants';
import { useViewport } from '../../../providers/ViewportProvider';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${FormControlsTheme.padding.side}px;
`;

const NameContainer = styled.div`
  margin-right: 5px;
`;

const DeliveryPeriodContainer = styled.div`
  margin-right: 5px;
`;

const PriceSeperator = styled.div`
  margin-right: 5px;
`;

const PriceContainer = styled.div`
  margin-right: 5px;
`;

interface Props {
  courierName: string;
  deliveryPeriod: string | null;
  currencyCode: string;
  price: number;
}

export default function ShippingOptionValueContent(props: Props) {
  const { courierName, deliveryPeriod, currencyCode, price } = props;

  const viewportSize = useViewport();
  const isMobileView = viewportSize.width <= ScreenSize.XS;
  
  const nameCharLimit = isMobileView ? 15 : 100;

  return (
    <Container>

      <NameContainer>
        <p>
          {courierName.slice(0, nameCharLimit)}
          {courierName.length > nameCharLimit ? '...' : ''}
        </p>
      </NameContainer>

      {deliveryPeriod && (
        <DeliveryPeriodContainer>
          <p>({deliveryPeriod})</p>
        </DeliveryPeriodContainer>
      )}

      <PriceSeperator>
        <p>@</p>
      </PriceSeperator>

      <PriceContainer>
        <p>{getFormattedPrice(price, currencyCode)}</p>
      </PriceContainer>

    </Container>
  );
}