export const TRIGGER_HYDRATE_AUTH_STORE = 'AUTH.TRIGGER_HYDRATE_AUTH_STORE';
export const TRIGGER_SET_AUTH_STATE = 'AUTH.TRIGGER_SET_AUTH_STATE';
export const TRIGGER_CLEAR_AUTH_STATE = 'AUTH.TRIGGER_CLEAR_AUTH_STATE';

export const SET_AUTH_STATE = 'AUTH.SET_AUTH_STATE';
export const CLEAR_AUTH_STATE = 'AUTH.CLEAR_AUTH_STATE';



export interface TriggerHydrateAuthStore {
  type: typeof TRIGGER_HYDRATE_AUTH_STORE;
}

export interface TriggerSetAuthStatePayload {
  accessToken: string;
  userProfile: UserProfile;
}

export interface TriggerSetAuthState {
  type: typeof TRIGGER_SET_AUTH_STATE;
  payload: TriggerSetAuthStatePayload;
}

export interface TriggerClearAuthState {
  type: typeof TRIGGER_CLEAR_AUTH_STATE;
}

export interface SetAuthState {
  type: typeof SET_AUTH_STATE;
  payload: {
    userProfile: UserProfile;
  }
}

export interface ClearAuthState {
  type: typeof CLEAR_AUTH_STATE;
}

export interface DecodedTokenParams {
  email: string;
  mobileNo: string;
  jti: string;
}

export interface UserProfile {
  email: string;
  mobileNo: string;
}


export type AuthActionType =
  TriggerHydrateAuthStore
  | TriggerSetAuthState
  | TriggerClearAuthState
  | SetAuthState
  | ClearAuthState;


export interface AuthState {
  userProfile: UserProfile | null;
}