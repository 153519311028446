import {
  TRIGGER_DISPLAY_TOAST,
  TriggerDisplayToast,

  ADD_TOAST,
  AddToast,

  REMOVE_TOAST,
  RemoveToast,
  TriggerDisplayToastPayload
} from "./types";

import { Toast } from "../../../components/ToastBoard/types";




export const triggerDisplayToast = (payload: TriggerDisplayToastPayload): TriggerDisplayToast => {
  return {
    type: TRIGGER_DISPLAY_TOAST,
    payload
  };
};

export const addToast = (toast: Toast): AddToast => {
  return {
    type: ADD_TOAST,
    payload: { toast }
  };
};

export const removeToast = (toastId: string): RemoveToast => {
  return {
    type: REMOVE_TOAST,
    payload: { toastId }
  };
};


