import React, { ReactNode, CSSProperties } from "react";
import styled from "styled-components";
import RadialButton from "../../../components/RadialButton";

const Container = styled.div`
  width: 100%;
  background-color: white;
`;

const OptionContainer = styled.div`
  height: 55px;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }
`;

const RadialButtonContainer = styled.div`
  margin-right: 20px;
`;

const OptionLabel = styled.p``;

interface Props {
  value: any;
  options: { label: string, value: any }[];
  onSelect: (value: any) => void;
}

export default function DropdownContent(props: Props) {
  const { value, options, onSelect } = props;

  return (
    <Container>
      {options.map(item => {
        return (
          <OptionContainer onClick={() => onSelect(item.value)}>

            <RadialButtonContainer>
              <RadialButton active={item.value === value} />
            </RadialButtonContainer>

            <OptionLabel>{item.label}</OptionLabel>

          </OptionContainer>
        );
      })}
    </Container>
  );
}