import {
  CREATE_CHECKOUT_ORDER_PENDING,
  CREATE_CHECKOUT_ORDER_SUCCESS,
  CREATE_CHECKOUT_ORDER_ERROR,
  OrderActionType,
  OrderState
} from "./types";

const initialState: OrderState = {
  orderDetails: null,
  creatingOrder: false,
  creatingOrderError: false
}

export function OrderReducer(
  state: OrderState = initialState,
  action: OrderActionType
): OrderState {
  switch (action.type) {

    case CREATE_CHECKOUT_ORDER_PENDING: {
      return {
        ...state,
        creatingOrder: true
      };
    }

    case CREATE_CHECKOUT_ORDER_SUCCESS: {
      const { payload } = action;
      const { orderDetails } = payload;

      return {
        ...state,
        orderDetails: orderDetails,
        creatingOrder: false,
        creatingOrderError: false
      };
    }

    case CREATE_CHECKOUT_ORDER_ERROR: {
      return {
        ...state,
        creatingOrder: false,
        creatingOrderError: true
      };
    }

    default: {
      return state;
    }
  }
}
