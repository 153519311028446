import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { CARD_BORDER_COLOR, CARD_CONTENT_PADDING } from '../style';
import AsyncImage from '../../../components/AsyncImage';
import { ColorTheme, ScreenSize, PAGE_SIDE_PADDING } from '../../../styling/constants';
import { OrderStatusOrderItem } from '../../../repositories/OrderRepository';
import { getFormattedPrice } from '../../../util/currencyUtil';



const Container = styled.div`

`;

const HeaderContainer = styled.div`
  padding: 15px ${CARD_CONTENT_PADDING}px;
  border-bottom: 1px solid ${CARD_BORDER_COLOR};

  h6 {
    font-size: 1.6rem;
  }
`;

const ItemsContainer = styled.div`
  padding: 15px ${PAGE_SIDE_PADDING}px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0px;
  }
`;


const ImageNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  margin-right: 20px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountContainer = styled.div`
  margin-bottom: 5px;
  
  p {
    color: black;
  }
`;

const NameContainer = styled.div`
  p {
    color: ${ColorTheme.primaryColorLight};
  }
`;


interface Props {
  orderItems: OrderStatusOrderItem[];
  currencyCode: string;
}

export default function MobileContent(props: Props) {
  const { orderItems, currencyCode } = props;

  return (
    <Container>
      <HeaderContainer>
        <h6>Order Details</h6>
      </HeaderContainer>

      <ItemsContainer>
        {orderItems.map(item => {
          const { name, imageFileUrl, quantity, amount } = item;

          return (
            <ItemContainer>

              <ImageContainer>
                <AsyncImage
                  src={imageFileUrl}
                  alt="Product Image"
                  imageStyle={{ objectFit: 'contain' }}
                />
              </ImageContainer>

              <DetailsContainer>

                <AmountContainer>
                  <p>{getFormattedPrice(amount, currencyCode)}</p>
                </AmountContainer>

                <NameContainer>
                  <p>{name}</p>
                </NameContainer>

              </DetailsContainer>

            </ItemContainer>
          );
        })}
      </ItemsContainer>

    </Container>
  );
}