import { combineReducers } from 'redux';

import SignInReducer from './signin/reducer';
import AuthReducer from './auth/reducer';

const CombinedAuthReducer = combineReducers({
  signin: SignInReducer,
  auth: AuthReducer
});

export default CombinedAuthReducer;
