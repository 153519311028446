import styled from "styled-components";
import { ScreenSize, BACK_NAVIGATOR_BOTTOM_MARGIN } from '../../styling/constants';
import { RouteView } from '../../components/Layout';

export const Container = styled(RouteView)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    
  }
`;

export const BackControlContainer = styled.div`
  margin-bottom: ${BACK_NAVIGATOR_BOTTOM_MARGIN}px;
`;

export const EmptyCartViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
  }
`;

export const CheckoutItemsContainer = styled.div`
  flex: 1;
  margin-bottom: 30px;

  @media (min-width: ${ScreenSize.XS}px) {
    margin-right: 85px;
  }
`;

export const CheckoutItemContainer = styled.div`
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e7e7e7;

  &:last-child {
    border-bottom: none;
  }
`;

export const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${ScreenSize.XS}px) {
    max-width: 345px;
  }
`;

export const OrderSummaryContainer = styled.div`
  margin-bottom: 30px;
`;

export const CheckoutButtonContainer = styled.div`
  margin-bottom: 25px;
`;

export const SupportedIssuersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

  p {
    margin-right: 15px;
  }

  img {
    height: 20px;
    margin-right: 0px;
  }
`;