import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

const SkeletonContainer = styled.div`
  background-color: #eef2f7;
`;

const SkeletonBoxContaier = styled(SkeletonContainer)`
  width: 100%;
  height: 100%;
  
`;

interface SkeletonBoxProps {
  style?: CSSProperties;
}

export function SkeletonBox(props: SkeletonBoxProps) {
  const { style } = props;

  return (
    <SkeletonBoxContaier style={{ ...style }} />
  );
}

const SkeletonTextContaier = styled(SkeletonContainer)`
  width: 100%;
  height: 1em;
`;

interface SkeletonTextProps {
  style?: CSSProperties;
}

export function SkeletonText(props: SkeletonTextProps) {
  const { style } = props;

  return (
    <SkeletonTextContaier style={{ ...style }} />
  );
}