import {
  SignInState,
  SignInActionType,
  SIGN_IN_PENDING,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR
} from './types';

const initialState: SignInState = {
  loading: false,
  loadingError: false,
  response: null
};

function SignInReducer(
  state = initialState,
  action: SignInActionType
): SignInState {
  switch (action.type) {
    case SIGN_IN_PENDING:
      return {
        ...state,
        loading: true
      };

    case SIGN_IN_SUCCESS:
      const { payload } = action;
      const { signInResponse } = payload;

      return {
        ...state,
        loading: false,
        loadingError: false,
        response: signInResponse
      };

    case SIGN_IN_ERROR:
      return {
        ...state,
        loading: false,
        loadingError: true,
      };

    default:
      return state;
  }
}

export default SignInReducer;
