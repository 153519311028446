import { GetProductsRequest, GetProductsResponse } from "../../../repositories/ProductRepository";

import {
  TRIGGER_FETCH_PRODUCTS,
  FETCH_PRODUCTS_PENDING,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  RESET_STORE_VIEW,
  TriggerFetchProducts,
  FetchProductsPending,
  FetchProductsSuccess,
  FetchProductsError,
  ResetStoreView
} from "./types";
import { StoreProduct } from "../../../entities/StoreProduct";




export const triggerFetchProducts = (storeUrlEndpoint: string, request: GetProductsRequest): TriggerFetchProducts => {
  return {
    type: TRIGGER_FETCH_PRODUCTS,
    payload: {
      storeUrlEndpoint, request
    }
  };
};

export const fetchProductsPending = (): FetchProductsPending => {
  return {
    type: FETCH_PRODUCTS_PENDING
  };
};

export const fetchProductsSuccess = (
  payload: { products: StoreProduct[], totalProductCount: number }
): FetchProductsSuccess => {
  const { products, totalProductCount } = payload;

  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products, totalProductCount }
  };
};

export const fetchProductsError = (): FetchProductsError => {
  return {
    type: FETCH_PRODUCTS_ERROR
  };
};

export const resetStoreView = (): ResetStoreView => {
  return {
    type: RESET_STORE_VIEW
  };
};
