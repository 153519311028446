import React from 'react';
import styled, { css } from "styled-components";

import { ReactComponent as PreviousArrowIcon } from '../../assets/icons/previous-arrow.svg';
import { ReactComponent as NextArrowIcon } from '../../assets/icons/next-arrow.svg';
import { ScreenSize } from '../../styling/constants';
import { slice } from 'lodash';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ControlButton = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: #eaeaea;
  border-radius: 3px;

  &:last-child {
    margin-right: 0px;
  }

  svg {
    width: 10px;
    height: 10px;
  }

  p {
    display: none;
  }

  *:first-child {
    margin-right: 5px;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    width: 90px;

    p {
      display: inline-block;
    }
  }
`;

interface PageButtonProps {
  active: boolean;
}

const PageButton = styled.div<PageButtonProps>`
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;

  ${({ active }) => active && css`
    background-color: #1aa7e8;

    p {
      color: white;
    }
  `};
`;

const PageSeparator = styled.div`
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  p {
    font-size: 1.6rem;
  }
`;

const PAGE_SPREAD_LIMIT = 1;

interface Props {
  pageCount: number;
  currentPage: number;
  onPageButtonClick: (pageIndex: number) => void;
}

export default function Paginator(props: Props) {
  const { pageCount, currentPage, onPageButtonClick } = props;

  let pageIndexes: number[] = [];

  if (currentPage === 1) {
    const endIndex = Math.min(pageCount - 1, PAGE_SPREAD_LIMIT + 1);
    pageIndexes = Array.from({ length: endIndex + 1 }, (x, i) => i + 1);
  }

  if (currentPage === pageCount) {
    const startIndex = Math.max(0, pageCount - 1 - PAGE_SPREAD_LIMIT - 1);
    pageIndexes = Array.from({ length: pageCount - startIndex }, (x, i) => i + startIndex + 1);
  }

  if (currentPage != 1 && currentPage != pageCount) {
    const startIndex = Math.max(0, currentPage - 1 - PAGE_SPREAD_LIMIT);
    const endIndex = Math.min(pageCount - 1, currentPage - 1 + PAGE_SPREAD_LIMIT);

    pageIndexes = Array.from({ length: endIndex - startIndex + 1 }, (x, i) => i + startIndex + 1);
  }


  const previousButtonHandler = () => {
    if (currentPage === 1) return;
    onPageButtonClick(currentPage - 1);
  }

  const nextButtonHandler = () => {
    if (currentPage === pageCount) return;
    onPageButtonClick(currentPage + 1);
  }

  return (
    <Container>

      <ControlButton onClick={previousButtonHandler}>
        <PreviousArrowIcon />
        <p>Previous</p>
      </ControlButton>

      {pageIndexes[0] !== 1 && (
        <PageButton
          active={currentPage === 0}
          onClick={() => onPageButtonClick(1)}
        >
          <p>1</p>
        </PageButton>
      )}

      {pageIndexes[0] != 1 && pageIndexes[0] - 1 > 1 && (
        <PageSeparator>
          <p>...</p>
        </PageSeparator>
      )}

      {pageIndexes.map(pageIndex => {
        return (
          <PageButton
            key={pageIndex}
            active={currentPage === pageIndex}
            onClick={() => onPageButtonClick(pageIndex)}
          >
            <p>{pageIndex}</p>
          </PageButton>
        );
      })}

      {pageIndexes[pageIndexes.length - 1] != pageCount && pageIndexes[pageIndexes.length - 1] + 1 < pageCount && (
        <PageSeparator>
          <p>...</p>
        </PageSeparator>
      )}

      {pageIndexes[pageIndexes.length - 1] != pageCount && (
        <PageButton
          active={currentPage === pageCount - 1}
          onClick={() => onPageButtonClick(pageCount)}
        >
          <p>{pageCount}</p>
        </PageButton>
      )}

      <ControlButton onClick={nextButtonHandler}>
        <p>Next</p>
        <NextArrowIcon />
      </ControlButton>

    </Container>
  );
}
