import React, { ReactNode, CSSProperties, FormEvent, RefObject, forwardRef, useEffect } from "react";
import styled from "styled-components";
import TextControl from "../../../components/form-controls/TextControl";
import { useViewport } from "../../../providers/ViewportProvider";
import { ScreenSize } from "../../../styling/constants";

import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

const Container = styled.div`
  width: 100%;
  z-index: 20;
  position: relative;
`;

const CloseControl = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 15%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;


  @media (min-width: ${ScreenSize.XS}px) {
    display: flex;
  }
`;

interface Props {
  inputRef: RefObject<HTMLInputElement>;
  value: string;
  onChangeHandler: (value: string) => void;
  onFocusHandler: () => void;
  onBlurHandler: () => void;
  onSubmit: (isClearAction?: boolean) => void;
}

export const ControlContent = (props: Props) => {
  const { inputRef, value, onChangeHandler, onFocusHandler, onBlurHandler, onSubmit } = props;

  const viewportSize = useViewport();
  const isMobileView = viewportSize.width <= ScreenSize.XS;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeHandler(event.target.value);
  }

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.nativeEvent.preventDefault();
    onSubmit();
  }

  const closeControlHandler = () => {
    onChangeHandler("");
    onSubmit(true);
  }

  const containerStyle = isMobileView
    ? { height: '60px' } : {};

  const textFieldStyle = isMobileView
    ? { outline: 'none', border: 'none' } : { paddingRight: '15%' };

  return (
    <Container>
      <form onSubmit={onFormSubmit}>
        <TextControl
          controlRef={inputRef}
          value={value}
          placeholder={'Search products '}
          onChange={onChange}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          containerStyle={{ width: '100%', ...containerStyle }}
          style={textFieldStyle}
        />

        <CloseControl onClick={closeControlHandler}>
          <CloseIcon />
        </CloseControl>
      </form>
    </Container>
  );
};