import * as React from "react";
import { useRouteMatch } from "react-router-dom";

import RequestState from "entities/RequestState";
import useIsMountedRef from "hooks/useIsMountedRef";
import { productRepository } from "repositories";
import ProductBundle from "entities/ProductBundle";

export default function useGetProductBundle() {
  const { params } = useRouteMatch<{ bundleUrl: string }>();

  const isMountedRef = useIsMountedRef();

  const [reqState, setReqState] = React.useState<RequestState<ProductBundle>>({
    loading: false,
    error: false,
  });

  const fetchBundle = React.useCallback(async () => {
    setReqState((s) => ({ ...s, loading: true, error: false }));

    try {
      const res = await productRepository.getProductBundle(params.bundleUrl);
      if (isMountedRef.current) {
        setReqState({
          loading: false,
          error: false,
          response: res.data,
        });
      }
    } catch (error) {
      console.log({ error });
      if (isMountedRef.current) {
        setReqState({
          loading: false,
          error: true,
          response: undefined,
        });
      }
    }
  }, []);

  React.useEffect(() => {
    fetchBundle();
  }, []);

  return { ...reqState, fetchBundle };
}
