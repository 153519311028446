import {
  TRIGGER_FETCH_STORE_DETAILS,
  FETCH_STORE_DETAILS_PENDING,
  FETCH_STORE_DETAILS_SUCCESS,
  FETCH_STORE_DETAILS_ERROR,
  TriggerFetchStoreDetails,
  FetchStoreDetailsPending,
  FetchStoreDetailsSuccess,
  FetchStoreDetailsError
} from "./types";
import { StoreDetails } from "../../entities/StoreDetails";




export const triggerFetchStoreDetails = (storeUrlEndpoint: string): TriggerFetchStoreDetails => {
  return {
    type: TRIGGER_FETCH_STORE_DETAILS,
    payload: { storeUrlEndpoint }
  };
};

export const fetchStoreDetailsPending = (): FetchStoreDetailsPending => {
  return {
    type: FETCH_STORE_DETAILS_PENDING
  };
};

export const fetchStoreDetailsSuccess = (response: StoreDetails): FetchStoreDetailsSuccess => {
  return {
    type: FETCH_STORE_DETAILS_SUCCESS,
    payload: response
  };
};

export const fetchStoreDetailsError = (): FetchStoreDetailsError => {
  return {
    type: FETCH_STORE_DETAILS_ERROR
  };
};
