import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import mapKeys from "lodash/mapKeys";
import { v4 as uuidv4 } from 'uuid';

import RequestState from "entities/RequestState";
import useIsMountedRef from "hooks/useIsMountedRef";
import { productRepository } from "repositories";
import { CartItem } from "store/cart/types";
import ProductBundleProduct from "entities/ProductBundleProduct";
import { ProductVariantProperties } from "entities/StoreProductDetails";

export interface BundleProduct extends CartItem {
  productVariants?: ProductVariantProperties[];
}

export default function useGetBundleProducts() {
  const { params } = useRouteMatch<{ bundleUrl: string }>();

  const isMountedRef = useIsMountedRef();

  const [bundleProducts, setBundleProducts] = React.useState<{
    [key: string]: BundleProduct;
  } | null>(null);

  const [reqState, setReqState] = React.useState<
    RequestState<ProductBundleProduct[]>
  >({
    loading: false,
    error: false,
  });

  const fetchBundleProducts = React.useCallback(async () => {
    setReqState((s) => ({ ...s, loading: true, error: false }));

    try {
      const res = await productRepository.getProductBundleProducts(
        params.bundleUrl
      );
      if (isMountedRef.current) {
        setReqState({
          loading: false,
          error: false,
          response: res.data.list,
        });
      }
    } catch (error) {
      console.log({ error });
      if (isMountedRef.current) {
        setReqState({
          loading: false,
          error: true,
          response: undefined,
        });
      }
    }
  }, []);

  React.useEffect(() => {
    fetchBundleProducts();
  }, []);

  React.useEffect(() => {
    if (reqState.response)
      setBundleProducts(
        mapKeys(
          reqState.response.map((product) => {
            const variants = product.productVariants.filter(
              (v) => v.quantity !== 0
            );
            const variantName = product.hasVariant
              ? variants[0].variantName
              : undefined;
            const variantValue = product.hasVariant
              ? variants[0].variantValue
              : undefined;
            const variantPrice = product.hasVariant
              ? variants[0].price
              : undefined;

            return {
              id: `qtb-${uuidv4()}`,
              currencyCode: product.currencyCode,
              productData: {
                id: product.id,
                name: product.name,
                productUrlEndpoint: product.urlEndpoint,
                productImageUrl: product.imageFileUrl,
              },
              productPrice:
                product.hasVariant && variantPrice
                  ? variantPrice
                  : product.price,
              quantity: 1,
              variantName,
              variantValue,
              productVariants: product.productVariants,
            };
          }),
          (p) => p.id
        )
      );
  }, [reqState.response]);

  const updateBundleProductPrice = React.useCallback(
    (id: string, variant: ProductVariantProperties) => {
      setBundleProducts((p) => ({
        ...p,
        [id]: {
          ...(p || {})[id],
          productPrice: variant.price,
          variantName: variant.variantName,
          variantValue: variant.variantValue,
        },
      }));
    },
    []
  );

  return {
    ...reqState,
    fetchBundleProducts,
    bundleProducts,
    updateBundleProductPrice,
  };
}
