import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

type IconButtonSize = 'XS' | 'S' | 'M' | 'L';

interface ContainerProps {
  size: IconButtonSize;
  iconColor?: string;
  activeIconColor?: string;
  active?: boolean;
}
const Container = styled.div<ContainerProps>`
  ${({ size }) => {
    const controlImageRatio = 0.8;

    let controlHeight = 20;

    if (size === 'XS') controlHeight = 16;
    if (size === 'S') controlHeight = 20;
    if (size === 'M') controlHeight = 28;
    if (size === 'L') controlHeight = 40;

    return css`
      width: ${controlHeight}px;
      height: ${controlHeight}px;

      img, svg {
        width: ${controlHeight * controlImageRatio}px;
        height: ${controlHeight * controlImageRatio}px;
      }
  `;
  }}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ iconColor }) => iconColor && css`
    svg {
      fill: ${iconColor};
    }
  `};

    ${({ activeIconColor, active }) => activeIconColor && active && css`
    svg {
      fill: ${activeIconColor};
    }
  `};
`;



interface Props {
  size?: IconButtonSize;
  onClickHandler?: () => void;
  image: ReactNode;
  iconColor?: string;
  activeIconColor?: string;
  active?: boolean;
}

export default function IconButton(props: Props) {
  const { size, onClickHandler, image, iconColor, activeIconColor, active } = props;

  return (
    <Container
      size={size || 'M'}
      onClick={onClickHandler}
      iconColor={iconColor}
      activeIconColor={activeIconColor}
      active={active}
    >
      {image}
    </Container>
  );
}