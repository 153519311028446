import React, { useState, useEffect, useRef, ReactNode, CSSProperties } from 'react';
import styled, { css, keyframes } from 'styled-components';

import usePrevious from '../../hooks/usePrevious';
import { Button } from '../Button';

import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ScreenSize } from '../../styling/constants';

interface ContainerProps {
  style?: CSSProperties;
  opaque: boolean
}

const FadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled.div<ContainerProps>`
  opacity: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  margin-right: 0px;
  overflow: auto;
  box-sizing: border-box;
  background: white;
  transition: opacity 0.2s linear;
  animation: ${FadeAnimation} 0.2s linear;
  z-index: 100;
  
  ${({ opaque }) => (opaque) && css`
    opacity: 1;
    transition: opacity 0.2s linear;
  `}

  @media (min-width: ${ScreenSize.XS}px) {
    padding: 20px;
    background: rgba(0, 60, 88, 0.2);
  }
`;

const CloseControl = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  padding: 5px;
`;

interface WrapperProps {
  style: CSSProperties;
}

const ModalWrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  transition: height 1s linear;

  @media (min-width: ${ScreenSize.XS}px) {
    width: auto;
    margin: auto;
  }
`;


const ModalHeader = styled.div`
  background-color: #f7fafc;
  padding: 15px;
  padding-left: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  color: #003c58;
`;

const ModalSmallTitle = styled(ModalTitle)`
  font-size: 16px;
`;

const ModalSubTitle = styled.div`
  font-size: 15px;
  color: #676b71;
  margin-top: 5px;
`;

const ModalContent = styled.div`
`;

const buttonStyle = {
  width: 100,
  height: 45,
  marginBottom: 40
};

const buttonIconStyle = {
  width: 7,
  height: 13,
  fill: '#ffffff'
};

const buttonTextStyle = {
  fontSize: 15,
  fontWeight: 600,
  color: '#003c58'
};

interface Props {
  open: boolean;
  children: ReactNode;
  modalSmallTitle?: string;
  modalTitle?: string;
  wrapperStyle?: CSSProperties;
  modalSubTitle?: JSX.Element | string;
  backAction?: () => void;
  closeButtonHandler?: () => void;
}

export default function Modal(props: Props) {
  const {
    open,
    modalSmallTitle,
    modalTitle,
    modalSubTitle,
    children,
    backAction,
    wrapperStyle,
    closeButtonHandler
  } = props;

  const prevOpen = usePrevious(open);

  const [show, setShowState] = useState(open);
  const [opaque, setOpaque] = useState(open);

  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (open === prevOpen) return;

    if (open) {
      document.body.style.overflow = "hidden";

      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      setOpaque(true);
      setShowState(true);
      return;
    }

    document.body.style.overflow = "initial";

    setOpaque(false);

    timeoutRef.current = setTimeout(_ => setShowState(false), 600);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [open]);

  if (!show) return null;

  return (
    <ModalContainer opaque={opaque}>
      <ModalWrapper style={{ ...wrapperStyle }}>

        {closeButtonHandler && (
          <CloseControl onClick={closeButtonHandler}>
            <CloseIcon />
          </CloseControl>
        )}

        {(modalSmallTitle || modalTitle) && (
          <ModalHeader>
            {backAction && (
              <Button
                color="SECONDARY"
                icon={<BackIcon style={buttonIconStyle} />}
                text="Back"
                onClick={backAction}
                containerStyle={buttonStyle}
              />
            )}

            {modalSmallTitle && (
              <ModalSmallTitle>{modalSmallTitle}</ModalSmallTitle>
            )}

            {modalTitle && (
              <ModalTitle>{modalTitle}</ModalTitle>
            )}

            {modalSubTitle && (
              <ModalSubTitle>{modalSubTitle}</ModalSubTitle>
            )}
          </ModalHeader>
        )}

        <ModalContent>{children}</ModalContent>
      </ModalWrapper>
    </ModalContainer>
  );
}

export const ModalButtonContainer = styled.div`
  background-color: #f7fafc;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const ModalContentPadding = styled.div`
  padding: 10px 20px;

  p {
    line-height: 26px;
  }
`;
