import React, { useState } from 'react';
import { SummaryCard, CardRow, LabelValue } from '../../SummaryCard';
import styled from 'styled-components';
import OrderItem from './OrderItem';
import { CartItem } from '../../../store/cart/types';
import { getFormattedPrice } from '../../../util/currencyUtil';
import { ScreenSize } from '../../../styling/constants';

const OrderItemsContainer = styled(CardRow)`
  @media (min-width: ${ScreenSize.XS}px) {
    max-height: 400px;
    overflow: auto;
  }
`;

const OrderItemContainer = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

interface Props {
  cartItems: CartItem[];
  shippingFee: number | null;
}

export default function OrderSummaryContent(props: Props) {
  const { cartItems, shippingFee } = props;

  if (cartItems.length == 0) return null;

  let subtotal = 0;
  let itemTotal = 0;

  cartItems.map(cartItem => {
    const { quantity, productPrice } = cartItem;

    subtotal += productPrice * quantity;
    itemTotal += quantity;
  });

  const { currencyCode } = cartItems[0];

  let total: number | null = null;

  if (shippingFee != null) {
    total = subtotal + shippingFee;
  }


  return (
    <SummaryCard>

      <CardRow>
        <LabelValue>
          <h6>Order Summary</h6>
          <h6>{itemTotal} Item(s)</h6>
        </LabelValue>
      </CardRow>

      <OrderItemsContainer>
        {cartItems.map(cartItem => {
          const { productData, variantName, variantValue, quantity, currencyCode, productPrice } = cartItem;

          const {
            id, name, productImageUrl,
          } = productData;

          return (
            <OrderItemContainer>
              <OrderItem
                key={id}
                name={name}
                price={productPrice}
                currencyCode={currencyCode}
                quantity={quantity}
                variantName={variantName}
                variantValue={variantValue}
                imageSrcUrl={productImageUrl}

              />
            </OrderItemContainer>
          );
        })}
      </OrderItemsContainer>

      <CardRow>
        <LabelValue>
          <p>Subtotal</p>
          <p>{getFormattedPrice(subtotal, currencyCode)}</p>
        </LabelValue>

        <LabelValue>
          <p>Shipping</p>

          {shippingFee === null && (
            <p>-</p>
          )}

          {shippingFee != null && (
            <p>{getFormattedPrice(shippingFee, currencyCode)}</p>
          )}
        </LabelValue>

      </CardRow>

      <CardRow>
        <LabelValue>
          <h4>Total</h4>

          {total === null && (
            <h4>-</h4>
          )}

          {total != null && (
            <h4>{getFormattedPrice(total, currencyCode)}</h4>
          )}
        </LabelValue>
      </CardRow>

    </SummaryCard>
  );
}