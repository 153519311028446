import { takeLatest, put } from "redux-saga/effects";

import { TRIGGER_FETCH_STORE_DETAILS, TriggerFetchStoreDetails } from "./types";

import {
  triggerFetchStoreDetails,
  fetchStoreDetailsPending,
  fetchStoreDetailsSuccess,
  fetchStoreDetailsError
} from "./actions";
import { } from "../../repositories";
import { storeRepository } from "../../repositories";
import { GetStoreDetailsResponse } from "../../repositories/StoreRepository";


function* handleTriggerFetchStoreDetails(action: TriggerFetchStoreDetails) {
  const { payload } = action;
  const { storeUrlEndpoint } = payload;

  yield put(fetchStoreDetailsPending());

  try {
    const response: GetStoreDetailsResponse = yield storeRepository.getStoreDetails(storeUrlEndpoint);

    const { data } = response;
    yield put(fetchStoreDetailsSuccess(data));
  } catch (err) {
    yield put(fetchStoreDetailsError());
    return;
  }
}


export function* storeSaga() {
  yield takeLatest(TRIGGER_FETCH_STORE_DETAILS, handleTriggerFetchStoreDetails);
}
