import React from 'react';
import styled from "styled-components";
import NotificationWidget from '../../components/NotificationWidget';
import { ScreenSize } from '../../styling/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  @media (min-width: ${ScreenSize.XS}px) {

  }
`;


interface Props {
  actionHandler: () => void;
}

export default function FailedOrderView(props: Props) {
  const { actionHandler } = props;

  return (
    <Container>
      <NotificationWidget
        type="ERROR"
        title="Something went wrong!"
        message="We couldn’t complete this transaction. Kindly review your order and try again."
        action={actionHandler}
        actionText={"Go To Cart"}
      />
    </Container>
  );
}