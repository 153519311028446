import { StoreProduct } from "../entities/StoreProduct";
import { HttpClient } from "../core/api/HttpClient";
import { SERVICE_BASE_URL } from "../config/properties";
import { StoreProductDetails } from "../entities/StoreProductDetails";
import ProductBundle from "entities/ProductBundle";
import ProductBundleProduct from "entities/ProductBundleProduct";

export type GetProductsSortType =
  | "NEWEST"
  | "RECOMMENDED"
  | "LOWEST_PRICE"
  | "HIGHEST_PRICE";

export interface GetProductsRequest {
  productName?: string;
  productType?: string;
  productStatus?: string;
  sortBy?: GetProductsSortType;
  pageNumber: number;
  pageSize: number;
  productGroupIds?: string;
}

export interface GetProductsResponse {
  data: {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
    nextPageUrl: null;
    prevPageUrl: null;
    from: number;
    to: number;
    list: StoreProduct[];
  };
}

export interface GetProductDetailsResponse {
  data: StoreProductDetails;
}

export interface GetProductsDetailsRequest {
  storeId: number;
  merchantCode: string;
  productIds: number[];
}

export interface GetProductsDetailsResponse {
  data: StoreProductDetails[];
}
export interface GetProductBundleProductsResponse {
  list: ProductBundleProduct[];
}

export interface IProductRepository {
  getProducts(
    storeUrlEndpoint: string,
    request: GetProductsRequest
  ): Promise<GetProductsResponse>;
  getProductDetails(
    productUrlEndpoint: string
  ): Promise<GetProductDetailsResponse>;
}

export class ProductRepository implements IProductRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getProducts(
    storeUrlEndpoint: string,
    request: GetProductsRequest
  ): Promise<GetProductsResponse> {
    const response = await this.httpClient.get<GetProductsResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/products/public/store/${storeUrlEndpoint}`,
      { params: request }
    );

    return response.data;
  }

  async getProductDetails(
    productUrlEndpoint: string
  ): Promise<GetProductDetailsResponse> {
    const response = await this.httpClient.get<GetProductDetailsResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/products/public/url/${productUrlEndpoint}`
    );

    return response.data;
  }

  async getProductsDetails(
    request: GetProductsDetailsRequest
  ): Promise<StoreProductDetails[]> {
    const response = await this.httpClient.post<GetProductsDetailsResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/products/public/store/get-multiple-product-details`,
      request
    );

    return response.data.data;
  }

  async getProductBundle(productBundleUrl: string) {
    const response = await this.httpClient.get<{data: ProductBundle}>(
      `${SERVICE_BASE_URL}/storefront/api/v1/product-bundles/public/url/${productBundleUrl}`
    );
    
    return response.data;
  }

  async getProductBundleProducts(productBundleUrl: string) {
    const response = await this.httpClient.get<{data: GetProductBundleProductsResponse}>(
      `${SERVICE_BASE_URL}/storefront/api/v1/products/public/bundle/${productBundleUrl}`,
      { params: { pageSize: 0 } }
    );

    return response.data;
  }
}
