import {
  SET_AUTH_STATE,
  CLEAR_AUTH_STATE,
  AuthActionType,
  AuthState
} from './types';

const initialState = {
  accessToken: null,
  userProfile: null
};

function AuthReducer(state: AuthState = initialState, action: AuthActionType): AuthState {
  switch (action.type) {
    case SET_AUTH_STATE:
      const { payload } = action;
      const { userProfile } = payload;

      return {
        ...state,
        userProfile
      };

    case CLEAR_AUTH_STATE:
      return initialState;
    default:
      return state;
  }
}

export default AuthReducer;
