import {
  CheckoutViewActionType,
  CheckoutViewState,

  FETCH_SUPPORTED_COUNTRIES_PENDING,
  FETCH_SUPPORTED_COUNTRIES_SUCCESS,
  FETCH_SUPPORTED_COUNTRIES_ERROR,

  FETCH_SUPPORTED_STATES_PENDING,
  FETCH_SUPPORTED_STATES_SUCCESS,
  FETCH_SUPPORTED_STATES_ERROR,

  FETCH_SUPPORTED_CITIES_PENDING,
  FETCH_SUPPORTED_CITIES_SUCCESS,
  FETCH_SUPPORTED_CITIES_ERROR,

  INITIATE_PAYMENT_PENDING,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_ERROR,

  RESET_CHECKOUT_VIEW,
  FETCH_DELIVERY_METHODS_PENDING,
  FETCH_DELIVERY_METHODS_SUCCESS,
  FETCH_DELIVERY_METHODS_ERROR,
} from "./types";

const initialState: CheckoutViewState = {
  fetchingSupportedCountries: false,
  errorFetchingSupportedCountries: false,
  supportedCountries: [],

  fetchingSupportedStates: false,
  errorFetchingSupportedStates: false,
  supportedStates: [],

  fetchingSupportedCities: false,
  errorFetchingSupportedCities: false,
  supportedCities: [],

  fetchingDeliveryMethods: false,
  errorFetchingDeliveryMethods: false,
  deliveryMethodsData: null,

  initiatingPayment: false,
  errorInitiatingPayment: false,
  initiatePaymentResponseData: null
}

export function CheckoutViewReducer(
  state: CheckoutViewState = initialState,
  action: CheckoutViewActionType
): CheckoutViewState {
  switch (action.type) {

    case FETCH_SUPPORTED_COUNTRIES_PENDING: {
      return {
        ...state,
        fetchingSupportedCountries: true,
        errorFetchingSupportedCountries: false
      };
    }

    case FETCH_SUPPORTED_COUNTRIES_SUCCESS: {
      const { payload } = action;
      const { countries } = payload;

      return {
        ...state,
        fetchingSupportedCountries: false,
        errorFetchingSupportedCountries: false,
        supportedCountries: countries
      };
    }

    case FETCH_SUPPORTED_COUNTRIES_ERROR: {
      return {
        ...state,
        fetchingSupportedCountries: false,
        errorFetchingSupportedCountries: true
      };
    }



    case FETCH_SUPPORTED_STATES_PENDING: {
      return {
        ...state,
        fetchingSupportedStates: true,
        errorFetchingSupportedStates: false,
      };
    }

    case FETCH_SUPPORTED_STATES_SUCCESS: {
      const { payload } = action;
      const { states } = payload;

      return {
        ...state,
        fetchingSupportedStates: false,
        errorFetchingSupportedStates: false,
        supportedStates: states
      };
    }

    case FETCH_SUPPORTED_STATES_ERROR: {
      return {
        ...state,
        fetchingSupportedStates: false,
        errorFetchingSupportedStates: true,
      };
    }



    case FETCH_SUPPORTED_CITIES_PENDING: {
      return {
        ...state,
        fetchingSupportedCities: true,
        errorFetchingSupportedCities: false,
      };
    }

    case FETCH_SUPPORTED_CITIES_SUCCESS: {
      const { payload } = action;
      const { cities } = payload;

      return {
        ...state,
        fetchingSupportedCities: false,
        errorFetchingSupportedCities: false,
        supportedCities: cities
      };
    }

    case FETCH_SUPPORTED_CITIES_ERROR: {
      return {
        ...state,
        fetchingSupportedCities: false,
        errorFetchingSupportedCities: true,
      };
    }


    case FETCH_DELIVERY_METHODS_PENDING: {
      return {
        ...state,
        fetchingDeliveryMethods: true,
        errorFetchingDeliveryMethods: false,
      };
    }

    case FETCH_DELIVERY_METHODS_SUCCESS: {
      const { payload } = action;
      const { deliveryMethodsData } = payload;

      return {
        ...state,
        fetchingDeliveryMethods: false,
        errorFetchingDeliveryMethods: false,
        deliveryMethodsData,
      };
    }

    case FETCH_DELIVERY_METHODS_ERROR: {
      return {
        ...state,
        fetchingDeliveryMethods: false,
        errorFetchingDeliveryMethods: true,
      };
    }



    case INITIATE_PAYMENT_PENDING: {
      return {
        ...state,
        initiatingPayment: true,
        errorInitiatingPayment: false
      };
    }

    case INITIATE_PAYMENT_SUCCESS: {
      const { paylods } = action;
      const { data } = paylods;

      return {
        ...state,
        initiatingPayment: false,
        errorInitiatingPayment: false,
        initiatePaymentResponseData: data
      };
    }

    case INITIATE_PAYMENT_ERROR: {
      return {
        ...state,
        initiatingPayment: false,
        errorInitiatingPayment: true
      };
    }


    case RESET_CHECKOUT_VIEW: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

