import { WhiteLabelAcquirerConfigResponse } from 'entities/StoreDetails';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/RootReducer';
import styled from 'styled-components';

import CompletedContent from './CompletedContent';
import EditContent from './EditContent';

const Header = styled.div`
  margin-bottom: 20px;

  h6 {
    color: #003c58;
    font-size: 15px;
    font-weight: 700;
  }
`;

interface StoreDispatchProps { }

interface Props {
  completed: boolean;
  isGuestCheckout: boolean;
  customerEmail?: string;
  whiteLabelAcquirer?: WhiteLabelAcquirerConfigResponse;
  loginHandler: () => void;
  continueAsGuestHandler: () => void;
  editControlHandler: () => void;
}

const CheckoutTypeForm = (props: Props) => {
  const {
    completed,
    isGuestCheckout,
    customerEmail,
    whiteLabelAcquirer,
    loginHandler,
    continueAsGuestHandler,
    editControlHandler
  } = props;

  if(!whiteLabelAcquirer) return (<></>)
  
  return (
    <>
      <Header>
        <h6>How would you like to checkout</h6>
      </Header>

      {completed && (
        <CompletedContent
          isGuestCheckout={isGuestCheckout}
          customerEmail={customerEmail}
          editControlHandler={editControlHandler}
        />
      )}

      {!completed && (
        <EditContent
          loginButtonHandler={loginHandler}
          continueAsGuestButtonHandler={continueAsGuestHandler}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
});

export default connect(mapStateToProps)(CheckoutTypeForm);
