import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CategorySection, CategoryTitleContainer } from './style';
import VariationOption from '../VariationOption';
import { ProductVariantProperties } from '../../entities/StoreProductDetails';

const Container = styled.div``;

export const VariationOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;


interface Props {
  activeVariantValue?: string;
  productVariants: ProductVariantProperties[];
  setActiveVariant: (name: string, value: string) => void;
}

export default function VariantSection(props: Props) {
  const { activeVariantValue, productVariants, setActiveVariant } = props;

  const variantsMap: { [key: string]: ProductVariantProperties[] } = {};

  productVariants.forEach(variantData => {
    const { variantName } = variantData;

    if (!variantsMap[variantName]) {
      variantsMap[variantName] = [variantData];
    } else {
      variantsMap[variantName].push(variantData);
    }
  });




  return (
    <Container>

      {Object.keys(variantsMap).map(variantName => {
        const variantOptions = variantsMap[variantName];

        return (
          <CategorySection>
            <CategoryTitleContainer>
              <h6>{variantName}</h6>
            </CategoryTitleContainer>

            <VariationOptionsContainer>
              {variantOptions.map(variantOption => {
                const { variantValue, quantity } = variantOption;

                return (
                  <VariationOption
                    text={variantValue}
                    active={variantValue === activeVariantValue}
                    disabled={quantity === 0}
                    onClick={() => setActiveVariant(variantName, variantValue)}
                  />
                );
              })}

            </VariationOptionsContainer>

          </CategorySection>
        );
      })}

    </Container>
  );
}