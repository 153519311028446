import React from 'react';
import styled, { css } from "styled-components";
import AsyncImage from '../../components/AsyncImage';
import QuantityInput from '../../components/form-controls/QuantityInput';
import { getFormattedPrice } from '../../util/currencyUtil';
import { ColorTheme, ScreenSize } from '../../styling/constants';
import VariantValue from '../VariantValue';
import { Link } from 'react-router-dom';
import { AppState } from 'store/RootReducer';
import { WhiteLabelAcquirerConfigResponse } from 'entities/StoreDetails';
import { connect } from 'react-redux';

const IMAGE_CONTAINER_WIDTH = 90;
const IMAGE_CONTAINER_MARGIN = 20;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CartData = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  height: 100px;
  width: ${IMAGE_CONTAINER_WIDTH}px;
  margin-right: ${IMAGE_CONTAINER_MARGIN}px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  margin-right: 20px;
`;

const AmountContainer = styled.div`
  margin-bottom: 3px;

  p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4f4f4f;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 5px;

  a {
    cursor: pointer;
    color: ${ColorTheme.primaryColorLight};
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 24px;
  }
`;

const VariantsContainer = styled.div`
  margin-bottom: 10px;
`;

const ActionControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:after {
    content: ' ';
    width: 1px;
    height: 15px;
    background: #d1d1d1;
    display: block;
    margin: 0px 15px;
  }

  &:last-child:after {
    display: none;
  }
`;


const TextAction = styled.div`
  cursor: pointer;

  p {
    font-size: 1.4rem;
    color: ${ColorTheme.primaryColorLight};
  }
`;

const ProductUpdatesContainer = styled.div`
  @media (min-width: ${ScreenSize.XS}px) {
    margin-left: ${IMAGE_CONTAINER_WIDTH + IMAGE_CONTAINER_MARGIN}px;
  }
`;

type UpdateType = "WARNING" | "ERROR";

interface UpdateContainerProps {
  type: UpdateType;
}

const UpdateContainer = styled.div<UpdateContainerProps>`
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 4px;
  margin-top: 20px;

  p {
    font-size: 1.4rem;
  }

  ${({ type }) => type === 'ERROR' && css`
    background-color: #fadfdf;
    border-color: #e55f5f;

    p {
      color: #ee3131;
    }
  `};

  ${({ type }) => type === 'WARNING' && css`
    background-color: #fef0cc;
    border-color: #edcd7d;

    p {
      color: #b98502;
    }
  `};


`;

interface Props {
  isAvailable: boolean;
  exceedsAvailableQuantity: boolean;
  availableQuantity: number;
  priceChanged: boolean;
  previousPrice: number;

  productUrlEndpoint: string;
  name: string;
  price: number;
  currencyCode: string;
  productImageUrl?: string;
  quantity: number;
  variantName?: string;
  variantValue?: string;
  whiteLabelAcquirer?: WhiteLabelAcquirerConfigResponse;

  decrementQuantityHandler: () => void;
  incrementQuantityHandler: () => void;
  removeHandler: () => void;
}

const mapStateToProps = (state: AppState) => ({
  whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
})

const CartItemEntry = connect(mapStateToProps)((props: Props) =>  {
  const {
    isAvailable,
    exceedsAvailableQuantity,
    availableQuantity,
    priceChanged,
    previousPrice,

    productUrlEndpoint,
    name,
    price,
    currencyCode,
    productImageUrl,
    quantity,
    variantName,
    variantValue,
    whiteLabelAcquirer,

    decrementQuantityHandler,
    incrementQuantityHandler,
    removeHandler
  } = props;


  return (
    <Container>
      <CartData>
        <ImageContainer>
          <AsyncImage
            src={productImageUrl}
            alt="Product Image"
            imageStyle={{ objectFit: 'contain' }}
          />
        </ImageContainer>

        <DetailsContainer>

          <AmountContainer>
            <p>{getFormattedPrice(price, currencyCode)}</p>
          </AmountContainer>

          <TitleContainer>
            <Link 
              to={productUrlEndpoint}
              style={ whiteLabelAcquirer && { color: whiteLabelAcquirer.primaryColor }}
            >{(name)}</Link>
          </TitleContainer>

          {variantName && variantValue && (
            <VariantsContainer>
              <VariantValue
                variantName={variantName}
                variantValue={variantValue} />
            </VariantsContainer>
          )}


          <ActionControlsContainer>

            <ActionControl>
              <QuantityInput
                value={quantity}
                onDecrement={decrementQuantityHandler}
                onIncrement={incrementQuantityHandler}
                controlSize="S"
              />
            </ActionControl>

            <ActionControl>
              <TextAction onClick={removeHandler}>
                <p style={ whiteLabelAcquirer && { color: whiteLabelAcquirer.secondaryColor }}>Remove</p>
              </TextAction>
            </ActionControl>


          </ActionControlsContainer>

        </DetailsContainer>
      </CartData>

      <ProductUpdatesContainer>

        {!isAvailable && (
          <UpdateContainer type="ERROR">
            <p>This item is no longer available. Kindly remove from cart to proceed.</p>
          </UpdateContainer>
        )}

        {exceedsAvailableQuantity && availableQuantity === 0 && (
          <UpdateContainer type="ERROR">
            <p>This item is currently sold out. Kindly remove from cart to proceed.</p>
          </UpdateContainer>
        )}

        {exceedsAvailableQuantity && availableQuantity > 0 && (
          <UpdateContainer type="WARNING">
            <p>The seller only has <b>{availableQuantity}</b> of this product available. Kindly update the quantity to proceed.</p>
          </UpdateContainer>
        )}

        {priceChanged && (
          <UpdateContainer type="WARNING">
            <p>Please note that the price of this item has changed from <b>{getFormattedPrice(previousPrice, currencyCode)}</b> to <b>{getFormattedPrice(price, currencyCode)}</b>.</p>
          </UpdateContainer>
        )}

      </ProductUpdatesContainer>

    </Container>
  );
});

export default CartItemEntry;