import { takeLatest, put } from "redux-saga/effects";
import { TRIGGER_CREATE_CHECKOUT_ORDER, TriggerCreateCheckoutOrder } from "./types";

import {
  createCheckoutOrderPending,
  createCheckoutOrderSuccess,
  createCheckoutOrderError
} from "./actions";
import { orderRepository } from "../../repositories";
import { CreateCheckoutOrderResponse } from "../../repositories/OrderRepository";


function* handleTriggerCreateCheckoutOrder(action: TriggerCreateCheckoutOrder) {
  const { payload } = action;
  const { request } = payload;

  yield put(createCheckoutOrderPending());

  try {
    const response: CreateCheckoutOrderResponse = yield orderRepository.createCheckoutOrder(
      request
    );

    const { data } = response;
    yield put(createCheckoutOrderSuccess(data));
  } catch (err) {
    yield put(createCheckoutOrderError());
    return;
  }
}


export function* orderSaga() {
  yield takeLatest(TRIGGER_CREATE_CHECKOUT_ORDER, handleTriggerCreateCheckoutOrder);
}
