import { takeLatest, put } from "redux-saga/effects";

import { TRIGGER_FETCH_PRODUCTS, TriggerFetchProducts, RESET_STORE_VIEW } from "./types";

import {
  triggerFetchProducts,
  fetchProductsPending,
  fetchProductsSuccess,
  fetchProductsError
} from "./actions";
import { productRepository } from "../../../repositories";
import { GetProductsResponse } from "../../../repositories/ProductRepository";
import { createCancellableSaga } from "../../CancellableSaga";


function* handleTriggerFetchProducts(action: TriggerFetchProducts) {
  const { payload } = action;
  const { storeUrlEndpoint, request } = payload;

  yield put(fetchProductsPending());


  try {
    const response: GetProductsResponse = yield productRepository.getProducts(
      storeUrlEndpoint, request
    );

    const { data: { total, list } } = response;

    yield put(fetchProductsSuccess({ products: list, totalProductCount: total }));
  } catch (err) {
    yield put(fetchProductsError());
    return;
  }
}


export function* watchStoreView() {
  yield takeLatest(TRIGGER_FETCH_PRODUCTS, handleTriggerFetchProducts);
}


export const storeViewSaga = createCancellableSaga(watchStoreView, RESET_STORE_VIEW);