import React, { createContext, useEffect, useState, ReactNode, useContext } from "react";
import { throttle } from "lodash";

const ViewportContext = createContext<ViewportSize>({
  width: window.outerWidth,
  height: window.outerHeight,
});

interface ViewportSize {
  width: number;
  height: number;
}

interface Props {
  children: ReactNode;
}


export function ViewportProvider(props: Props) {
  const { children } = props;

  const [viewportSize, setViewportSize] = useState<ViewportSize>({
    width: window.outerWidth,
    height: window.outerHeight
  });

  const handleWindowResize = () => {
    setViewportSize({
      width: window.outerWidth,
      height: window.outerHeight
    });
  };

  useEffect(() => {
    const throttledHandleWindowResize = throttle(handleWindowResize, 16);

    window.addEventListener('resize', throttledHandleWindowResize);
    return () => window.removeEventListener('resize', throttledHandleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={viewportSize}>
      {children}
    </ViewportContext.Provider>
  );
}


export function useViewport(): ViewportSize {
  return useContext(ViewportContext);
}