import React from 'react';
import styled from "styled-components";
import AsyncImage from '../../components/AsyncImage';
import { ScreenSize } from '../../styling/constants';

const Container = styled.div`
  position: relative;
  border: solid 1px #d7d7d7;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row-reverse;
  }
`;

const ActiveIndicator = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 4px;
  background-color: #1aa7e8;
  opacity: ${({ show }) => show ? 1 : 0};

  @media (min-width: ${ScreenSize.XS}px) {
    width: 4px;
    height: 100%;
    top: 0px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

interface Props {
  imageSrc: string;
  active: boolean;
}

export default function ProductImageListItem(props: Props) {
  const { imageSrc, active } = props;

  return (
    <Container>

      <ImageContainer>
        <AsyncImage
          src={imageSrc}
          alt="Product Image"
          imageStyle={{ objectFit: 'contain' }}
        />
      </ImageContainer>

      <ActiveIndicator show={active} />

    </Container>
  );
}