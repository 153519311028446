import {
  CustomerAddressState,
  CustomerAddressesActionType,

  FETCH_CUSTOMER_ADDRESSES_PENDING,
  FETCH_CUSTOMER_ADDRESSES_SUCCESS,
  FETCH_CUSTOMER_ADDRESSES_ERROR,


  ADD_CUSTOMER_ADDRESS_PENDING,
  ADD_CUSTOMER_ADDRESS_SUCCESS,
  ADD_CUSTOMER_ADDRESS_ERROR,

  DELETE_CUSTOMER_ADDRESS_PENDING,
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  DELETE_CUSTOMER_ADDRESS_ERROR,

  ADD_CUSTOMER_ADDRESS,

  DELETE_CUSTOMER_ADDRESS,

  RESET_CUSTOMER_ADDRESSES,
} from './types';

const initialState: CustomerAddressState = {
  customerAddresses: null,
  customerAddressesLoading: false,
  customerAddressesLoadingError: false,

  creatingCustomerAddress: false,
  creatingCustomerAddressError: false,

  deletingCustomerAddress: false,
  deletingCustomerAddressError: false
};

const CustomerAddressReducer = (
  state = initialState, action: CustomerAddressesActionType
): CustomerAddressState => {
  switch (action.type) {
    case FETCH_CUSTOMER_ADDRESSES_PENDING: {
      return {
        ...state,
        customerAddressesLoading: true
      };
    }


    case FETCH_CUSTOMER_ADDRESSES_SUCCESS: {
      const { payload } = action;
      const { customerAddresses } = payload;

      return {
        ...state,
        customerAddresses: customerAddresses,
        customerAddressesLoading: false,
        customerAddressesLoadingError: false
      };
    }

    case FETCH_CUSTOMER_ADDRESSES_ERROR: {
      return {
        ...state,
        customerAddressesLoading: false,
        customerAddressesLoadingError: true
      };
    }



    case ADD_CUSTOMER_ADDRESS_PENDING: {
      return {
        ...state,
        creatingCustomerAddress: true,
      };
    }


    case ADD_CUSTOMER_ADDRESS_SUCCESS: {
      return {
        ...state,
        creatingCustomerAddress: false,
        creatingCustomerAddressError: false
      };
    }


    case ADD_CUSTOMER_ADDRESS_ERROR: {
      return {
        ...state,
        creatingCustomerAddress: false,
        creatingCustomerAddressError: true,
      };
    }




    case DELETE_CUSTOMER_ADDRESS_PENDING: {
      return {
        ...state,
        deletingCustomerAddress: true,
      };
    }


    case DELETE_CUSTOMER_ADDRESS_SUCCESS: {
      return {
        ...state,
        deletingCustomerAddress: false,
        deletingCustomerAddressError: false,
      };
    }


    case DELETE_CUSTOMER_ADDRESS_ERROR: {
      return {
        ...state,
        deletingCustomerAddress: false,
        deletingCustomerAddressError: true,
      };
    }




    case ADD_CUSTOMER_ADDRESS: {
      const { customerAddress } = action.payload;

      const { customerAddresses } = state;

      if (!customerAddresses) return state;

      const newCustomerAddresses = [customerAddress, ...customerAddresses];

      return {
        ...state,
        customerAddresses: newCustomerAddresses
      };
    }




    case DELETE_CUSTOMER_ADDRESS: {
      const { customerAddressId } = action.payload;

      const { customerAddresses } = state;

      if (!customerAddresses) return state;

      const newCustomerAddresses = customerAddresses.filter(address => {
        return address.id !== customerAddressId;
      })

      return {
        ...state,
        customerAddresses: newCustomerAddresses
      };
    }




    case RESET_CUSTOMER_ADDRESSES: {
      return initialState;
    }


    default:
      return state;
  }
};

export default CustomerAddressReducer;
