import {
  FETCH_PRODUCTS_PENDING,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  RESET_STORE_VIEW,
  StoreViewState, StoreViewActionType
} from "./types";

const initialState: StoreViewState = {
  products: [],
  totalProductCount: 0,
  fetchingProducts: true,
  fetchingProductsError: false
}

export function StoreViewReducer(
  state: StoreViewState = initialState,
  action: StoreViewActionType
): StoreViewState {
  switch (action.type) {

    case FETCH_PRODUCTS_PENDING: {
      return {
        ...state,
        fetchingProducts: true
      };
    }

    case FETCH_PRODUCTS_SUCCESS: {
      const { payload } = action;
      const { products, totalProductCount } = payload;

      return {
        ...state,
        products,
        totalProductCount,
        fetchingProducts: false,
        fetchingProductsError: false
      };
    }

    case FETCH_PRODUCTS_ERROR: {
      return {
        ...state,
        fetchingProducts: false,
        fetchingProductsError: true
      };
    }

    case RESET_STORE_VIEW: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
