import React, { CSSProperties, DetailedHTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { ColorTheme, FormControlsTheme } from '../../styling/constants';

type Size = 'S' | 'M' | 'L';

interface ContainerProps {
  size: Size;
}

const ActionButton = styled.div`
  height: 100%;
  width: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;  

  p {
    font-size: 1.8rem;
  }
`;

const Container = styled.div<ContainerProps>`
  width: 120px;
  height: ${FormControlsTheme.controlHeight.default}px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${ColorTheme.formControls.borderColor};
  box-sizing: border-box;
  border-radius: 2px;

  ${({ size }) => {

    if (size === 'S') {
      return css`
        width: 85px;
        height: 30px;
      `;
    }

    if (size === 'L') {
      return css``;
    }

    return css``;
  }};

  input {
    flex: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid ${ColorTheme.formControls.borderColor};
    border-top: none;
    border-bottom: none;
    outline: none;
    text-align: center;
    border-radius: 0px;
  }

  & ${ActionButton} {
    width: 35px;

    ${({ size }) => {

    if (size === 'S') {
      return css`
        width: 23px;
      `;
    }

    if (size === 'L') {
      return css``;
    }

    return css``;
  }};
  }
`;


interface OwnProps {
  onIncrement: () => void;
  onDecrement: () => void;
  controlSize?: Size;
  containerStyle?: CSSProperties;
}

type Props = OwnProps & DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;


export default function QuantityInput(props: Props) {
  const { onIncrement, onDecrement, controlSize, containerStyle, ...inputProps } = props;

  return (
    <Container size={controlSize || 'M'} style={{ ...containerStyle }}>

      <ActionButton onClick={onDecrement}>
        <p>-</p>
      </ActionButton>

      <input readOnly {...inputProps} />

      <ActionButton onClick={onIncrement}>
        <p>+</p>
      </ActionButton>

    </Container>
  );
}