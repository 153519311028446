
import {
  TRIGGER_FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_PENDING,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_ERROR,

  TRIGGER_FETCH_PRODUCTS,
  FETCH_PRODUCTS_PENDING,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,

  TriggerFetchOrderDetails,
  FetchOrderDetailsPending,
  FetchOrderDetailsSuccess,
  FetchOrderDetailsError,

  FetchProductsPending,
  FetchProductsSuccess,
  FetchProductsError,
  TriggerFetchProducts,
} from "./types";
import { OrderStatusDetails } from "../../../repositories/OrderRepository";
import { StoreProduct } from "../../../entities/StoreProduct";
import { GetProductsRequest } from "../../../repositories/ProductRepository";



export const triggerFetchOrderDetails = (orderId: string): TriggerFetchOrderDetails => {
  return {
    type: TRIGGER_FETCH_ORDER_DETAILS,
    payload: { orderId }
  };
};

export const fetchOrderDetailsPending = (): FetchOrderDetailsPending => {
  return {
    type: FETCH_ORDER_DETAILS_PENDING
  };
};

export const fetchOrderDetailsSuccess = (orderDetails: OrderStatusDetails): FetchOrderDetailsSuccess => {

  return {
    type: FETCH_ORDER_DETAILS_SUCCESS,
    payload: { orderDetails }
  };
};

export const fetchOrderDetailsError = (): FetchOrderDetailsError => {
  return {
    type: FETCH_ORDER_DETAILS_ERROR
  };
};


export const triggerFetchProducts = (storeUrlEndpoint: string, request: GetProductsRequest): TriggerFetchProducts => {
  return {
    type: TRIGGER_FETCH_PRODUCTS,
    payload: {
      storeUrlEndpoint, request
    }
  };
};

export const fetchProductsPending = (): FetchProductsPending => {
  return {
    type: FETCH_PRODUCTS_PENDING
  };
};

export const fetchProductsSuccess = (
  payload: { products: StoreProduct[], totalProductCount: number }
): FetchProductsSuccess => {
  const { products, totalProductCount } = payload;

  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products, totalProductCount }
  };
};

export const fetchProductsError = (): FetchProductsError => {
  return {
    type: FETCH_PRODUCTS_ERROR
  };
};