import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";

import StoreHeader from "./StoreHeader";
import StoreFooter from "./StoreFooter";
import { ScreenSize, MAX_STORE_VIEW_WIDTH } from "../styling/constants";
import { ViewportProvider } from "../providers/ViewportProvider";
import { triggerFetchStoreDetails } from "../store/store/actions";
import { AppState } from "../store/RootReducer";
import { StoreActionType } from "../store/store/types";

import StoreView from "./StoreView";
import StoreProductView from "./StoreProductView";
import InvalidStoreView from "./InvalidStoreView";
import LoadingStoreView from "./LoadingStoreView";
import { StoreDetails } from "../entities/StoreDetails";
import ShoppingCartView from "./ShoppingCartView";
import CheckoutView from "./CheckoutView";
import OrderStatusView from "./OrderStatusView";
import usePrevious from "../hooks/usePrevious";
import { triggerRestoreCartItems } from "../store/cart/actions";
import { CartActionType } from "../store/cart/types";
import AddToCartNotificationToast from "./AddToCartNotification";
import { ToastBoard } from "../components/ToastBoard";
import { Toast } from "../components/ToastBoard/types";

import { AuthActionType } from "../store/auth/auth/types";
import { triggerHydrateAuthStore } from "../store/auth/auth/actions";
import Home from "./Home";
import StoreProductBundle from "./StoreProductBundle";

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    /* overflow: hidden; */
  }
`;

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ActiveViewContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 160px);
  max-width: ${MAX_STORE_VIEW_WIDTH}px;
  display: flex;
  margin: auto;
  margin-top: 0px;
  box-sizing: border-box;

  @media (min-width: ${ScreenSize.XS}px) {
    flex: 1;
    margin: auto;
    width: 100%;
    max-width: ${MAX_STORE_VIEW_WIDTH}px;
  }
`;

interface StoreStateProps {
  toasts: Toast[];
  fetchingStoreDetails: boolean;
  fetchingStoreDetailsError: boolean;
  storeDetails: StoreDetails;
}

interface StoreDispatchProps {
  triggerFetchStoreDetails: (storeUrlEndpoint: string) => void;
  triggerRestoreCartItems: (storeUrlEndpoint: string) => void;
  hydrateAuthStore: () => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

function RootView(props: Props) {
  const {
    toasts,
    storeDetails,
    fetchingStoreDetails,
    fetchingStoreDetailsError,

    triggerFetchStoreDetails,
    triggerRestoreCartItems,
    hydrateAuthStore,
  } = props;

  const fetchingStoreDetailsPrev = usePrevious(fetchingStoreDetails);

  const history = useHistory();

  const { location } = history;

  const { pathname } = location;

  const storeUrlEndpoint = pathname.split("/")[1];

  useEffect(() => {
    hydrateAuthStore();

    triggerFetchStoreDetails(storeUrlEndpoint);
    // if (storeUrlEndpoint) triggerFetchStoreDetails(storeUrlEndpoint);
  }, []);

  useEffect(() => {
    if (
      !fetchingStoreDetails &&
      fetchingStoreDetailsPrev &&
      !fetchingStoreDetailsError
    ) {
      const { urlEndpoint } = storeDetails;

      triggerRestoreCartItems(urlEndpoint);
    }
  }, [fetchingStoreDetails]);

  // if (storeUrlEndpoint && fetchingStoreDetails) {
  if (fetchingStoreDetails) {
    return <LoadingStoreView />;
  }

  if (fetchingStoreDetailsError) {
    return <InvalidStoreView noStoreFound />;
  }

  // const { status: storeStatus } = storeDetails;

  if (storeDetails && storeDetails.status === "CLOSED") {
    return <InvalidStoreView storeClosed />;
  }

  return (
    <ViewportProvider>
      <Container>
        {storeDetails && <StoreHeader />}

        <ToastBoard toasts={toasts} closeHandler={() => {}} />

        <BodyContainer>
          <Switch>
            {/* <Route exact path="/" component={Home} /> */}

            <ActiveViewContainer>
              <AddToCartNotificationToast />

              <Switch>
                <Route exact path="/:storeUrlEndpoint/" component={StoreView} />

                <Route
                  exact
                  path="/:storeUrlEndpoint/product/:productUrlEndpoint"
                  component={StoreProductView}
                />

                <Route
                  exact
                  path="/:storeUrlEndpoint/product-bundles/:bundleUrl"
                  component={StoreProductBundle}
                />

                <Route
                  exact
                  path="/:storeUrlEndpoint/cart"
                  component={ShoppingCartView}
                />

                <Route
                  exact
                  path="/:storeUrlEndpoint/checkout"
                  component={CheckoutView}
                />

                <Route
                  exact
                  path="/:storeUrlEndpoint/order-status/:orderId"
                  component={OrderStatusView}
                />
              </Switch>
            </ActiveViewContainer>
          </Switch>
          {storeDetails && <StoreFooter name="Yinkus Pinkus Stores" />}
        </BodyContainer>
      </Container>
    </ViewportProvider>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  toasts: state.ui.toastBoard.toasts,

  fetchingStoreDetails: state.store.fetchingStoreDetails,
  fetchingStoreDetailsError: state.store.fetchingStoreDetailsError,
  storeDetails: state.store.storeDetails as StoreDetails,
});

const mapDispatchToProps = (dispath: (action: StoreActionType | CartActionType | AuthActionType) => void): StoreDispatchProps => ({
  triggerFetchStoreDetails(storeUrlEndpoint: string) {
    dispath(triggerFetchStoreDetails(storeUrlEndpoint));
  },
  triggerRestoreCartItems(storeUrlEndpoint: string) {
    dispath(triggerRestoreCartItems(storeUrlEndpoint));
  },
  hydrateAuthStore() {
    dispath(triggerHydrateAuthStore());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RootView);
