import {
  TRIGGER_CREATE_CHECKOUT_ORDER,
  CREATE_CHECKOUT_ORDER_PENDING,
  CREATE_CHECKOUT_ORDER_SUCCESS,
  CREATE_CHECKOUT_ORDER_ERROR,
  TriggerCreateCheckoutOrder,
  CreateCheckoutOrderPending,
  CreateCheckoutOrderSuccess,
  CreateCheckoutOrderError,
} from "./types";
import { CreateCheckoutOrderRequest } from "../../repositories/OrderRepository";
import { OrderDetails } from "../../entities/OrderDetails";




export const triggerCreateCheckoutOrder = (request: CreateCheckoutOrderRequest): TriggerCreateCheckoutOrder => {
  return {
    type: TRIGGER_CREATE_CHECKOUT_ORDER,
    payload: { request }
  };
};

export const createCheckoutOrderPending = (): CreateCheckoutOrderPending => {
  return {
    type: CREATE_CHECKOUT_ORDER_PENDING
  };
};

export const createCheckoutOrderSuccess = (orderDetails: OrderDetails): CreateCheckoutOrderSuccess => {
  return {
    type: CREATE_CHECKOUT_ORDER_SUCCESS,
    payload: { orderDetails }
  };
};

export const createCheckoutOrderError = (): CreateCheckoutOrderError => {
  return {
    type: CREATE_CHECKOUT_ORDER_ERROR
  };
};


