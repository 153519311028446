import { StoreProduct } from "../../../entities/StoreProduct";
import { GetProductsRequest } from "../../../repositories/ProductRepository";

export const TRIGGER_FETCH_PRODUCTS = 'UI.STORE_VIEW.TRIGGER_FETCH_PRODUCTS';
export const FETCH_PRODUCTS_PENDING = 'UI.STORE_VIEW.FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'UI.STORE_VIEW.FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'UI.STORE_VIEW.FETCH_PRODUCTS_ERROR';

export const RESET_STORE_VIEW = 'UI.STORE_VIEW.RESET_STORE_VIEW';


export interface TriggerFetchProducts {
  type: typeof TRIGGER_FETCH_PRODUCTS;
  payload: {
    storeUrlEndpoint: string;
    request: GetProductsRequest;
  };
}

export interface FetchProductsPending {
  type: typeof FETCH_PRODUCTS_PENDING;
}

export interface FetchProductsSuccess {
  type: typeof FETCH_PRODUCTS_SUCCESS;
  payload: {
    products: StoreProduct[];
    totalProductCount: number;
  };
}

export interface FetchProductsError {
  type: typeof FETCH_PRODUCTS_ERROR;
}

export interface ResetStoreView {
  type: typeof RESET_STORE_VIEW;
}


export type StoreViewActionType =
  | TriggerFetchProducts
  | FetchProductsPending
  | FetchProductsSuccess
  | FetchProductsError
  | ResetStoreView;

export interface StoreViewState {
  products: StoreProduct[];
  totalProductCount: number;
  fetchingProducts: boolean;
  fetchingProductsError: boolean;
}