import { takeLatest, put } from "redux-saga/effects";

import {
  TRIGGER_FETCH_PRODUCT_DETAILS,
  RESET_STORE_PRODUCT_VIEW,
  TriggerFetchProductDetails
} from "./types";

import {
  fetchProductDetailsPending,
  fetchProductDetailsSuccess,
  fetchProductDetailsError,
} from "./actions";

import { productRepository } from "../../../repositories";
import { GetProductDetailsResponse } from "../../../repositories/ProductRepository";
import { createCancellableSaga } from "../../CancellableSaga";


function* handleTriggerFetchProductDetails(action: TriggerFetchProductDetails) {
  const { payload } = action;
  const { productUrlEndpoint } = payload;

  yield put(fetchProductDetailsPending());


  try {
    const response: GetProductDetailsResponse = yield productRepository.getProductDetails(
      productUrlEndpoint
    );

    const { data } = response;

    yield put(fetchProductDetailsSuccess(data));
  } catch (err) {
    yield put(fetchProductDetailsError());
    return;
  }
}


export function* watchStoreProductView() {
  yield takeLatest(TRIGGER_FETCH_PRODUCT_DETAILS, handleTriggerFetchProductDetails);
}


export const storeProductViewSaga = createCancellableSaga(watchStoreProductView, RESET_STORE_PRODUCT_VIEW);