import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter as Router } from 'connected-react-router';
import { store, history } from "./store";
import { Provider } from "react-redux";

import { GlobalStyle } from './styling/global';
import RootView from './views/RootView';

import './styling/fonts.css';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />

    <Provider store={store}>

      <Router history={history}>
        <RootView />
      </Router>

    </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
