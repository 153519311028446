import styled from "styled-components";
import {PAGE_TOP_PADDING, ScreenSize, BACK_NAVIGATOR_BOTTOM_MARGIN, ColorTheme} from '../../styling/constants';
import { RouteView } from '../../components/Layout';

export const FORMS_LEFT_OFFSET = 60;

export const Container = styled(RouteView)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    
  }
`;

export const BackControlContainer = styled.div`
  margin-bottom: ${BACK_NAVIGATOR_BOTTOM_MARGIN}px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
  flex-direction: row;
  }
`;

export const CheckoutStepsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    margin-right: 110px;
  }
`;

export const CheckoutStepContainer = styled.div`
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e6e6e6;
`;



export const CheckoutFormContainer = styled.div`
  margin-top: 15px;

  @media (min-width: ${ScreenSize.XS}px) {
    margin-left: ${FORMS_LEFT_OFFSET}px;
  }
`;

export const OrderDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${ScreenSize.XS}px) {
    max-width: 385px;
  }
`;

export const OrderSummaryContainer = styled.div`
  margin-bottom: 25px;
`;

export const SubmitControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  > div:first-of-type {
    margin-bottom: 15px;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
    
    > div {
      flex: 1;
      margin-right: 30%;
      
      &:first-of-type {
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const EditControl = styled.div`
  cursor: pointer;
  
  p {
    color: ${ColorTheme.primaryColor};
  }
`;
