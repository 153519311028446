import * as React from "react";
import debounce from "lodash.debounce";

import { StoreDetails } from "entities/StoreDetails";
import { useDispatch } from "react-redux";
import { triggerFetchStoreDetails } from "store/store/actions";
import { storeRepository } from "repositories";
import { SearchedStore } from "repositories/StoreRepository";

interface ReqState {
  loading: boolean;
  response?: SearchedStore[];
  error?: string;
}
export default function useStoreSearch() {
  const [searchString, setSearchString] = React.useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchString(value);
  };

  const [showDropdown, setShowDropdown] = React.useState(false);
  const closeDropdown = () => setShowDropdown(false);
  const openDropdown = () => setShowDropdown(true);

  const [reqState, setReqState] = React.useState<ReqState>({
    loading: false,
  });

  const searchStorefront = async () => {
    setReqState((r) => ({ ...r, loading: true }));
    try {
      const res = await storeRepository.searchStoreByName(searchString);
      setReqState({
        loading: false,
        response: res.data,
      });
    } catch (error) {
      setReqState({ loading: false, error: "Error" });
    }
  };

  const debouncedSearch = debounce(searchStorefront, 500);

  React.useEffect(() => {
    if (searchString.trim().length) {
      openDropdown();
    }
    debouncedSearch();
  }, [searchString]);

  const dispatch = useDispatch();

  const fetchSelectedStore = (url: string) => {
    dispatch(triggerFetchStoreDetails(url));
  };

  return {
    searchString,
    handleSearchChange,
    showDropdown,
    closeDropdown,
    openDropdown,
    reqState,
    fetchSelectedStore,
  };
}
