import * as React from "react";
import styled from "styled-components";
import { convertFromRaw } from "draft-js";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";

import { Button } from "components/Button";
import { ScreenSize } from "styling/constants";
import ProductBundle from "entities/ProductBundle";
import { getFormattedPrice } from "util/currencyUtil";
import { BundleProduct } from "../hooks/useGetBundleProducts";
import { addCartItems } from "store/cart/actions";
import { ReactComponent as ShareIcon } from "assets/icons/icn-share.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/icn-twitter.svg";
import { ReactComponent as FBIcon } from "assets/icons/icn-facebook.svg";
// import { ReactComponent as IgIcon } from "assets/icons/icn-instagram.svg";

interface Props {
  productBundle: ProductBundle;
  bundleProducts: { [key: string]: BundleProduct };
}
export default function BundleHeader(props: Props) {
  const { productBundle, bundleProducts } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch<{ storeUrlEndpoint: string }>();

  const addBundleToCart = () => {
    console.log(bundleProducts);
    dispatch(addCartItems({ cartItems: Object.values(bundleProducts) }));
    history.push({ pathname: `/${params.storeUrlEndpoint}/cart` });
  };

  const description = !!productBundle.description
    ? convertFromRaw(JSON.parse(productBundle.description)).getPlainText()
    : "";

  const total = React.useMemo(
    () =>
      Object.values(bundleProducts).reduce((prev, curr) => {
        const { productPrice } = curr;

        return prev + productPrice;
      }, 0),
    [bundleProducts]
  );

  const { currencyCode } = Object.values(bundleProducts)[0];

  const shareUrl = encodeURIComponent(window.location.href);
  const shareText = encodeURIComponent(productBundle.name);

  return (
    <HeaderContainer>
      <div className="left">
        <h4>{productBundle.name}</h4>
        <p>{description}</p>
        <div className="links">
          <p>
            <ShareIcon />
            <span>
              SHARE WITH <br /> FRIENDS
            </span>
          </p>
          <a
            href={`https://twitter.com/share?url=${shareUrl}&text=${shareText}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Twitter"
          >
            <TwitterIcon />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?t=${shareText}&u=${shareUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
          >
            <FBIcon />
          </a>
          {/* <a href="http://" target="_blank" rel="noopener noreferrer">
            <IgIcon />
          </a> */}
        </div>
      </div>
      <div className="right">
        <p>Get all {productBundle.totalNoOfProducts} product(s) for</p>
        <h5>{getFormattedPrice(total, currencyCode)}</h5>
        <Button
          text="ADD BUNDLE TO CART"
          color="PRIMARY"
          containerStyle={{ width: "100%" }}
          onClick={addBundleToCart}
        />
      </div>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .left {
    width: 600px;
    max-width: 100%;

    h4 {
      font-size: 2.4rem;
      color: #003c58;
      margin-bottom: 24px;
    }
    p {
      color: #6a6a6a;
    }
  }

  .links {
    margin-top: 37px;
    display: flex;
    align-items: center;
    gap: 15px;

    color: #666b72;

    p {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      gap: 15px;

      span {
        line-height: normal;
      }
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .right {
    width: 300px;
    max-width: 100%;

    > p,
    > h5 {
      color: #003c58;
      text-align: right;
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 6px;
    }

    h5 {
      font-size: 2.3rem;
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: ${ScreenSize.XS}px) {
    .right {
      width: 100%;
      margin-top: 50px;
      > p,
      > h5 {
        text-align: left;
      }
    }
  }
`;
