import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const DropdownOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
`;

export interface DropdownContentContainerProps {
  show: boolean;
  rightAlign?: boolean;
}

const showAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const DropdownContentContainer = styled.div<DropdownContentContainerProps>`
  display: ${({ show }) => show ? "block" : "none"};
  position: absolute;
  top: 100%;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 3px 5px -3px rgba(0,0,0,0.1), 0px 4px 5px 1px rgba(0,0,0,0.03), 0px 2px 10px 2px rgba(0,0,0,0.01);
  border: 1px solid #e6e6e6;
  animation: ${showAnimation} 0.3s ease-out;
  transform-origin: ${({ rightAlign }) => rightAlign ? "right top" : "left top"};
  z-index: 900;

  ${({ rightAlign }) => rightAlign ? css`
    right: 0px;
  `: css`
    left: 0px;
  `};
`;