import { compose, createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';

import createSagaMiddleware from 'redux-saga';
import RootReducer from './RootReducer';
import RootSaga from './RootSaga';
import { persistCustomerCart } from './cart/subscribers';


// @ts-ignore
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose;
const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();


function configureStore() {
  return createStore(RootReducer(history),
    composeEnhancers(applyMiddleware(sagaMiddleware)));
}

export const store = configureStore();

store.subscribe(persistCustomerCart);

sagaMiddleware.run(RootSaga, store.dispatch);
