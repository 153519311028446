import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import { DeliveryAddressFormState } from '../..';
import Modal from '../../../../components/Modal';
import usePrevious from '../../../../hooks/usePrevious';
import { AddCustomerAddressRequest, CustomerAddress, DeleteCustomerAddressRequest } from '../../../../repositories/CustomerRepository';
import { UserProfile } from '../../../../store/auth/auth/types';
import { triggerAddCustomerAddress, triggerDeleteCustomerAddress } from '../../../../store/customerAddresses/actions';
import { CustomerAddressesActionType } from '../../../../store/customerAddresses/types';
import { AppState } from '../../../../store/RootReducer';
import { triggerDisplayToast } from '../../../../store/ui/ToastBoard/actions';
import { ToastBoardActionType, TriggerDisplayToastPayload } from '../../../../store/ui/ToastBoard/types';
import { ScreenSize } from '../../../../styling/constants';
import NewAddressContent from './NewAddressContent';
import SelectAddressContent from './SelectAddressContent';

const Container = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: ${ScreenSize.XS}px) {
    width: 450px;
  }
`;

type ActiveView = "SELECT_ADDRESS" | "CREATE_ADDRESS";

interface StoreStateProps {
  userProfile: UserProfile;

  customerAddresses: CustomerAddress[];
  customerAddressesLoading: boolean;
  customerAddressesLoadingError: boolean;

  creatingCustomerAddress: boolean;
  creatingCustomerAddressError: boolean;

  deletingCustomerAddress: boolean;
  deletingCustomerAddressError: boolean;
}

interface StoreDispatchProps {
  displayToast: (payload: TriggerDisplayToastPayload) => void;
  addCustomerAddress: (request: AddCustomerAddressRequest) => void;
  deleteCustomerAddress: (request: DeleteCustomerAddressRequest) => void;
}

interface OwnProps {
  open: boolean;
  selectedAddressId: number | null;
  closeButtonHandler: () => void;
  onSelectedAddressChange: (addressId: number) => void;
}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;


export function CustomerAddressBook(props: Props) {
  const {
    open,
    selectedAddressId,
    closeButtonHandler,
    onSelectedAddressChange,

    userProfile,

    customerAddresses,
    customerAddressesLoading,
    customerAddressesLoadingError,

    creatingCustomerAddress,
    creatingCustomerAddressError,

    deletingCustomerAddress,
    deletingCustomerAddressError,

    displayToast,
    addCustomerAddress,
    deleteCustomerAddress
  } = props;

  const prevOpen = usePrevious(open);

  const prevCreatingCustomerAddress = usePrevious(creatingCustomerAddress);

  const [activeView, setActiveView] = useState<ActiveView>("SELECT_ADDRESS");
  const showSelectAddressView = () => setActiveView("SELECT_ADDRESS");
  const showCreateAddressView = () => setActiveView("CREATE_ADDRESS");

  const [deleteAddressId, setDeleteAddressId] = useState<number | null>(null);

  const createAddressButtonHandler = (formState: DeliveryAddressFormState) => {
    addCustomerAddress({
      country: formState.country,
      state: formState.state,
      area: formState.city,
      address: formState.address,
      customerName: formState.fullName,
      customerEmail: userProfile.email,
      customerPhoneNumber: userProfile.mobileNo,
    });
  }

  const deleteAddressButtonHandler = (addressId: number) => {
    const deleteApproed = window.confirm('Delete this address?');
    
    if (!deleteApproed) return;

    setDeleteAddressId(addressId);

    deleteCustomerAddress({
      customerEmail: userProfile.email,
      id: addressId
    });
  };

  useEffect(() => {
    // WHen address book is closed reset active view
    if (!open && prevOpen) {
      setActiveView("SELECT_ADDRESS");
    }
  }, [open]);

  useEffect(() => {
    if (!(!creatingCustomerAddress && prevCreatingCustomerAddress)) {
      return;
    }

    if (creatingCustomerAddressError) {
      displayToast({ toastType: "ERROR" });
      return;
    }

    setActiveView("SELECT_ADDRESS");
  }, [creatingCustomerAddress]);

  return (
    <Modal
      open={open}
      modalTitle="Address Book"
      closeButtonHandler={closeButtonHandler}

    >
      <Container>
        {activeView === "SELECT_ADDRESS" && (
          <SelectAddressContent
            customerAddresses={customerAddresses}
            selectedAddressId={selectedAddressId}
            deleteAddressId={deleteAddressId}
            deletingAddress={deletingCustomerAddress}
            onAddressSelect={onSelectedAddressChange}
            createAddressButtonHandler={showCreateAddressView}
            deleteAddressButtonHandler={deleteAddressButtonHandler}
          />
        )}

        {activeView === "CREATE_ADDRESS" && (
          <NewAddressContent
            creatingAddress={creatingCustomerAddress}
            submitButtonHandler={createAddressButtonHandler}
            cancelButtonHandler={showSelectAddressView}
          />
        )}
      </Container>
    </Modal>
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  userProfile: state.auth.auth.userProfile as UserProfile,

  customerAddresses: state.customerAddresses.customerAddresses as CustomerAddress[],
  customerAddressesLoading: state.customerAddresses.customerAddressesLoading,
  customerAddressesLoadingError: state.customerAddresses.customerAddressesLoadingError,

  creatingCustomerAddress: state.customerAddresses.creatingCustomerAddress,
  creatingCustomerAddressError: state.customerAddresses.creatingCustomerAddressError,

  deletingCustomerAddress: state.customerAddresses.deletingCustomerAddress,
  deletingCustomerAddressError: state.customerAddresses.deletingCustomerAddressError,
});

const mapDispatchToProps = (
  dispatch: (action: ToastBoardActionType | CustomerAddressesActionType) => void
): StoreDispatchProps => ({
  displayToast(payload: TriggerDisplayToastPayload) {
    dispatch(triggerDisplayToast(payload));
  },
  addCustomerAddress(request: AddCustomerAddressRequest) {
    dispatch(triggerAddCustomerAddress(request));
  },
  deleteCustomerAddress(request: DeleteCustomerAddressRequest) {
    dispatch(triggerDeleteCustomerAddress(request));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddressBook);