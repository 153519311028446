import { takeLatest, put } from "redux-saga/effects";

import {
  TRIGGER_FETCH_ORDER_DETAILS,
  RESET_ORDER_STATUS_VIEW,
  TriggerFetchOrderDetails,
  TRIGGER_FETCH_PRODUCTS,
  TriggerFetchProducts
} from "./types";

import {
  fetchOrderDetailsPending,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsError,
  fetchProductsPending,
  fetchProductsSuccess,
  fetchProductsError
} from "./actions";

import { orderRepository, productRepository } from "../../../repositories";
import { createCancellableSaga } from "../../CancellableSaga";
import { GetOrderDetailsResponse, OrderStatusDetails } from "../../../repositories/OrderRepository";
import { GetProductsResponse } from "../../../repositories/ProductRepository";


function* handleTriggerFetchOrderDetails(action: TriggerFetchOrderDetails) {
  const { payload } = action;
  const { orderId } = payload;

  yield put(fetchOrderDetailsPending());


  try {
    const response: OrderStatusDetails = yield orderRepository.getOrderDetails(
      orderId
    );

    yield put(fetchOrderDetailsSuccess(response));
  } catch (err) {
    yield put(fetchOrderDetailsError());
    return;
  }
}

function* handleTriggerFetchProducts(action: TriggerFetchProducts) {
  const { payload } = action;
  const { storeUrlEndpoint, request } = payload;

  yield put(fetchProductsPending());


  try {
    const response: GetProductsResponse = yield productRepository.getProducts(
      storeUrlEndpoint, request
    );

    const { data: { total, list } } = response;

    yield put(fetchProductsSuccess({ products: list, totalProductCount: total }));
  } catch (err) {
    yield put(fetchProductsError());
    return;
  }
}


export function* watchOrderDetailsView() {
  yield takeLatest(TRIGGER_FETCH_ORDER_DETAILS, handleTriggerFetchOrderDetails);
  yield takeLatest(TRIGGER_FETCH_PRODUCTS, handleTriggerFetchProducts);
}


export const orderDetailsViewSaga = createCancellableSaga(watchOrderDetailsView, RESET_ORDER_STATUS_VIEW);