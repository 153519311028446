import { all } from "redux-saga/effects";

import { storeSaga } from "./store/saga";
import { cartSaga } from "./cart/saga";
import { orderSaga } from "./order/saga";
import { authSaga } from "./auth/auth/saga";
import { signinSaga } from "./auth/signin/saga";

import { toastBoardSaga } from "./ui/ToastBoard/saga";
import { storeViewSaga } from "./ui/StoreView/saga";
import { storeProductViewSaga } from "./ui/StoreProductView/saga";
import { shoppingCartViewSaga } from "./ui/ShoppingCartView/saga";
import { checkoutViewSaga } from "./ui/CheckoutView/saga";
import { orderDetailsViewSaga } from "./ui/OrderStatusView/saga";
import { customerAddressesSaga } from './customerAddresses/saga';



export default function* RootSaga(dispatch: any) {
  yield all([
    storeSaga(),
    cartSaga(),
    orderSaga(),
    authSaga(),
    customerAddressesSaga(),

    toastBoardSaga(),
    storeViewSaga(),
    storeProductViewSaga(),
    shoppingCartViewSaga(),
    checkoutViewSaga(),
    orderDetailsViewSaga(),

    signinSaga(),

  ]);
}
