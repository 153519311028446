import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;

  p {
    font-size: 14px;
  }
  
  p:first-child {
    margin-right: 5px;
  }
  
`;

interface Props {
  variantName: string;
  variantValue: string;
}

export default function VariantValue(props: Props) {
  const { variantName, variantValue } = props;

  return (
    <Container>
      <p>{variantName}:</p>
      <p>{variantValue}</p>
    </Container>
  );
}