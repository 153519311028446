import { store } from ".."
import { SecureStorage } from "../../util/storageUtil";

export const getCartStorageKey = (storeUrlEndpoint: string) => {
  return `${storeUrlEndpoint}_CRT`;
};

export function persistCustomerCart(): void {
  const appState = store.getState();

  const {
    store: { storeDetails },
    cart: { cartItems, cartInitialised }
  } = appState;

  if (storeDetails === null || !cartInitialised) {
    return;
  };

  const { urlEndpoint } = storeDetails;

  SecureStorage.setValue(getCartStorageKey(urlEndpoint), cartItems);
}