import React, { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { Container, DropdownOverlay, DropdownContentContainer } from "./style";


interface Props {
  controlContent: ReactNode;
  dropdownContent: ReactNode;
  showDropdown: boolean;
  outerClickHandler?: () => void;
  rightAlign?: boolean;
  containerStyle?: React.CSSProperties;
  dropdownContainerStyle?: React.CSSProperties;
  containerClassName?: string;
  dropdownContainerClassName?: string;
}

export default function DropdownWrapper(props: Props) {
  const {
    controlContent,
    dropdownContent,
    showDropdown,
    outerClickHandler,
    rightAlign,
    containerStyle,
    dropdownContainerStyle,
    containerClassName,
    dropdownContainerClassName
  } = props;

  return (
    <Container style={containerStyle} className={containerClassName}>
      {controlContent}

      {showDropdown && <DropdownOverlay onClick={outerClickHandler} />}

      <DropdownContentContainer
        style={dropdownContainerStyle}
        show={showDropdown}
        rightAlign={rightAlign}
        className={dropdownContainerClassName}
      >
        {dropdownContent}
      </DropdownContentContainer>
    </Container>
  );
}