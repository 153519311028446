import { OrderStatusDetails } from "../../../repositories/OrderRepository";
import { StoreProduct } from "../../../entities/StoreProduct";
import { GetProductsRequest } from "../../../repositories/ProductRepository";

export const TRIGGER_FETCH_ORDER_DETAILS = 'UI.ORDER_STATUS_VIEW.TRIGGER_FETCH_ORDER_DETAILS';
export const FETCH_ORDER_DETAILS_PENDING = 'UI.ORDER_STATUS_VIEW.FETCH_ORDER_DETAILS_PENDING';
export const FETCH_ORDER_DETAILS_SUCCESS = 'UI.ORDER_STATUS_VIEW.FETCH_ORDER_DETAILS_SUCCESS';
export const FETCH_ORDER_DETAILS_ERROR = 'UI.ORDER_STATUS_VIEW.FETCH_ORDER_DETAILS_ERROR';

export const TRIGGER_FETCH_PRODUCTS = 'UI.ORDER_STATUS_VIEW.TRIGGER_FETCH_PRODUCTS';
export const FETCH_PRODUCTS_PENDING = 'UI.ORDER_STATUS_VIEW.FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'UI.ORDER_STATUS_VIEW.FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'UI.ORDER_STATUS_VIEW.FETCH_PRODUCTS_ERROR';

export const RESET_ORDER_STATUS_VIEW = 'UI.ORDER_STATUS_VIEW.RESET';


export interface TriggerFetchOrderDetails {
  type: typeof TRIGGER_FETCH_ORDER_DETAILS;
  payload: {
    orderId: string
  };
}

export interface FetchOrderDetailsPending {
  type: typeof FETCH_ORDER_DETAILS_PENDING;
}

export interface FetchOrderDetailsSuccess {
  type: typeof FETCH_ORDER_DETAILS_SUCCESS;
  payload: {
    orderDetails: OrderStatusDetails;
  };
}

export interface FetchOrderDetailsError {
  type: typeof FETCH_ORDER_DETAILS_ERROR;
}

export interface ResetOrderStatusView {
  type: typeof RESET_ORDER_STATUS_VIEW;
}


export interface TriggerFetchProducts {
  type: typeof TRIGGER_FETCH_PRODUCTS;
  payload: {
    storeUrlEndpoint: string;
    request: GetProductsRequest;
  };
}

export interface FetchProductsPending {
  type: typeof FETCH_PRODUCTS_PENDING;
}

export interface FetchProductsSuccess {
  type: typeof FETCH_PRODUCTS_SUCCESS;
  payload: {
    products: StoreProduct[];
    totalProductCount: number;
  };
}

export interface FetchProductsError {
  type: typeof FETCH_PRODUCTS_ERROR;
}

export type OrderStatusViewActionType =
  | TriggerFetchOrderDetails
  | FetchOrderDetailsPending
  | FetchOrderDetailsSuccess
  | FetchOrderDetailsError
  | ResetOrderStatusView
  | TriggerFetchProducts
  | FetchProductsPending
  | FetchProductsSuccess
  | FetchProductsError;

export interface OrderStatusViewState {
  fetchingOrderDetails: boolean;
  errorFetchingOrderDetails: boolean;
  orderDetails: OrderStatusDetails | null;

  products: StoreProduct[];
  fetchingProducts: boolean;
  fetchingProductsError: boolean;
}