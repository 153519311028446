import { GetProductsDetailsRequest, GetProductsRequest, GetProductsResponse } from "../../../repositories/ProductRepository";

import {
  TRIGGER_FETCH_PRODUCTS_DETAILS,
  TriggerFetchProductsDetails,

  FETCH_PRODUCTS_DETAILS_PENDING,
  FetchProductsDetailsPending,

  FETCH_PRODUCTS_DETAILS_SUCCESS,
  FetchProductsDetailsSuccess,

  FETCH_PRODUCTS_DETAILS_ERROR,
  FetchProductsDetailsError,

  RESET_SHOPPING_CART_VIEW,
  ResetShoppingCartView,
} from "./types";
import { StoreProductDetails } from "../../../entities/StoreProductDetails";





export const triggerFetchProductsDetails = (request: GetProductsDetailsRequest): TriggerFetchProductsDetails => {
  return {
    type: TRIGGER_FETCH_PRODUCTS_DETAILS,
    payload: request
  };
};

export const fetchProductsDetailsPending = (): FetchProductsDetailsPending => {
  return {
    type: FETCH_PRODUCTS_DETAILS_PENDING
  };
};

export const fetchProductsDetailsSuccess = (payload: { productsDetails: StoreProductDetails[] }): FetchProductsDetailsSuccess => {

  return {
    type: FETCH_PRODUCTS_DETAILS_SUCCESS,
    payload,
  };
};

export const fetchProductsDetailsError = (): FetchProductsDetailsError => {
  return {
    type: FETCH_PRODUCTS_DETAILS_ERROR
  };
};



export const resetShoppingCartView = (): ResetShoppingCartView => {
  return {
    type: RESET_SHOPPING_CART_VIEW
  };
};