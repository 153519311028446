import React from 'react';
import styled from 'styled-components';
import NotificationWidget from "../components/NotificationWidget";
import { ColorTheme, PAGE_SIDE_PADDING, ScreenSize } from '../styling/constants';

import LogoImage from '../assets/images/app-logo.png';
import StoreSearchInput from "components/StoreSearchInput";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${ColorTheme.backgroundColor};
  padding: 0px ${PAGE_SIDE_PADDING}px;
  box-sizing: border-box;
`;

const ImageContainer = styled.div`
  margin: 40px 0px;

  img {
    height: 35px;
  }
`;

const NotificationContainer = styled.div`
  margin-bottom: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    margin-bottom: 0px;
    margin-right: 50px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

interface Props {
  noStoreFound?: boolean;
  storeClosed?: boolean;
  storeName?: string;
}

export default function InvalidStoreView(props: Props) {
  const {
    noStoreFound = false,
    storeClosed = false,
    storeName = "Store",
  } = props;

  return (
    <Container>
      <ImageContainer>
        <img alt='logo' src={LogoImage} />
      </ImageContainer>

      <NotificationContainer>
        {noStoreFound && (
          <>
            <NotificationWidget
              title="Oops! Storefront Not Found"
              message="The storefront you are trying to view was not found or does not exist. Kindly confirm the url is correct or use the search box below to search for the appropriate storefront."
              type="ERROR"
            />
            <StoreSearchInput />
          </>
        )}

        {storeClosed && (
          <NotificationWidget
            title={`${storeName} is Closed`}
            message="The store is currently closed. Please contact support for further assistance."
            type="ERROR"
          />
        )}
      </NotificationContainer>

      <LinksContainer>
        <LinkContainer>
          <a href="#">About Quickteller Storefront</a>
        </LinkContainer>

        <LinkContainer>
          <a href="#">Setup a store for FREE</a>
        </LinkContainer>
      </LinksContainer>
    </Container>
  );
}
