import React, { useState, ReactNode } from 'react';
import styled, { css } from "styled-components";
import RadialButton from '../../../components/RadialButton';
import { ColorTheme, ScreenSize } from '../../../styling/constants';


const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const RadialControlContainer = styled.div`
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

interface OptionTitleProps {
  disabled: boolean;
}

const OptionTitle = styled.div<OptionTitleProps>`
  h6 {
    font-size: 1.4rem;
    color: ${ColorTheme.primaryColorDark};
  }

  ${({ disabled }) => disabled && css`
    h6 {
      color: #aaaaaa;
      font-weight: 100;
    }
  `}
`;

const OptionDescription = styled.div`
  margin-top: 25px;

  @media (min-width: ${ScreenSize.XS}px) {
    margin-left: 30px;
  }

  p {
    /* font-size: 1.4rem; */
  }
`;

const OptionContent = styled.div`
  margin-top: 10px;
  
  @media (min-width: ${ScreenSize.XS}px) {
    margin-left: 30px;
  }
`;

interface Props {
  active: boolean;
  disabled?: boolean;
  title: string;
  description: string;
  children: ReactNode;
  onSelect: () => void;
}

export default function DeliveryOption(props: Props) {
  const { active, disabled = false, title, description, children, onSelect } = props;

  return (
    <Container>

      <ControlContainer onClick={disabled ? undefined : onSelect}>

        <RadialControlContainer>
          <RadialButton
            active={active}
            disabled={disabled}
          />
        </RadialControlContainer>

        <OptionTitle disabled={disabled}>
          <h6>{title}</h6>
        </OptionTitle>

      </ControlContainer>

      {active && (
        <OptionDescription>
          <p>{description}</p>
        </OptionDescription>
      )}

      {active && (
        <OptionContent>
          {children}
        </OptionContent>
      )}

    </Container>
  );
}