import * as React from 'react';
import styled, { css } from "styled-components";

import { ToastType } from './types';

import { ReactComponent as WarningIcon } from './icons/warning-icon.svg';
import { ReactComponent as SuccessIcon } from './icons/success-icon.svg';
import { ReactComponent as ErrorIcon } from './icons/error-icon.svg';
import { ReactComponent as CloseIcon } from './icons/close-icon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  min-height: 80px;
  padding: 15px 15px;
  border-radius: 4px;
  line-height: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  box-shadow: 0 10px 15px 0 rgba(0,0,0,0.04);
  border: 1px solid #f1f1f1;
`;

const IconContainer = styled.div`
  margin-right: 15px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const DetailsContainer = styled.div`
  flex: 1;
`;

interface TitleContainerProps {
  type: ToastType;
}

const TitleContainer = styled.div<TitleContainerProps>`
  margin-bottom: 10px;

  h6 {
    font-size: 1.6rem;
    line-height: 1.6rem;
    word-break: break-all;
    font-weight: 500;

    ${({ type }) => type === "WARNING" && css`
      color: #d9bc3f;
    `}

    ${({ type }) => type === "SUCCESS" && css`
      color: #2eb57e;
    `}

    ${({ type }) => type === "ERROR" && css`
      color: #e55f5f;
    `}
  }
`;

const MessageContainer = styled.div`
  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    white-space: pre-wrap;
  }
`;

const CloseControl = styled.div`
  padding: 10px;
  cursor: pointer;
`;



interface Props {
  title: string;
  message: string;
  type: ToastType;
}

export default function ToastWidget(props: Props) {
  const { title, message, type } = props;

  return (
    <Container>

      <IconContainer>
        {type === "WARNING" && (
          < WarningIcon />
        )}

        {type === "SUCCESS" && (
          < SuccessIcon />
        )}

        {type === "ERROR" && (
          < ErrorIcon />
        )}
      </IconContainer>


      <DetailsContainer>

        <TitleContainer type={type}>
          <h6>{title}</h6>
        </TitleContainer>

        <MessageContainer>
          <p>{message}</p>
        </MessageContainer>

      </DetailsContainer>

      <CloseControl>
        <CloseIcon />
      </CloseControl>

    </Container>
  );
}