import styled from "styled-components";

export const Text = styled.p`
  margin-left: 4px;
  font-size: 1.5rem;
  color: #919497;
`;

export const Checkmark = styled.span`
  position: relative;
  display: block;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: 1px solid rgba(18, 18, 18, 0.2);
  box-sizing: border-box;
  border-radius: 4px;

  transition: background 100ms linear, box-shadow 100ms linear;

  &::after {
    content: "";
    display: none;
    position: absolute;
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
    border-radius: 2px;
    /* border: solid #1aa7e8; */
    border: solid #003c58;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Container = styled.label`
  display: flex;
  align-items: center;

  & > input {
    display: none;
    &:checked ~ ${Checkmark} {
      background-color: #ffffff;
      /* border: 1.5px solid #1aa7e8; */
      border: 1.5px solid #003c58;
    }

    &:checked ~ ${Checkmark}::after {
      display: block;
    }
    &:disabled ~ ${Checkmark}, &:disabled ~ ${Text} {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;
