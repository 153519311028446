import React, { useState, useEffect, ReactNode, useRef } from 'react';
import styled from "styled-components";
import { ScreenSize } from '../../styling/constants';
import IconButton from '../../components/IconButton';

import { ReactComponent as UpArrowIcon } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/down-arrow.svg';

const Container = styled.div`
  flex-shrink: 0;
  overflow: auto;
  padding-bottom: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-right: 50px;
  }
`;

const ScrollButtonContainer = styled.div`
  display: none;
  flex-direction: revert;
  justify-content: center;

  @media (min-width: ${ScreenSize.XS}px) {
    display: flex;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: ${ScreenSize.XS}px) {
    max-height: 360px;
    margin: 10px 0px;
    flex-direction: column;
    overflow: hidden;
  }
`;

interface Props {
  children: ReactNode;
}

export default function ProductImagesList(props: Props) {
  const { children } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScrollUp = () => {
    if (!containerRef.current) return;

    containerRef.current.scrollBy({
      top: -100,
      left: 0,
      behavior: "smooth"
    });
  };

  const handleScrollDown = () => {
    if (!containerRef.current) return;

    containerRef.current.scrollBy({
      top: 100,
      left: 0,
      behavior: "smooth"
    });
  };

  return (
    <Container>

      <ScrollButtonContainer>
        <IconButton image={<UpArrowIcon />} onClickHandler={handleScrollUp} />
      </ScrollButtonContainer>

      <ItemsContainer ref={containerRef}>
        {children}
      </ItemsContainer>

      <ScrollButtonContainer>
        <IconButton image={<DownArrowIcon />} onClickHandler={handleScrollDown} />
      </ScrollButtonContainer>

    </Container>
  );
}