import { StoreProduct } from "../../../entities/StoreProduct";
import { StoreProductDetails } from "../../../entities/StoreProductDetails";
import { GetProductsDetailsRequest } from "../../../repositories/ProductRepository";


export const TRIGGER_FETCH_PRODUCTS_DETAILS = 'UI.SHOPPING_CART_VIEW.TRIGGER_FETCH_PRODUCTS_DETAILS';
export const FETCH_PRODUCTS_DETAILS_PENDING = 'UI.SHOPPING_CART_VIEW.FETCH_PRODUCTS_DETAILS_PENDING';
export const FETCH_PRODUCTS_DETAILS_SUCCESS = 'UI.SHOPPING_CART_VIEW.FETCH_PRODUCTS_DETAILS_SUCCESS';
export const FETCH_PRODUCTS_DETAILS_ERROR = 'UI.SHOPPING_CART_VIEW.FETCH_PRODUCTS_DETAILS_ERROR';

export const RESET_SHOPPING_CART_VIEW = 'UI.SHOPPING_CART_VIEW.RESET_SHOPPING_CART_VIEW';




export interface TriggerFetchProductsDetails {
  type: typeof TRIGGER_FETCH_PRODUCTS_DETAILS;
  payload: GetProductsDetailsRequest;
}
export interface FetchProductsDetailsPending {
  type: typeof FETCH_PRODUCTS_DETAILS_PENDING;
}
export interface FetchProductsDetailsSuccess {
  type: typeof FETCH_PRODUCTS_DETAILS_SUCCESS;
  payload: {
    productsDetails: StoreProductDetails[];
  };
}
export interface FetchProductsDetailsError {
  type: typeof FETCH_PRODUCTS_DETAILS_ERROR;
}




export interface ResetShoppingCartView {
  type: typeof RESET_SHOPPING_CART_VIEW;
}


export type ShoppingCartViewActionType =
  TriggerFetchProductsDetails
  | FetchProductsDetailsPending
  | FetchProductsDetailsSuccess
  | FetchProductsDetailsError

  | ResetShoppingCartView;



export interface ShoppingCartViewState {
  fetchingProductsDetails: boolean;
  fetchingProductsDetailsError: boolean;
  productsDetails: StoreProductDetails[] | null;
}