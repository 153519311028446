import { SignInRequest, SignInResponse } from '../../../repositories/AuthRepository';

export const TRIGGER_SIGN_IN = 'UI.SIGN_IN.TRIGGER_SIGN_IN';
export const SIGN_IN_PENDING = 'UI.SIGN_IN.SIGN_IN_PENDING';
export const SIGN_IN_SUCCESS = 'UI.SIGN_IN.SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'UI.SIGN_IN.SIGN_IN_ERROR';

export const RESET_SIGN_IN = 'UI.SIGN_IN.RESET_SIGN_IN';

export interface TriggerSignInUser {
  type: typeof TRIGGER_SIGN_IN;
  payload: SignInRequest;
}

export interface SignInPending {
  type: typeof SIGN_IN_PENDING;
}

export interface SignInSuccess {
  type: typeof SIGN_IN_SUCCESS;
  payload: {
    signInResponse: SignInResponse;
  }
}

export interface SignInError {
  type: typeof SIGN_IN_ERROR;
}


export type SignInActionType =
  TriggerSignInUser
  | SignInPending
  | SignInSuccess
  | SignInError;


export interface SignInState {
  loading: boolean;
  loadingError: boolean;
  response: SignInResponse | null;
}
