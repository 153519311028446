import {
  FETCH_ORDER_DETAILS_PENDING,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDER_DETAILS_ERROR,

  FETCH_PRODUCTS_PENDING,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,

  RESET_ORDER_STATUS_VIEW,

  OrderStatusViewActionType,
  OrderStatusViewState,
} from "./types";



const initialState: OrderStatusViewState = {
  fetchingOrderDetails: false,
  errorFetchingOrderDetails: false,
  orderDetails: null,

  products: [],
  fetchingProducts: false,
  fetchingProductsError: false
}

export function OrderStatusViewReducer(
  state: OrderStatusViewState = initialState,
  action: OrderStatusViewActionType
): OrderStatusViewState {
  switch (action.type) {

    case FETCH_ORDER_DETAILS_PENDING: {
      return {
        ...state,
        fetchingOrderDetails: true,
        errorFetchingOrderDetails: false,
      };
    }

    case FETCH_ORDER_DETAILS_SUCCESS: {
      const { payload } = action;
      const { orderDetails } = payload;

      return {
        ...state,
        fetchingOrderDetails: false,
        errorFetchingOrderDetails: false,
        orderDetails: orderDetails
      };
    }

    case FETCH_ORDER_DETAILS_ERROR: {
      return {
        ...state,
        fetchingOrderDetails: false,
        errorFetchingOrderDetails: true,
      };
    }


    case FETCH_PRODUCTS_PENDING: {
      return {
        ...state,
        fetchingProducts: true,
        fetchingProductsError: false,
      };
    }

    case FETCH_PRODUCTS_SUCCESS: {
      const { payload } = action;
      const { products } = payload;

      return {
        ...state,
        fetchingProducts: false,
        fetchingProductsError: false,
        products: products
      };
    }

    case FETCH_PRODUCTS_ERROR: {
      return {
        ...state,
        fetchingProducts: false,
        fetchingProductsError: true,
      };
    }



    case RESET_ORDER_STATUS_VIEW: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
