import {
  CartItem,
  TRIGGER_RESTORE_CART_ITEMS,
  TriggerRestoreCartItems,

  RESTORE_CART_ITEMS,
  RestoreCartItems,

  SET_CART_ITEMS,
  SetCartItems,

  TRIGGER_ADD_CART_ITEM,
  TriggerAddCartItemPayload,
  TriggerAddCartItem,

  ADD_CART_ITEM,
  AddCartItem,

  REMOVE_CART_ITEM,
  RemoveCartItem,

  SET_CART_ITEM_QUANTITY,
  SetCartItemQuantity,

  CLEAR_CART,
  ClearCart,

  TRIGGER_SHOW_ADD_TO_CART_NOTIFICATION,
  TriggerShowAddToCartNotification,

  SET_ADD_TO_CART_NOTIFICATION,
  SetAddToCartNotification,

  CLEAR_ADD_TO_CART_NOTIFICATION,
  ClearAddToCartNotification,
  AddCartItems,
  ADD_CART_ITEMS
} from "./types";



export const triggerRestoreCartItems = (storeUrlEndpoint: string): TriggerRestoreCartItems => {
  return {
    type: TRIGGER_RESTORE_CART_ITEMS,
    payload: {
      storeUrlEndpoint,
    }
  };
};

export const restoreCartItems = (payload: { cartItems: CartItem[] }): RestoreCartItems => {
  return {
    type: RESTORE_CART_ITEMS,
    payload
  };
};

export const setCartItems = (payload: { cartItems: CartItem[] }): SetCartItems => {
  return {
    type: SET_CART_ITEMS,
    payload
  };
};

export const triggerAddCartItem = (payload: TriggerAddCartItemPayload): TriggerAddCartItem => {
  return {
    type: TRIGGER_ADD_CART_ITEM,
    payload,
  };
};

export const addCartItem = (payload: { cartItem: CartItem }): AddCartItem => {
  return {
    type: ADD_CART_ITEM,
    payload: payload
  };
};

export const addCartItems = (payload: { cartItems: CartItem[] }): AddCartItems => {
  return {
    type: ADD_CART_ITEMS,
    payload: payload
  };
};

export const removeCartItem = (payload: { cartItemId: string }): RemoveCartItem => {
  return {
    type: REMOVE_CART_ITEM,
    payload: payload
  };
};

export const setCartItemQuantity = (
  payload: { cartItemId: string, quantity: number }
): SetCartItemQuantity => {
  return {
    type: SET_CART_ITEM_QUANTITY,
    payload: payload
  };
};

export const clearCart = (): ClearCart => {
  return {
    type: CLEAR_CART
  };
};


export const triggerShowAddToCartNotification = (payload: { cartItem: CartItem }): TriggerShowAddToCartNotification => {
  return {
    type: TRIGGER_SHOW_ADD_TO_CART_NOTIFICATION,
    payload: payload
  };
};

export const setAddToCartNotification = (payload: { cartItem: CartItem }): SetAddToCartNotification => {
  return {
    type: SET_ADD_TO_CART_NOTIFICATION,
    payload: payload
  };
};

export const clearAddToCartNotification = (): ClearAddToCartNotification => {
  return {
    type: CLEAR_ADD_TO_CART_NOTIFICATION,
  };
};