import { takeLatest, put } from "redux-saga/effects";
import { 
  TRIGGER_FETCH_CUSTOMER_ADDRESSES, 
  TriggerFetchCustomerAddresses,

  TRIGGER_ADD_CUSTOMER_ADDRESS,
  TriggerAddCustomerAddress,

  TRIGGER_DELETE_CUSTOMER_ADDRESS,
  TriggerDeleteCustomerAddress,
 } from "./types";

import {
  fetchCustomerAddressesPending,
  fetchCustomerAddressesSuccess,
  fetchCustomerAddressesError,

  addCustomerAddressPending,
  addCustomerAddressSuccess,
  addCustomerAddressError,

  deleteCustomerAddressPending,
  deleteCustomerAddressSuccess,
  deleteCustomerAddressError,

  addCustomerAddress,
  deleteCustomerAddress,
} from "./actions";
import { customerRepository } from "../../repositories";
import { AddCustomerAddressResponse, GetCustomerAddressesResponse } from "../../repositories/CustomerRepository";


function* handleTriggerFetchCustomerAddresses(action: TriggerFetchCustomerAddresses) {
  const { payload } = action;

  yield put(fetchCustomerAddressesPending());

  try {
    const response: GetCustomerAddressesResponse = yield customerRepository.getCustomerAddresses(
      payload
    );

    const { data } = response;
    yield put(fetchCustomerAddressesSuccess(data));
  } catch (err) {
    yield put(fetchCustomerAddressesError());
    return;
  }
}



function* handleTriggerAddCustomerAddress(action: TriggerAddCustomerAddress) {
  const { payload } = action;

  yield put(addCustomerAddressPending());

  try {
    const response: AddCustomerAddressResponse = yield customerRepository.addCustomerAddress(
      payload
    );
    yield put(addCustomerAddressSuccess());

    const { data } = response;

    yield put(addCustomerAddress(data));
  } catch (err) {
    yield put(addCustomerAddressError());
    return;
  }
}



function* handleTriggerDeleteCustomerAddress(action: TriggerDeleteCustomerAddress) {
  const { payload } = action;

  yield put(deleteCustomerAddressPending());

  try {
    const response: AddCustomerAddressResponse = yield customerRepository.deleteCustomerAddress(
      payload
    );
    yield put(deleteCustomerAddressSuccess());
    
    yield put(deleteCustomerAddress(payload.id));
  } catch (err) {
    yield put(deleteCustomerAddressError());
    return;
  }
}



export function* customerAddressesSaga() {
  yield takeLatest(TRIGGER_FETCH_CUSTOMER_ADDRESSES, handleTriggerFetchCustomerAddresses);
  yield takeLatest(TRIGGER_ADD_CUSTOMER_ADDRESS, handleTriggerAddCustomerAddress);
  yield takeLatest(TRIGGER_DELETE_CUSTOMER_ADDRESS, handleTriggerDeleteCustomerAddress);  
}
