import {
  GetStoreDetailsResponse
} from "../../repositories/StoreRepository";
import { StoreDetails } from "../../entities/StoreDetails";


export const TRIGGER_FETCH_STORE_DETAILS = 'STORE.TRIGGER_FETCH_STORE_DETAILS';
export const FETCH_STORE_DETAILS_PENDING = 'STORE.FETCH_STORE_DETAILS_PENDING';
export const FETCH_STORE_DETAILS_SUCCESS = 'STORE.FETCH_STORE_DETAILS_SUCCESS';
export const FETCH_STORE_DETAILS_ERROR = 'STORE.FETCH_STORE_DETAILS_ERROR';


export interface TriggerFetchStoreDetails {
  type: typeof TRIGGER_FETCH_STORE_DETAILS;
  payload: {
    storeUrlEndpoint: string;
  };
}

export interface FetchStoreDetailsPending {
  type: typeof FETCH_STORE_DETAILS_PENDING;
}

export interface FetchStoreDetailsSuccess {
  type: typeof FETCH_STORE_DETAILS_SUCCESS;
  payload: StoreDetails;
}

export interface FetchStoreDetailsError {
  type: typeof FETCH_STORE_DETAILS_ERROR;
}


export type StoreActionType =
  | TriggerFetchStoreDetails
  | FetchStoreDetailsPending
  | FetchStoreDetailsSuccess
  | FetchStoreDetailsError;

export interface StoreState {
  storeDetails: StoreDetails | null;
  fetchingStoreDetails: boolean;
  fetchingStoreDetailsError: boolean;
}