import React, { useState } from 'react';
import styled from "styled-components";
import { Button } from '../../../components/Button';
import { CustomerAddress } from '../../../repositories/CustomerRepository';
import { ColorTheme } from '../../../styling/constants';

const Container = styled.div``;

const CustomerAddressContainer = styled.div`
  position: relative;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 15px;
`;

const ControlsContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px;
  box-sizing: border-box;
`;

const NameContainer = styled.div`
  margin-bottom: 10px;

  p {
    color: ${ColorTheme.primaryColorDark};
  }
`;

const AddressContainer = styled.div`
  margin-bottom: 10px;

  p {
    white-space: pre;
  }
`;

const PhoneNumberContainer = styled.div``;

interface Props {
  loading: boolean;
  loadingError: boolean;
  customerAddress: CustomerAddress | null;
  reloadAddressesHandler: () => void;
  openAddressBookHandler: () => void;
}

export default function SelectedAddressContent(props: Props) {
  const { 
    loading, 
    loadingError, 
    customerAddress,
    reloadAddressesHandler,
    openAddressBookHandler,
  } = props;

  let addressText = '';
  if (customerAddress) {
    const { country, state, area, address } = customerAddress;

    addressText += `${address},`;

    if (area) addressText += `\n${area},`;
    if (state) addressText += `\n${state},`;

    addressText += `\n${country}`;
  }

  return (
    <Container>
      {loading && (
        <p>Loading Address Book...</p>
      )}

      {!loading && loadingError && (
        <p>Failed to load Address Book. <a href="#" onClick={reloadAddressesHandler}>Reload</a></p>
      )}

      {!loading && !loadingError && !customerAddress && (
        <p>You don't have any saved addresses yet. Check <a href="#" onClick={openAddressBookHandler}>address book</a></p>
      )}
      
      {!loading && customerAddress && (
        <CustomerAddressContainer>
          <ControlsContainer>
            <Button 
              text="Change"
              color="PRIMARY"
              type="OUTLINE"
              containerStyle={{height: '30px', padding: "0px 10px"}}
              textStyle={{fontSize: "1.2rem"}}
              onClick={openAddressBookHandler}
            />
          </ControlsContainer>

          <NameContainer>
            <p>{customerAddress.customerName}</p>
          </NameContainer>

          <AddressContainer>
            <p>{addressText}</p>
          </AddressContainer>

          <PhoneNumberContainer>
            <p>{customerAddress.customerPhoneNumber}</p>
          </PhoneNumberContainer>

        </CustomerAddressContainer>
      )}
    </Container>
  );
}