import { AddCustomerAddressRequest, CustomerAddress, DeleteCustomerAddressRequest, GetCustomerAddressesRequest } from '../../repositories/CustomerRepository';

import {
  TRIGGER_FETCH_CUSTOMER_ADDRESSES,
  TriggerFetchCustomerAddresses,

  FETCH_CUSTOMER_ADDRESSES_PENDING,
  FetchCustomerAddressesPending,

  FETCH_CUSTOMER_ADDRESSES_SUCCESS,
  FetchCustomerAddressesSuccess,

  FETCH_CUSTOMER_ADDRESSES_ERROR,
  FetchCustomerAddressesError,



  TRIGGER_ADD_CUSTOMER_ADDRESS,
  TriggerAddCustomerAddress,

  ADD_CUSTOMER_ADDRESS_PENDING,
  AddCustomerAddressPending,

  ADD_CUSTOMER_ADDRESS_SUCCESS,
  AddCustomerAddressSuccess,

  ADD_CUSTOMER_ADDRESS_ERROR,
  AddCustomerAddressError,



  TRIGGER_DELETE_CUSTOMER_ADDRESS,
  TriggerDeleteCustomerAddress,

  DELETE_CUSTOMER_ADDRESS_PENDING,
  DeleteCustomerAddressPending,

  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  DeleteCustomerAddressSuccess,

  DELETE_CUSTOMER_ADDRESS_ERROR,
  DeleteCustomerAddressError,


  ADD_CUSTOMER_ADDRESS,
  AddCustomerAddress,


  DELETE_CUSTOMER_ADDRESS,
  DeleteCustomerAddress,


  RESET_CUSTOMER_ADDRESSES,
  ResetCustomerAddresses,
} from './types';


export const triggerFetchCustomerAddresses = (payload: GetCustomerAddressesRequest): TriggerFetchCustomerAddresses => ({
  type: TRIGGER_FETCH_CUSTOMER_ADDRESSES,
  payload
});

export const fetchCustomerAddressesPending = (): FetchCustomerAddressesPending => ({
  type: FETCH_CUSTOMER_ADDRESSES_PENDING,
});

export const fetchCustomerAddressesSuccess = (customerAddresses: CustomerAddress[]): FetchCustomerAddressesSuccess => ({
  type: FETCH_CUSTOMER_ADDRESSES_SUCCESS,
  payload: { customerAddresses }
});

export const fetchCustomerAddressesError = (): FetchCustomerAddressesError => ({
  type: FETCH_CUSTOMER_ADDRESSES_ERROR,
});



export const triggerAddCustomerAddress = (payload: AddCustomerAddressRequest): TriggerAddCustomerAddress => ({
  type: TRIGGER_ADD_CUSTOMER_ADDRESS,
  payload
});

export const addCustomerAddressPending = (): AddCustomerAddressPending => ({
  type: ADD_CUSTOMER_ADDRESS_PENDING,
});

export const addCustomerAddressSuccess = (): AddCustomerAddressSuccess => ({
  type: ADD_CUSTOMER_ADDRESS_SUCCESS
});

export const addCustomerAddressError = (): AddCustomerAddressError => ({
  type: ADD_CUSTOMER_ADDRESS_ERROR,
});




export const triggerDeleteCustomerAddress = (payload: DeleteCustomerAddressRequest): TriggerDeleteCustomerAddress => ({
  type: TRIGGER_DELETE_CUSTOMER_ADDRESS,
  payload
});

export const deleteCustomerAddressPending = (): DeleteCustomerAddressPending => ({
  type: DELETE_CUSTOMER_ADDRESS_PENDING,
});

export const deleteCustomerAddressSuccess = (): DeleteCustomerAddressSuccess => ({
  type: DELETE_CUSTOMER_ADDRESS_SUCCESS
});

export const deleteCustomerAddressError = (): DeleteCustomerAddressError => ({
  type: DELETE_CUSTOMER_ADDRESS_ERROR,
});



export const addCustomerAddress = (customerAddress: CustomerAddress): AddCustomerAddress => ({
  type: ADD_CUSTOMER_ADDRESS,
  payload: { customerAddress }
});



export const deleteCustomerAddress = (customerAddressId: number): DeleteCustomerAddress => ({
  type: DELETE_CUSTOMER_ADDRESS,
  payload: { customerAddressId }
});



export const resetCustomerAddresses = (): ResetCustomerAddresses => ({
  type: RESET_CUSTOMER_ADDRESSES,
});
