import { SERVICE_BASE_URL } from "../config/properties";
import { HttpClient } from "../core/api/HttpClient";
import { OrderDetails } from "../entities/OrderDetails";



export interface OrderItemProps {
  productId: number;
  quantity: number;
  amount: number;
  variantName?: any;
  variantValue?: any;
}

export interface CreateCheckoutOrderRequest {
  amount: number;
  storeId: number;
  orderItems: OrderItemProps[];
}

export interface CreateCheckoutOrderResponse {
  data: OrderDetails;
}

export interface SupportedCountry {
  id: number;
  name: string;
  code: string;
}

interface GetSupportedCountriesResponse {
  data: SupportedCountry[];
}

export interface SupportedState {
  id: number;
  name: string;
  code: string;
  countryId: number;
  typeOfAdministrativeDistrict: number;
  parentId: number;
}

interface GetSupportedStatesResponse {
  data: SupportedState[];
}


export interface SupportedCity {
  id: number;
  name: string;
  administrativeDistrictCode: string;
  administrativeDistrictName: string;
  lgaName: string;
  lgaCode: string;
}

interface GetSupportedCitiesResponse {
  data: SupportedCity[];
}

export interface GetDeliveryMethodsRequest {
  orderId: string;
  state: string;
  country: string;
  city: string;
}

export interface PickupOption {
  pickupLocation: null;
  pickupFee: number | null;
  pickupAvailable: boolean;
}

interface LogisticsServiceExtras {
  requestSessionId: string;
  shipperId: string;
  shippingClassId: string;
}

export interface MerchantShippingOption {
  id: string;
  courierName: string;
  courierLogoUrl: string | null;
  price: number;
  deliveryPeriod: string | null;
  shippingAreaName: string;
  shippingProvider: string;
  logisticsServiceExtras: LogisticsServiceExtras | null;
}

export interface ShippingOption {
  id: string;
  courierName: string;
  courierLogoUrl: string | null;
  price: number;
  deliveryPeriod: string | null;
  shippingProvider: string;
  logisticsServiceExtras: LogisticsServiceExtras | null;
}

export interface DeliveryMethodsData {
  pickupOption: PickupOption;
  merchantShippingOptions: MerchantShippingOption[];
  thirdPartyShippingOptions: ShippingOption[];
  currencyCode: string;
}

export interface GetDeliveryMethodsResponse {
  data: DeliveryMethodsData;
}

export type DeliveryMethod = 'DOOR_DELIVERY' | 'PICKUP_FROM_STORE' | 'DIGITAL';

export interface InitiatePaymentRequest {
  orderId: string;
  storeId: number;
  customerName: string;
  customerEmail: string;
  customerPhoneNumber: string;
  country: string;
  orderShippingInfo: {
    deliveryMethod: DeliveryMethod;
    address: string;
    city: string;
    state: string;
    country: string;
    shippingOptionId?: string;
  }
}

export interface InitiatePaymentResponseData {
  amountToBePaid: number;
  country: string;
  customerEmail: string;
  customerName: string;
  customerPhoneNumber: string;
  orderId: string;
  orderShippingInfo: {
    additionalAddressInfo: null
    address: string;
    city: string;
    country: string;
    courier: string;
    courierLogoUrl: null
    customerFullAddress: string;
    dateDelivered: null
    dateShipped: null
    deliveryMethod: DeliveryMethod;
    deliveryPeriod: null
    fee: number;
    landmark: null
    lga: "Eti-Osa"
    logisticsServiceOrderId: null
    orderId: "CF59BF22-CAC1-4F67-9318-C37466A24ED3"
    postalCode: "101241"
    provider: "MERCHANT"
    shippingOptionId: "ca09bffa-942e-4f49-a473-f13b09612e79"
    state: "Lagos"
    status: "ORDER_PLACED"
    trackShipmentLink: null
  }
  storeId: number;
  paymentReference: string;
}

interface InitiatePaymentResponse {
  data: InitiatePaymentResponseData;
}

export type OrderStatus = 'NEW' | 'READY_FOR_PICKUP' | 'PICKED_UP' | 'FAILED_PICKUP' | 'FAILED_DELIVERY' | 'CANCELED';

export type ShippingOrderStatus = 'ORDER_PLACED' | 'PROCESSING_ORDER' | 'PACKAGED' | 'IN_TRANSIT' | 'DELIVERED';

type OrderStatusPaymentStatus = 'PENDING' | 'FAILED' | 'SUCCESSFUL';

type OrderStatusPaymentChannel = 'WEB' | 'API' | any;

export type OrderStatusOrderItem = {
  amount: number;
  imageFileName: string;
  imageFileUrl: string;
  name: string;
  productId: number;
  productType: "PHYSICAL"
  quantity: number;
  reference: string;
  stockKeepingUnit: "";
  variantName: string | null;
  variantValue: string | null;
  weight: number;
}

export interface OrderStatusDetails {
  amount: number;
  cardNumber: string;
  channel: OrderStatusPaymentChannel;
  country: null;
  createdOn: string | null;
  currencyCode: string;
  customerEmail: string;
  customerName: string;
  customerPhoneNumber: string;
  id: string;
  orderItems: [];
  orderShippingInfo: {
    additionalAddressInfo: null
    address: string;
    city: string;
    country: string;
    courier: "Merchant"
    courierLogoUrl: null;
    customerFullAddress: string;
    dateDelivered: string | null;
    dateShipped: string | null;
    deliveryMethod: DeliveryMethod;
    deliveryPeriod: null;
    fee: number;
    landmark: null;
    lga: null;
    logisticsServiceOrderId: null;
    orderId: string;
    postalCode: string;
    provider: "MERCHANT"
    state: string;
    status: ShippingOrderStatus;
    trackShipmentLink: null;
  };
  orderStatus: OrderStatus;
  paymentDate: null;
  paymentStatus: OrderStatusPaymentStatus;
  storeId: number;
  updatedOn: string | null;
}

export interface GetOrderDetailsResponse {
  data: OrderStatusDetails;
}

export interface IOrderRepository {
  createCheckoutOrder(request: CreateCheckoutOrderRequest): Promise<CreateCheckoutOrderResponse>;
  getSupportedCountries(): Promise<SupportedCountry[]>;
  getSupportedStates(countryCode: string): Promise<SupportedState[]>;
  getSupportedCities(stateCode: string): Promise<SupportedCity[]>;
  initiatePayment(request: InitiatePaymentRequest): Promise<InitiatePaymentResponseData>;
}

export class OrderRepository implements IOrderRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async createCheckoutOrder(request: CreateCheckoutOrderRequest): Promise<CreateCheckoutOrderResponse> {
    const response = await this.httpClient.post<CreateCheckoutOrderResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/orders/public/checkout`,
      request
    );

    return response.data;
  }

  async getSupportedCountries(): Promise<SupportedCountry[]> {
    const response = await this.httpClient.get<GetSupportedCountriesResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/orders/public/countries`
    );
    return response.data.data;
  }

  async getSupportedStates(countryCode: string): Promise<SupportedState[]> {
    const response = await this.httpClient.get<GetSupportedStatesResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/orders/public/countries/${countryCode}/states`
    );

    return response.data.data;
  }

  async getSupportedCities(stateCode: string): Promise<SupportedCity[]> {
    const response = await this.httpClient.get<GetSupportedCitiesResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/orders/public/states/${stateCode}/areas`
    );

    return response.data.data;
  }

  async getDeliveryMethods(request: GetDeliveryMethodsRequest): Promise<DeliveryMethodsData> {
    const response = await this.httpClient.post<GetDeliveryMethodsResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/orders/public/get-delivery-methods`,
      { ...request, area: request.city }
    );

    return response.data.data;
  }

  async initiatePayment(request: InitiatePaymentRequest): Promise<InitiatePaymentResponseData> {
    const { orderShippingInfo, ...otherParams } = request;

    const newOrderShippingInfo = {
      ...orderShippingInfo,
      area: orderShippingInfo.city
    }

    const response = await this.httpClient.post<InitiatePaymentResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/orders/public/payment`,
      {
        ...otherParams,
        orderShippingInfo: newOrderShippingInfo
      }
    );

    return response.data.data;
  }

  async getOrderDetails(orderId: string): Promise<OrderStatusDetails> {
    const response = await this.httpClient.get<GetOrderDetailsResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/orders/public/${orderId}`
    );

    return response.data.data;
  }

}