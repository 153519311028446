import * as React from 'react';
import styled from "styled-components";
import { ScreenSize } from '../../styling/constants';
import ToastWidget from './ToastWidget';
import { Toast, ToastType } from './types';

const TOAST_BOARD_WIDTH = 370;

const Container = styled.div`
  position: fixed;
  left: 0px;
  width: 100%;
  z-index: 900;

  @media (min-width: ${ScreenSize.XS}px) {
    left: calc(50% - ${TOAST_BOARD_WIDTH / 2}px);
    width: ${TOAST_BOARD_WIDTH}px;
    padding: 10px 0px;
  }
`;


interface Props {
  toasts: Toast[];
  closeHandler: (toastId: string) => void;
}


export function ToastBoard(props: Props) {
  const { toasts, closeHandler } = props;

  return (
    <Container>
      {toasts.map(toast => {
        const { id, title, message, type } = toast;

        return (
          <ToastWidget
            key={id}
            title={title}
            message={message}
            type={type}
          />
        );
      })}
    </Container>
  );
}
