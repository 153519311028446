import { takeLatest, put } from "redux-saga/effects";

import {
  TRIGGER_INITIATE_PAYMENT,
  RESET_CHECKOUT_VIEW,
  TriggerInitiatePayment,
  TRIGGER_FETCH_SUPPORTED_COUNTRIES,
  TriggerFetchSupportedCountries,
  TRIGGER_FETCH_SUPPORTED_STATES,
  TriggerFetchSupportedStates,
  TRIGGER_FETCH_SUPPORTED_CITIES,
  TriggerFetchSupportedCities,
  TRIGGER_FETCH_DELIVERY_METHODS,
  TriggerFetchDeliveryMethods
} from "./types";

import {

  fetchSupportedCountriesPending,
  fetchSupportedCountriesSuccess,
  fetchSupportedCountriesError,

  fetchSupportedStatesPending,
  fetchSupportedStatesSuccess,
  fetchSupportedStatesError,

  fetchSupportedCitiesSuccess,
  fetchSupportedCitiesPending,
  fetchSupportedCitiesError,

  fetchDeliveryMethodsSuccess,
  fetchDeliveryMethodsError,
  fetchDeliveryMethodsPending,

  triggerInitiatePayment,
  initiatePaymentPending,
  initiatePaymentSuccess,
  initiatePaymentError,

  resetCheckoutView,
} from "./actions";

import { orderRepository } from "../../../repositories";
import { GetProductsResponse } from "../../../repositories/ProductRepository";
import { createCancellableSaga } from "../../CancellableSaga";
import { SupportedCountry, SupportedState, SupportedCity, DeliveryMethodsData, InitiatePaymentResponseData } from "../../../repositories/OrderRepository";
import { fetchStoreDetailsSuccess } from "../../store/actions";



function* handleTriggerFetchSupportedCountries(action: TriggerFetchSupportedCountries) {
  yield put(fetchSupportedCountriesPending());

  try {
    const supportedCountries: SupportedCountry[] = yield orderRepository.getSupportedCountries();

    yield put(fetchSupportedCountriesSuccess({
      countries: supportedCountries
    }));
  } catch (err) {
    yield put(fetchSupportedCountriesError());
    return;
  }
}

function* handleTriggerFetchSupportedStates(action: TriggerFetchSupportedStates) {
  const { payload } = action;
  const { countryCode } = payload;

  yield put(fetchSupportedStatesPending());

  try {
    const supportedStates: SupportedState[] = yield orderRepository.getSupportedStates(countryCode);

    yield put(fetchSupportedStatesSuccess({
      states: supportedStates
    }));
  } catch (err) {
    yield put(fetchSupportedStatesError());
    return;
  }
}

function* handleTriggerFetchSupportedCities(action: TriggerFetchSupportedCities) {
  const { payload } = action;
  const { city } = payload;

  yield put(fetchSupportedCitiesPending());

  try {
    const supportedCities: SupportedCity[] = yield orderRepository.getSupportedCities(city);

    yield put(fetchSupportedCitiesSuccess({
      cities: supportedCities
    }));
  } catch (err) {
    yield put(fetchSupportedCitiesError());
    return;
  }
}

function* handleTriggerFetchDeliveryMethods(action: TriggerFetchDeliveryMethods) {
  const { payload } = action;
  const { request } = payload;

  yield put(fetchDeliveryMethodsPending());

  try {
    const deliveryMethodsData: DeliveryMethodsData = yield orderRepository.getDeliveryMethods(request);

    yield put(fetchDeliveryMethodsSuccess({ deliveryMethodsData }));
  } catch (err) {
    yield put(fetchDeliveryMethodsError());
    return;
  }
}




function* handleTriggerInitiatePayment(action: TriggerInitiatePayment) {
  const { payload } = action;
  const { request } = payload;

  yield put(initiatePaymentPending());

  try {
    const response: InitiatePaymentResponseData = yield orderRepository.initiatePayment(
      request
    );

    yield put(initiatePaymentSuccess(response));
  } catch (err) {
    yield put(initiatePaymentError());
    return;
  }
}


export function* watchCheckoutView() {
  yield takeLatest(TRIGGER_FETCH_SUPPORTED_COUNTRIES, handleTriggerFetchSupportedCountries);
  yield takeLatest(TRIGGER_FETCH_SUPPORTED_STATES, handleTriggerFetchSupportedStates);
  yield takeLatest(TRIGGER_FETCH_SUPPORTED_CITIES, handleTriggerFetchSupportedCities);

  yield takeLatest(TRIGGER_FETCH_DELIVERY_METHODS, handleTriggerFetchDeliveryMethods);

  yield takeLatest(TRIGGER_INITIATE_PAYMENT, handleTriggerInitiatePayment);
}


export const checkoutViewSaga = createCancellableSaga(watchCheckoutView, RESET_CHECKOUT_VIEW);