import { GetProductsRequest } from "../../../repositories/ProductRepository";
import { StoreProductDetails } from "../../../entities/StoreProductDetails";

export const TRIGGER_FETCH_PRODUCT_DETAILS = 'UI.STORE_PRODUCT_VIEW.TRIGGER_FETCH_PRODUCT_DETAILS';
export const FETCH_PRODUCT_DETAILS_PENDING = 'UI.STORE_PRODUCT_VIEW.FETCH_PRODUCT_DETAILS_PENDING';
export const FETCH_PRODUCT_DETAILS_SUCCESS = 'UI.STORE_PRODUCT_VIEW.FETCH_PRODUCT_DETAILS_SUCCESS';
export const FETCH_PRODUCT_DETAILS_ERROR = 'UI.STORE_PRODUCT_VIEW.FETCH_PRODUCT_DETAILS_ERROR';

export const RESET_STORE_PRODUCT_VIEW = 'UI.STORE_PRODUCT_VIEW.RESET';


export interface TriggerFetchProductDetails {
  type: typeof TRIGGER_FETCH_PRODUCT_DETAILS;
  payload: {
    productUrlEndpoint: string;
  };
}

export interface FetchProductDetailsPending {
  type: typeof FETCH_PRODUCT_DETAILS_PENDING;
}

export interface FetchProductDetailsSuccess {
  type: typeof FETCH_PRODUCT_DETAILS_SUCCESS;
  payload: {
    productDetails: StoreProductDetails
  };
}

export interface FetchProductDetailsError {
  type: typeof FETCH_PRODUCT_DETAILS_ERROR;
}

export interface ResetStoreProductView {
  type: typeof RESET_STORE_PRODUCT_VIEW;
}


export type StoreProductViewActionType =
  | TriggerFetchProductDetails
  | FetchProductDetailsPending
  | FetchProductDetailsSuccess
  | FetchProductDetailsError
  | ResetStoreProductView;

export interface StoreProductViewState {
  productDetails: StoreProductDetails | null;
  fetchingProductDetails: boolean;
  fetchingProductDetailsError: boolean;
}