import axios, { AxiosInstance } from 'axios';

import { tokenInterceptor } from './HTTPInterceptor';

export type HttpClient = AxiosInstance;


export function createHttpClient() {
  const instance = axios.create();

  instance.interceptors.request.use(tokenInterceptor);

  return instance;
}
