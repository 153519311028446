import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { CARD_BORDER_COLOR, CARD_CONTENT_PADDING } from './style';
import { Button } from '../../components/Button';

const Container = styled.div`
  opacity: 0;
  display: flex;
  flex-direction: column;
  padding: ${CARD_CONTENT_PADDING}px;
  border: 1px solid ${CARD_BORDER_COLOR};
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;

  h6 {
    font-size: 1.6rem;
  }
`;

const MessageContainer = styled.div`
  margin-bottom: 20px;
`;


export default function CancelOrderContent() {
  return (
    <Container>

      <TitleContainer>
        <h6>Got issues with this order?</h6>
      </TitleContainer>

      <MessageContainer>
        <p>Noticed any issues with order or you simply changed your mind about this purchase? Kindly use the button below to cancel this order before it ships.</p>
      </MessageContainer>

      <Button
        text="Cancel Order"
        color="DANGER"
        onClick={() => { }}
      />

    </Container>
  );
}