import { combineReducers } from 'redux';

import { ToastBoardReducer } from './ToastBoard/reducer';

import { StoreViewReducer } from './StoreView/reducer';
import { StoreProductViewReducer } from './StoreProductView/reducer';
import { ShoppingCartViewReducer } from './ShoppingCartView/reducer';
import { CheckoutViewReducer } from './CheckoutView/reducer';
import { OrderStatusViewReducer } from './OrderStatusView/reducer';

export const UiReducer = combineReducers({
  toastBoard: ToastBoardReducer,
  storeView: StoreViewReducer,
  storeProductView: StoreProductViewReducer,
  shoppingCartView: ShoppingCartViewReducer,
  checkoutView: CheckoutViewReducer,
  orderStatusView: OrderStatusViewReducer,
});

