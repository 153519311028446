import {
  AddCustomerAddressRequest,
  CustomerAddress,
  DeleteCustomerAddressRequest,
  GetCustomerAddressesRequest,
  GetCustomerAddressesResponse
} from '../../repositories/CustomerRepository';

export const TRIGGER_FETCH_CUSTOMER_ADDRESSES = 'UI.CUSTOMER_ADDRESSES.TRIGGER_FETCH_CUSTOMER_ADDRESSES';
export const FETCH_CUSTOMER_ADDRESSES_PENDING = 'UI.CUSTOMER_ADDRESSES.FETCH_CUSTOMER_ADDRESSES_PENDING';
export const FETCH_CUSTOMER_ADDRESSES_SUCCESS = 'UI.CUSTOMER_ADDRESSES.FETCH_CUSTOMER_ADDRESSES_SUCCESS';
export const FETCH_CUSTOMER_ADDRESSES_ERROR = 'UI.CUSTOMER_ADDRESSES.FETCH_CUSTOMER_ADDRESSES_ERROR';

export const TRIGGER_ADD_CUSTOMER_ADDRESS = 'UI.CUSTOMER_ADDRESSES.TRIGGER_ADD_CUSTOMER_ADDRESS';
export const ADD_CUSTOMER_ADDRESS_PENDING = 'UI.CUSTOMER_ADDRESSES.ADD_CUSTOMER_ADDRESS_PENDING';
export const ADD_CUSTOMER_ADDRESS_SUCCESS = 'UI.CUSTOMER_ADDRESSES.ADD_CUSTOMER_ADDRESS_SUCCESS';
export const ADD_CUSTOMER_ADDRESS_ERROR = 'UI.CUSTOMER_ADDRESSES.ADD_CUSTOMER_ADDRESS_ERROR';

export const TRIGGER_DELETE_CUSTOMER_ADDRESS = 'UI.CUSTOMER_ADDRESSES.TRIGGER_DELETE_CUSTOMER_ADDRESS';
export const DELETE_CUSTOMER_ADDRESS_PENDING = 'UI.CUSTOMER_ADDRESSES.DELETE_CUSTOMER_ADDRESS_PENDING';
export const DELETE_CUSTOMER_ADDRESS_SUCCESS = 'UI.CUSTOMER_ADDRESSES.DELETE_CUSTOMER_ADDRESS_SUCCESS';
export const DELETE_CUSTOMER_ADDRESS_ERROR = 'UI.CUSTOMER_ADDRESSES.DELETE_CUSTOMER_ADDRESS_ERROR';


export const ADD_CUSTOMER_ADDRESS = 'UI.CUSTOMER_ADDRESSES.ADD_CUSTOMER_ADDRESS';
export const DELETE_CUSTOMER_ADDRESS = 'UI.CUSTOMER_ADDRESSES.DELETE_CUSTOMER_ADDRESS';
// export const TRIGGER_UPDATE_CUSTOMER_ADDRESS = 'UI.CUSTOMER_ADDRESSES.TRIGGER_UPDATE_CUSTOMER_ADDRESS';
// export const SET_DEFAULT_CUSTOMER_ADDRESS = 'UI.CUSTOMER_ADDRESSES.SET_DEFAULT_CUSTOMER_ADDRESS';

// export const CANCEL_ADDRESS_REQUESTS = 'UI.ADDRESS.CANCEL_ADDRESS_REQUESTS';


export const RESET_CUSTOMER_ADDRESSES = 'UI.CUSTOMER_ADDRESSES.RESET_CUSTOMER_ADDRESSES';



export interface TriggerFetchCustomerAddresses {
  type: typeof TRIGGER_FETCH_CUSTOMER_ADDRESSES;
  payload: GetCustomerAddressesRequest;
}
export interface FetchCustomerAddressesPending {
  type: typeof FETCH_CUSTOMER_ADDRESSES_PENDING;
}
export interface FetchCustomerAddressesSuccess {
  type: typeof FETCH_CUSTOMER_ADDRESSES_SUCCESS;
  payload: {
    customerAddresses: CustomerAddress[];
  };
}
export interface FetchCustomerAddressesError {
  type: typeof FETCH_CUSTOMER_ADDRESSES_ERROR;
}


export interface TriggerAddCustomerAddress {
  type: typeof TRIGGER_ADD_CUSTOMER_ADDRESS;
  payload: AddCustomerAddressRequest;
}
export interface AddCustomerAddressPending {
  type: typeof ADD_CUSTOMER_ADDRESS_PENDING;
}
export interface AddCustomerAddressSuccess {
  type: typeof ADD_CUSTOMER_ADDRESS_SUCCESS;
}
export interface AddCustomerAddressError {
  type: typeof ADD_CUSTOMER_ADDRESS_ERROR;
}


export interface TriggerDeleteCustomerAddress {
  type: typeof TRIGGER_DELETE_CUSTOMER_ADDRESS;
  payload: DeleteCustomerAddressRequest;
}
export interface DeleteCustomerAddressPending {
  type: typeof DELETE_CUSTOMER_ADDRESS_PENDING;
}
export interface DeleteCustomerAddressSuccess {
  type: typeof DELETE_CUSTOMER_ADDRESS_SUCCESS;
}
export interface DeleteCustomerAddressError {
  type: typeof DELETE_CUSTOMER_ADDRESS_ERROR;
}


export interface AddCustomerAddress {
  type: typeof ADD_CUSTOMER_ADDRESS;
  payload: {
    customerAddress: CustomerAddress;
  }
}


export interface DeleteCustomerAddress {
  type: typeof DELETE_CUSTOMER_ADDRESS;
  payload: {
    customerAddressId: number;
  }
}


export interface ResetCustomerAddresses {
  type: typeof RESET_CUSTOMER_ADDRESSES;
}


export type CustomerAddressesActionType =
  TriggerFetchCustomerAddresses
  | FetchCustomerAddressesPending
  | FetchCustomerAddressesSuccess
  | FetchCustomerAddressesError

  | TriggerAddCustomerAddress
  | AddCustomerAddressPending
  | AddCustomerAddressSuccess
  | AddCustomerAddressError

  | TriggerDeleteCustomerAddress
  | DeleteCustomerAddressPending
  | DeleteCustomerAddressSuccess
  | DeleteCustomerAddressError

  | AddCustomerAddress

  | DeleteCustomerAddress

  | ResetCustomerAddresses;


export interface CustomerAddressState {
  customerAddresses: CustomerAddress[] | null;
  customerAddressesLoading: boolean;
  customerAddressesLoadingError: boolean;

  creatingCustomerAddress: boolean;
  creatingCustomerAddressError: boolean;

  deletingCustomerAddress: boolean;
  deletingCustomerAddressError: boolean;
}



