import React, { useState } from 'react';
import styled from "styled-components";
import { ColorTheme } from '../../styling/constants';
import { FORMS_LEFT_OFFSET, EditControl } from './style';
import { AppState } from 'store/RootReducer';
import { connect } from 'react-redux';
import { WhiteLabelAcquirerConfigResponse } from 'entities/StoreDetails';

const Container = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StepNumContainer = styled.div`
  width: ${FORMS_LEFT_OFFSET}px;
`;

const StepNum = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${ColorTheme.primaryColorDark};

  width: 30px;
  height: 30px;
  margin-right: 15px;
  border-radius: 100%;

  p {
    font-size: 1.6rem;
    color: white;
  }
`;

const TextContainer = styled.div`
  h6 {
    font-size: 1.8rem;
    color: ${ColorTheme.primaryColorDark};
  }
`;

const ControlsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const EditComponent = (props: { editControlHandler?: () => void }) => {
  const { editControlHandler } = props;

  return (
    <EditControl onClick={editControlHandler}>
      <p>[ Edit ]</p>
    </EditControl>
  );
};

interface Props {
  stepNo: number;
  title: string;
  complete: boolean;
  whiteLabelAcquirer?: WhiteLabelAcquirerConfigResponse;
  editControlHandler?: () => void;
}

function CheckoutStepHeader(props: Props) {
  const {
    stepNo, title, complete, whiteLabelAcquirer, editControlHandler
  } = props;

  return (
    <Container>

      <StepNumContainer>
        <StepNum style={whiteLabelAcquirer && { backgroundColor: whiteLabelAcquirer.secondaryColor }}>
          <p>{stepNo}</p>
        </StepNum>
      </StepNumContainer>

      <TextContainer>
        <h6 style={whiteLabelAcquirer && { color: whiteLabelAcquirer.secondaryColor }}>{title}</h6>
      </TextContainer>

      {complete && (
        <ControlsContainer>
          <EditComponent editControlHandler={editControlHandler} />
        </ControlsContainer>
      )}
    </Container>
  );
}

const mapStateToProps = (state: AppState) => ({
  whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
})

export default connect(mapStateToProps)(CheckoutStepHeader);
