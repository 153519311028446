import { SupportedCountry, SupportedState, GetDeliveryMethodsRequest, DeliveryMethodsData, InitiatePaymentRequest, InitiatePaymentResponseData, SupportedCity } from "../../../repositories/OrderRepository";


export const TRIGGER_FETCH_SUPPORTED_COUNTRIES = 'UI.CHECKOUT_VIEW.TRIGGER_FETCH_SUPPORTED_COUNTRIES';
export const FETCH_SUPPORTED_COUNTRIES_PENDING = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_COUNTRIES_PENDING';
export const FETCH_SUPPORTED_COUNTRIES_SUCCESS = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_COUNTRIES_SUCCESS';
export const FETCH_SUPPORTED_COUNTRIES_ERROR = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_COUNTRIES_ERROR';

export const TRIGGER_FETCH_SUPPORTED_STATES = 'UI.CHECKOUT_VIEW.TRIGGER_FETCH_SUPPORTED_STATES';
export const FETCH_SUPPORTED_STATES_PENDING = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_STATES_PENDING';
export const FETCH_SUPPORTED_STATES_SUCCESS = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_STATES_SUCCESS';
export const FETCH_SUPPORTED_STATES_ERROR = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_STATES_ERROR';

export const TRIGGER_FETCH_SUPPORTED_CITIES = 'UI.CHECKOUT_VIEW.TRIGGER_FETCH_SUPPORTED_CITIES';
export const FETCH_SUPPORTED_CITIES_PENDING = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_CITIES_PENDING';
export const FETCH_SUPPORTED_CITIES_SUCCESS = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_CITIES_SUCCESS';
export const FETCH_SUPPORTED_CITIES_ERROR = 'UI.CHECKOUT_VIEW.FETCH_SUPPORTED_CITIES_ERROR';

export const TRIGGER_FETCH_DELIVERY_METHODS = 'UI.CHECKOUT_VIEW.TRIGGER_FETCH_DELIVERY_METHODS';
export const FETCH_DELIVERY_METHODS_PENDING = 'UI.CHECKOUT_VIEW.FETCH_DELIVERY_METHODS_PENDING';
export const FETCH_DELIVERY_METHODS_SUCCESS = 'UI.CHECKOUT_VIEW.FETCH_DELIVERY_METHODS_SUCCESS';
export const FETCH_DELIVERY_METHODS_ERROR = 'UI.CHECKOUT_VIEW.FETCH_DELIVERY_METHODS_ERROR';

export const TRIGGER_INITIATE_PAYMENT = 'UI.CHECKOUT_VIEW.TRIGGER_INITIATE_PAYMENT';
export const INITIATE_PAYMENT_PENDING = 'UI.CHECKOUT_VIEW.INITIATE_PAYMENT_PENDING';
export const INITIATE_PAYMENT_SUCCESS = 'UI.CHECKOUT_VIEW.INITIATE_PAYMENT_SUCCESS';
export const INITIATE_PAYMENT_ERROR = 'UI.CHECKOUT_VIEW.INITIATE_PAYMENT_ERROR';

export const RESET_CHECKOUT_VIEW = 'UI.CHECKOUT_VIEW.RESET_CHECKOUT_VIEW';





export interface TriggerFetchSupportedCountries {
  type: typeof TRIGGER_FETCH_SUPPORTED_COUNTRIES;
}
export interface FetchSupportedCountriesPending {
  type: typeof FETCH_SUPPORTED_COUNTRIES_PENDING;
}
export interface FetchSupportedCountriesSuccess {
  type: typeof FETCH_SUPPORTED_COUNTRIES_SUCCESS;
  payload: {
    countries: SupportedCountry[];
  };
}
export interface FetchSupportedCountriesError {
  type: typeof FETCH_SUPPORTED_COUNTRIES_ERROR;
}




export interface TriggerFetchSupportedStates {
  type: typeof TRIGGER_FETCH_SUPPORTED_STATES;
  payload: {
    countryCode: string;
  }
}
export interface FetchSupportedStatesPending {
  type: typeof FETCH_SUPPORTED_STATES_PENDING;
}
export interface FetchSupportedStatesSuccess {
  type: typeof FETCH_SUPPORTED_STATES_SUCCESS;
  payload: {
    states: SupportedState[];
  };
}
export interface FetchSupportedStatesError {
  type: typeof FETCH_SUPPORTED_STATES_ERROR;
}




export interface TriggerFetchSupportedCities {
  type: typeof TRIGGER_FETCH_SUPPORTED_CITIES;
  payload: {
    city: string;
  }
}
export interface FetchSupportedCitiesPending {
  type: typeof FETCH_SUPPORTED_CITIES_PENDING;
}
export interface FetchSupportedCitiesSuccess {
  type: typeof FETCH_SUPPORTED_CITIES_SUCCESS;
  payload: {
    cities: SupportedCity[];
  };
}
export interface FetchSupportedCitiesError {
  type: typeof FETCH_SUPPORTED_CITIES_ERROR;
}





export interface TriggerFetchDeliveryMethods {
  type: typeof TRIGGER_FETCH_DELIVERY_METHODS;
  payload: {
    request: GetDeliveryMethodsRequest;
  };
}
export interface FetchDeliveryMethodsPending {
  type: typeof FETCH_DELIVERY_METHODS_PENDING;
}
export interface FetchDeliveryMethodsSuccess {
  type: typeof FETCH_DELIVERY_METHODS_SUCCESS;
  payload: {
    deliveryMethodsData: DeliveryMethodsData
  };
}
export interface FetchDeliveryMethodsError {
  type: typeof FETCH_DELIVERY_METHODS_ERROR;
}




export interface TriggerInitiatePayment {
  type: typeof TRIGGER_INITIATE_PAYMENT;
  payload: {
    request: InitiatePaymentRequest;
  };
}

export interface InitiatePaymentPending {
  type: typeof INITIATE_PAYMENT_PENDING;
}

export interface InitiatePaymentSuccess {
  type: typeof INITIATE_PAYMENT_SUCCESS;
  paylods: {
    data: InitiatePaymentResponseData;
  }
}

export interface InitiatePaymentError {
  type: typeof INITIATE_PAYMENT_ERROR;
}




export interface ResetCheckoutView {
  type: typeof RESET_CHECKOUT_VIEW;
}


export type CheckoutViewActionType =
  TriggerFetchSupportedCountries
  | FetchSupportedCountriesPending
  | FetchSupportedCountriesSuccess
  | FetchSupportedCountriesError

  | TriggerFetchSupportedStates
  | FetchSupportedStatesPending
  | FetchSupportedStatesSuccess
  | FetchSupportedStatesError

  | TriggerFetchSupportedCities
  | FetchSupportedCitiesPending
  | FetchSupportedCitiesSuccess
  | FetchSupportedCitiesError

  | TriggerFetchDeliveryMethods
  | FetchDeliveryMethodsPending
  | FetchDeliveryMethodsSuccess
  | FetchDeliveryMethodsError

  | TriggerInitiatePayment
  | InitiatePaymentPending
  | InitiatePaymentSuccess
  | InitiatePaymentError

  | ResetCheckoutView;



export interface CheckoutViewState {
  fetchingSupportedCountries: boolean;
  errorFetchingSupportedCountries: boolean;
  supportedCountries: SupportedCountry[];

  fetchingSupportedStates: boolean;
  errorFetchingSupportedStates: boolean;
  supportedStates: SupportedState[];

  fetchingSupportedCities: boolean;
  errorFetchingSupportedCities: boolean;
  supportedCities: SupportedCity[];

  fetchingDeliveryMethods: boolean;
  errorFetchingDeliveryMethods: boolean;
  deliveryMethodsData: DeliveryMethodsData | null;

  initiatingPayment: boolean;
  errorInitiatingPayment: boolean;
  initiatePaymentResponseData: InitiatePaymentResponseData | null;
}