import React, { ReactNode, CSSProperties } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import DropdownWrapper from "../../components/DropdownWrapper";
import DropdownContent from "./DropdownContent";
import { useViewport } from "../../providers/ViewportProvider";
import { ScreenSize, HEADER_HEIGHT } from "../../styling/constants";
import ControlContent from "./ControlContent";
import { AppState } from "../../store/RootReducer";
import { CartItem, CartActionType } from "../../store/cart/types";
import { StoreDetails } from "../../entities/StoreDetails";


const containerStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}


interface StoreStateProps {
  cartItems: CartItem[];
  storeDetails: StoreDetails;
}

interface StoreDispatchProps { }

interface OwnProps {
  showCart?: boolean;
}

type Props = RouteComponentProps & StoreStateProps & StoreDispatchProps & OwnProps;


function ShoppingCartWidget(props: Props) {
  const {
    match,
    history,
    cartItems,
    storeDetails
  } = props;

  const viewportSize = useViewport();
  const fullscreenCart = viewportSize.width <= ScreenSize.XS;


  const dropdownContainerStyle: CSSProperties = fullscreenCart ? {
    position: 'fixed',
    top: `${HEADER_HEIGHT}px`,
    width: '100%',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
  } : { width: '400px' };

  const navigateToCart = () => {
    const { urlEndpoint } = storeDetails;

    history.push({ pathname: `/${urlEndpoint}/cart` });
  }

  let itemCount = 0;

  cartItems.forEach(cartItem => {
    itemCount += cartItem.quantity;
  });

  return (
    <DropdownWrapper
      controlContent={
        <ControlContent
          itemCount={itemCount}
          onClickHandler={navigateToCart}
        />
      }
      dropdownContent={<DropdownContent />}
      showDropdown={false}
      containerStyle={containerStyle}
      dropdownContainerStyle={dropdownContainerStyle}
      rightAlign
    />
  );
}


const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails,
  cartItems: state.cart.cartItems
});

const mapDispatchToProps = (dispath: (action: CartActionType) => void): StoreDispatchProps => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCartWidget));