import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useViewport } from '../../providers/ViewportProvider';
import { ScreenSize, ColorTheme } from '../../styling/constants';

import IconButton from '../../components/IconButton';

import { ReactComponent as FullViewIcon } from '../../assets/icons/full-view.svg';
import { ReactComponent as SemiGridViewIcon } from '../../assets/icons/semi-grid-view.svg';
import { ReactComponent as GridViewIcon } from '../../assets/icons/grid-view.svg';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface ButtonContainerProps {
  active: boolean;
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  margin-right: 5px;

  &:last-child {
    margin-right: 0px;
  }

  svg {
    path {
      fill: ${ColorTheme.primaryColorDark};
    }
  }

  ${({ active }) => active && css`
    svg {
      path {
        fill: ${ColorTheme.primaryColor};
      }
    }
  `}
`;

interface Props {
  compressLayout: boolean;
  setCompressedLayout: (fullLayout: boolean) => void;
}

export default function ProductLayoutControl(props: Props) {
  const { compressLayout, setCompressedLayout } = props;

  const viewportSize = useViewport();
  const mobileView = viewportSize.width <= ScreenSize.XS;

  const iconColor = ColorTheme.primaryColorDark;
  const activeIconColor = ColorTheme.primaryColor;

  return (
    <Container>

      <ButtonContainer active={!compressLayout}>
        <IconButton
          image={mobileView ? <FullViewIcon /> : <SemiGridViewIcon />}
          iconColor={iconColor}
          activeIconColor={activeIconColor}
          size="M"
          onClickHandler={() => { setCompressedLayout(false) }} />
      </ButtonContainer>

      <ButtonContainer active={compressLayout}>
        <IconButton
          image={<GridViewIcon />}
          iconColor={iconColor}
          activeIconColor={activeIconColor}
          size="M"
          onClickHandler={() => { setCompressedLayout(true) }} />
      </ButtonContainer>

    </Container>
  );
}