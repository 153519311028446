import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as SuccessIcon } from '../assets/icons/success.svg';
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg';
import { ReactComponent as ErrorIcon } from '../assets/icons/error.svg';
import { Button } from './Button';
import { ScreenSize } from '../styling/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  /* background: white; */

  @media (min-width: ${ScreenSize.XS}px) {
    max-width: 530px;
    min-height: 350px;
    /* border: 1px solid #dcdcdcaa; */
  }
`;

const IconContainer = styled.div`
  margin-bottom: 30px;
`;

const TitleContaier = styled.div`
  margin-bottom: 20px;

  h4 {
    font-size: 1.8rem;
    color: #313131;
  }
`;

const MessageContainer = styled.div`
  p {
    text-align: center;
    color: #313131;
    font-size: 1.4rem;
  }
`;

const Icon = styled.div`
  svg {
    width: 45px;
    height: 45px;
  }
`;

const ControlContainer = styled.div`
  margin-top: 40px;
`;

type WidgetType = "SUCCESS" | "WARNING" | "ERROR";

interface Props {
  title: string;
  message: string;
  type?: WidgetType;
  icon?: ReactNode;
  action?: () => void;
  actionText?: string;
}

export default function NotificationWidget(props: Props) {
  const { title, message, type, icon, action, actionText } = props;

  let iconComponent;

  if (!type) {
    iconComponent = icon;
  }

  if (type === "SUCCESS") {
    iconComponent = (
      <Icon>
        <SuccessIcon fill="#43c771" />
      </Icon>
    );
  }

  if (type === "WARNING") {
    iconComponent = (
      <Icon>
        <WarningIcon fill="#e6d627" />
      </Icon>
    );
  }

  if (type === "ERROR") {
    iconComponent = (
      <Icon>
        <ErrorIcon fill="#e55f5f" />
      </Icon>
    );
  }

  return (
    <Container>
      <IconContainer>{iconComponent}</IconContainer>

      <TitleContaier>
        <h4>{title}</h4>
      </TitleContaier>

      <MessageContainer>
        <p>{message}</p>
      </MessageContainer>

      {action && (
        <ControlContainer>
          <Button
            color="PRIMARY"
            text={actionText || "Continue"}
            onClick={action}
          />
        </ControlContainer>
      )}
    </Container>
  );
}