import { takeLatest, put, takeEvery, delay, race, call, take } from "redux-saga/effects";
import { v4 as uuidv4 } from 'uuid';

import {
  TRIGGER_RESTORE_CART_ITEMS,
  TRIGGER_ADD_CART_ITEM,
  TRIGGER_SHOW_ADD_TO_CART_NOTIFICATION,
  TriggerRestoreCartItems,
  TriggerAddCartItem,
  CartItem,
  TriggerShowAddToCartNotification,
  CLEAR_ADD_TO_CART_NOTIFICATION
} from "./types";

import {
  restoreCartItems,
  addCartItem,
  setAddToCartNotification,
  clearAddToCartNotification,
  triggerShowAddToCartNotification
} from "./actions";

import { SecureStorage } from "../../util/storageUtil";
import { getCartStorageKey } from "./subscribers";

const CART_NOTIFICATION_DURATION_MS = 10000;

function* handleTriggerRestoreCartItems(action: TriggerRestoreCartItems) {
  const { payload } = action;
  const { storeUrlEndpoint } = payload;

  const persistedCartItems = SecureStorage.getValue<CartItem[]>(
    getCartStorageKey(storeUrlEndpoint)
  );

  yield put(restoreCartItems({
    cartItems: persistedCartItems || []
  }));
}


function* handleTriggerAddCartItem(action: TriggerAddCartItem) {
  const { payload } = action;

  const cartItemId = `qtb-${uuidv4()}`;

  const cartItem: CartItem = {
    id: cartItemId,
    ...payload
  }

  yield put(addCartItem({ cartItem: cartItem }));
  yield put(triggerShowAddToCartNotification({ cartItem: cartItem }));
}



function* handleTriggerShowAddToCartNotification(action: TriggerShowAddToCartNotification) {
  const { payload } = action;
  const { cartItem } = payload;

  yield put(setAddToCartNotification({ cartItem: cartItem }));

  yield delay(CART_NOTIFICATION_DURATION_MS);

  yield put(clearAddToCartNotification());
}

function* watchAddToCartNotification() {
  yield takeLatest(TRIGGER_SHOW_ADD_TO_CART_NOTIFICATION, handleTriggerShowAddToCartNotification);
}

function* cancellableAddToCartNotification() {
  while(true) {
    yield race({
      task: call(watchAddToCartNotification),
      cancel: take(CLEAR_ADD_TO_CART_NOTIFICATION)
    });
  }
}


export function* cartSaga() {
  yield takeLatest(TRIGGER_RESTORE_CART_ITEMS, handleTriggerRestoreCartItems);
  yield takeLatest(TRIGGER_ADD_CART_ITEM, handleTriggerAddCartItem);

  yield cancellableAddToCartNotification()
}
