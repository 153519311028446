import { WhiteLabelAcquirerConfigResponse } from "entities/StoreDetails";
import styled from "styled-components";
import { RouteView } from '../../components/Layout';
import { ScreenSize, BACK_NAVIGATOR_BOTTOM_MARGIN, ColorTheme } from '../../styling/constants';

type WhiteLabelColorConfig = {
  whiteLabelColorConfig?: Pick<WhiteLabelAcquirerConfigResponse, 'primaryColor' | 'secondaryColor'>;
}

export const Container = styled(RouteView)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    
  }
`;

export const BackControlContainer = styled.div`
  margin-bottom: ${BACK_NAVIGATOR_BOTTOM_MARGIN}px;
`;

export const CheckoutSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    overflow: auto;
  }
`;

export const ProductImagesContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenSize.XS}px) {
    margin-right: 90px;
    flex-direction: row-reverse;
  }
`;

export const ProductActiveImage = styled.div`
  border-radius: 4px;
  height: 250px;
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: ${ScreenSize.XS}px) {
    width: 450px;
    height: 450px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;


export const ProductImageListItemContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  flex-shrink: 0;
  cursor: pointer;

  @media (min-width: ${ScreenSize.XS}px) {
    width: 60px;
    height: 60px;
    margin-right: 0px;
    margin-bottom: 15px;
  }
`;

export const ProductDataContainer = styled.div`
  flex: 1;
  max-width: 400px;
`;

export const ProductTitleContainer = styled.div`
  margin-bottom: 10px;

  h4 {
    font-size: 2.0rem;
    font-weight: 100;
    color: ${ColorTheme.primaryColorLight};
    line-height: 3rem;
  }
`;

export const ProductPriceContainer = styled.div`
  margin-bottom: 15px; 
  
  h4 {
    font-weight: bold;
    color: #4f4f4f;
    font-size: 1.8rem;
  }
`;

export const StatusBadgesContainer = styled.div`
  margin-bottom: 15px; 
`;

export const ProductDescriptionContainer = styled.div`
  p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 10px;
  }
`;

export const CategorySection = styled.div`
  margin-bottom: 10px;
`;

export const CategorySectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #979797;
  opacity: 0.3;
  margin: 28px 0px;
`;

export const CategoryTitleContainer = styled.div`
  margin-bottom: 10px;

  h6 {
    font-size: 1.4rem;
    color: #004663;
    font-weight: 100;
  }
`;

export const VariantErrorContainer = styled.div`
  margin-bottom: 20px;

  p{
    color: red;
  }
`;

export const QuantityCheckoutContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const QuantityContainer = styled.div`
  margin-right: 15px;
  width: 130px;
`;

export const CheckoutButtonContainer = styled.div`
  flex: 1;
`;

export const ProductInfoSectionsContainer = styled.div`

`;

export const SectionHeadersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  border-bottom: 2px solid #eee;
`;

export const SectionHeader = styled.div<WhiteLabelColorConfig>`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 3px solid ${props => props.whiteLabelColorConfig ? props.whiteLabelColorConfig.primaryColor : '#1aa7e8'};
  margin-right: 30px;

  h4 {
    color: ${ColorTheme.primaryColorDark};
    font-size: 1.8rem;
    font-weight: 100;
  }
`;