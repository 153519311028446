import styled from "styled-components";
import { RouteView } from '../../components/Layout';
import { PAGE_SIDE_PADDING, PAGE_TOP_PADDING, ScreenSize, BACK_NAVIGATOR_BOTTOM_MARGIN, ColorTheme } from '../../styling/constants';

export const RECOMMENDED_ITEMS_COUNT = 5;

export const CARD_CONTENT_PADDING = 25;
export const CARDS_SPACING = 25;
export const CARD_BORDER_COLOR = 'rgba(0, 0, 0, 0.13)';

export const Container = styled(RouteView)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  

  @media (min-width: ${ScreenSize.XS}px) {
    
  }
`;