import React, { ReactNode, CSSProperties, useState } from "react";

import DropdownContent from "./DropdownContent";
import DropdownWrapper from "../../../components/DropdownWrapper";
import { useViewport } from "../../../providers/ViewportProvider";
import { ScreenSize } from "../../../styling/constants";
import ControlContent from "./ControlContent";
import { GetProductsSortType } from "../../../repositories/ProductRepository";


interface Props {
  value: any;
  options: { label: string, value: any }[];
  handleChangeSort: (value: any) => void;
}

export default function SortControl(props: Props) {
  const { value, options, handleChangeSort } = props;

  const [showDropdown, setShowDropdown] = useState(false);
  const handleControlToggle = () => {
    setShowDropdown(!showDropdown);
  }

  const viewportSize = useViewport();
  const fullscreenDropdown = viewportSize.width <= ScreenSize.XS;

  const dropdownContainerStyle: CSSProperties = fullscreenDropdown ? {
    width: '260px',
  } : { width: '260px', maxWidth: '260px' };

  const selectedValue = options.find((option) => option.value === value);

  const handleOnSelect = (value: any) => {
    handleChangeSort(value);
    setShowDropdown(false);
  }

  return (
    <DropdownWrapper
      outerClickHandler={() => setShowDropdown(false)}
      controlContent={
        <ControlContent
          text={selectedValue ? selectedValue.label : ''}
          onClickHandler={handleControlToggle}
        />
      }
      dropdownContent={
        <DropdownContent
          value={value}
          options={options}
          onSelect={handleOnSelect}
        />
      }
      showDropdown={showDropdown}
      dropdownContainerStyle={dropdownContainerStyle}
    />
  );
}

