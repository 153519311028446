import React, { useState, useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 0px;
  img {
    max-width: 100%;
  }
`;

interface Props {
  content: string;
}

export default function DescriptionSection(props: Props) {
  const { content } = props;

  const hashtagConfig = {
    trigger: '#',
    seperator: ' ',
  };

  let innerHTML = content;

  try {
    let rawContentState = JSON.parse(content);

    const markup = draftToHtml(
      rawContentState,
      hashtagConfig,
    );

    innerHTML = markup;
  } catch (err) {

  }

  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
    </Container>
  );
}

