import { WhiteLabelAcquirerConfigResponse } from 'entities/StoreDetails';
import React, { CSSProperties, ReactNode, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState } from 'store/RootReducer';
import styled, { css } from 'styled-components';
import { FormControlsTheme } from '../styling/constants';
import LoadingIndicator from './LoadingIndicator';

type ButtonType = 'FLAT' | 'OUTLINE';
type ButtonColorScheme = 'PRIMARY' | 'SECONDARY' | 'DANGER' | 'DARK' | 'GREY' | 'TRANSPARENT' | 'TRANSPARENT-DANGER';

interface ContainerProps {
  type: ButtonType;
  color: ButtonColorScheme;
  style: CSSProperties;
  loading: boolean;
  disabled: boolean;
}

const ButtonText = styled.p`
  font-size: 1.4rem;
  color: #1aa7e8;
  white-space: nowrap;
`;


const ContainerFlatStyles = (color: ButtonColorScheme) => {
  if (color === 'PRIMARY') {
    return css`
      background-color: #1aa7e8;
      border-color: transparent;

      & ${ButtonText} {
        color: white;
      }
    `;
  }

  if (color === 'SECONDARY') {
    return css`
      background-color: #fafafa;
      border-color: #f1f1f1;

      & ${ButtonText} {
        color: black;
      }
    `;
  }

  if (color === 'DANGER') {
    return css`
      background-color: #e55f5f;
      border-color: transparent;

      & ${ButtonText} {
        color: white;
      }
    `;
  }

  if (color === 'GREY') {
    return css`
      background-color: #f2f2f2;
      border-color: #dbdbdb;

      & ${ButtonText} {
        color: #000000;
      }
    `;
  }

  if (color === 'DARK') {
    return css`
      background-color: #6a6a6a;
      border-color: transparent;

      & ${ButtonText} {
        color: white;
      }
    `;
  }
};

const ContainerOutlineStyles = (color: ButtonColorScheme) => {
  if (color === 'PRIMARY') {
    return css`
      background-color: white;
      border-color: #1aa7e8;

      svg {
        width: 12px;
        height: 12px;
        fill: #1aa7e8;
      }
      
      & ${ButtonText} {
        color: #1aa7e8;
      }
    `;
  }

  if (color === 'SECONDARY') {
    return css`
      background-color: white;
      border-color: #ddd;

      & ${ButtonText} {
        color: black;
      }
    `;
  }

  if (color === 'TRANSPARENT') {
    return css`
      background-color: white;
      border-color: transparent;

      & ${ButtonText} {
        color: #6a6a6a;
      }
    `;
  }

  if (color === 'TRANSPARENT-DANGER') {
    return css`
      background-color: white;
      border-color: transparent;

      & ${ButtonText} {
        color: #e55f5f;
      }
    `;
  }
};

const Container = styled.div<ContainerProps>`
  display: inline-block;
  padding: 0px 15px;
  height: ${FormControlsTheme.controlHeight.default}px;
  cursor: pointer;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;

  ${({ loading, disabled }) => (loading || disabled) && css`
    opacity: 0.5;
    cursor: not-allowed;
  `}

  ${({ type, color }) => {
    switch (type) {
      case 'FLAT': {
        return ContainerFlatStyles(color);
      }
      case 'OUTLINE': {
        return ContainerOutlineStyles(color);
      }
    }
  }};
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  font-size: 0px;
`;

const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface Props {
  text: string;
  type?: ButtonType;
  color: ButtonColorScheme;
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  icon?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  whiteLabelAcquirer?: WhiteLabelAcquirerConfigResponse;
  onClick?: () => void;
}

const Button = (props: Props) => {
  const { whiteLabelAcquirer } = useSelector((state: AppState) => ({
    whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
  }));

  const { text, type, color, containerStyle, textStyle, icon, loading = false, disabled = false, onClick } = props;
  const onClickHandler = () => {
    if (!loading && !disabled && onClick) onClick();
  };

  return (
    <Container
      type={type || 'FLAT'}
      onClick={onClickHandler}
      color={color}
      style={
        whiteLabelAcquirer ? 
        { ...containerStyle, backgroundColor: whiteLabelAcquirer.primaryColor, borderColor: whiteLabelAcquirer.primaryColor } :
        { ...containerStyle }
      }
      loading={loading}
      disabled={disabled}
    >
      {!loading && (
        <ButtonContainer>
          {icon && (
            <IconContainer>{icon}</IconContainer>
          )}
          <ButtonText style={ whiteLabelAcquirer?.acquiredBy === 'ZIB' ? { color: '#fff' } : textStyle}>{text}</ButtonText>
        </ButtonContainer>
      )}

      {loading && (
        <LoaderContainer>
          <LoadingIndicator />
        </LoaderContainer>
      )}

    </Container>
  );
}

export { Button }



