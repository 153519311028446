import AsyncImage from "components/AsyncImage";
import * as React from "react";
import styled from "styled-components";

import DownArrowIcon from "assets/icons/down-arrow.svg";
import { Link } from "react-router-dom";
import { ScreenSize } from "styling/constants";
import { BundleProduct } from "../hooks/useGetBundleProducts";
import { getFormattedPrice } from "util/currencyUtil";
import { ProductVariantProperties } from "entities/StoreProductDetails";

interface Props {
  bundleProducts: { [key: string]: BundleProduct };
  updateBundleProductPrice: (
    id: string,
    variant: ProductVariantProperties
  ) => void;
}

export default function BundleProducts(props: Props) {
  const { bundleProducts, updateBundleProductPrice } = props;

  const onProductVariantChanged = (id: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const product = bundleProducts[id];
    const variant = product.productVariants?.find(v => v.variantValue === e.target.value);
    if(variant) {
      updateBundleProductPrice(id, variant);
    }
  };

  return (
    <ProductsContainer>
      {Object.values(bundleProducts).map((product, i) => (
        <ProductContainer key={product.id}>
          <Link to="" className="img-box">
            <AsyncImage
              src={product.productData.productImageUrl}
              alt={product.productData.name}
              imageStyle={{ objectFit: "contain" }}
            />
          </Link>
          <Link to="" className="name">
            {product.productData.name}
          </Link>
          {product.productVariants && product.productVariants.length !== 0 && (
            <Select
              value={product.variantValue}
              onChange={onProductVariantChanged(product.id)}
            >
              {product.productVariants
                .filter((v) => v.quantity !== 0)
                .map((variant) => (
                  <option value={variant.variantValue} key={variant.variantValue}>
                    {variant.variantValue}
                  </option>
                ))}
            </Select>
          )}
          <p className="price">
            {getFormattedPrice(product.productPrice, product.currencyCode)}
          </p>
        </ProductContainer>
      ))}
    </ProductsContainer>
  );
}

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @media screen and (max-width: ${ScreenSize.XS}px) {
    /* gap: 19px; */
    justify-content: center;
  }
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #cdcdcd;
  background-color: #fff;
  font-family: inherit;
  color: #666b72;

  padding: 0px 15px;

  background-image: url(${DownArrowIcon});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 95% center;
`;

const ProductContainer = styled.div`
  width: 267px;
  max-width: 100%;

  .img-box {
    display: block;
    width: 100%;
    height: 335px;
    margin-bottom: 18px;
  }
  a.name {
    display: block;
    text-decoration: none;
    margin-bottom: 26px;
    color: #007e9b;
  }
  p.price {
    color: #6a6a6a;
    font-size: 1.7rem;
    margin-top: 26px;
    font-weight: bold;
  }

  /* @media screen and (max-width: ${ScreenSize.XS}px) {
    width: 174px;
    .img-box {
      height: 218px;
    }
  } */
`;
