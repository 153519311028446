import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

import { SECURE_STORAGE_SECRET, SECURE_STORAGE_KEY_PREFIX } from "../config/properties";


export class SecureStorage {
  static getStorageKey(key: string) {
    return `${SECURE_STORAGE_KEY_PREFIX}_${key}`;
  }

  static getValue<T>(key: string): T | null {
    const encryptedString = localStorage.getItem(this.getStorageKey(key));

    if (encryptedString == null) return null;

    let valueObject;

    try {
      const bytes = AES.decrypt(encryptedString, SECURE_STORAGE_SECRET);

      const decryptedString = bytes.toString(Utf8);

      if (decryptedString.toString() == null) return null;

      valueObject = JSON.parse(decryptedString.toString());
    } catch (err) {
      localStorage.removeItem(this.getStorageKey(key));
      return null;
    }

    return valueObject;
  }

  static setValue(key: string, value: any): void {
    const valueString = JSON.stringify(value);

    const encryptedString = AES.encrypt(valueString, SECURE_STORAGE_SECRET);

    localStorage.setItem(this.getStorageKey(key), encryptedString.toString());
  }
}

