import styled from "styled-components";

import { ScreenSize } from "styling/constants";

export const FilterContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
`;

export const MobileFilterContainer = styled.div`
  &,
  * {
    box-sizing: border-box;
  }

  width: 100%;
  display: none;
  margin-bottom: 20px;

  .dropdown-container {
    width: 100%;
  }

  @media (max-width: ${ScreenSize.XS}px) {
    display: block;
  }
`;

export const FilterHeaderButton = styled.button`
  width: 100%;
  border: none;
  outline: none;
  background-color: white;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  color: #003c58;

  height: 40px;

  svg {
    width: 12.8px;
    height: 12.8px;
    transform: rotate(180deg);
    transition: transform 300ms ease-in-out;
  }

  &.close {
    svg {
      transform: none;
    }
  }
  &.mobile {
    border: solid 1px #e9e9e9;
    background-color: #fdfdfd;
    padding: 0px 12px;
    svg {
      transform: none;
    }
  }
`;

export const Categories = styled.div`
  .category-item {
    width: 100%;
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
    height: 44px;
    display: flex;
    align-items: center;
  }
  .checkbox {
    p {
      margin-left: 14px;
    }
  }

  max-height: 100vh;
  transition: max-height 300ms ease-in-out;

  &.close {
    max-height: 0px;
    overflow: hidden;
  }
`;

export const MobileFilterDropdown = styled.div`
  min-height: 346px;
  width: 100%;
  background-color: white;

  display: flex;
  flex-direction: column;

  ${Categories} {
    flex-grow: 1;
    padding: 17px;
    .checkbox {
      span::after {
        left: 3px;
        top: 0px;
      }
    }
  }

  .footer {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7fafc;
    padding: 0px 17px;
  }
`;
