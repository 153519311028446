import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import EditContent from './EditContent';
import CompletedContent from './CompletedContent';
import { DeliveryAddressFormState, DeliveryAddressFormSubmitParams } from '..';

import { AppState } from '../../../store/RootReducer';

const Container = styled.div``;


interface OwnProps {
  completed: boolean;
  initialUseSavedAddress: boolean | null;
  initialSelectedCustomerAddressId: number | null;
  formState: DeliveryAddressFormState;
  submitInProgress: boolean;
  submitHandler: (props: DeliveryAddressFormSubmitParams) => void;
}

interface StoreStateProps {

}

interface StoreDispatchProps {

}

type Props = OwnProps & StoreStateProps & StoreDispatchProps;

function DeliveryAddressForm(props: Props) {
  const {
    completed,
    initialUseSavedAddress,
    initialSelectedCustomerAddressId,
    formState,
    submitInProgress,

    submitHandler
  } = props;


  return (
    <Container>
      {!completed && (
        <EditContent
          initialUseSavedAddress={initialUseSavedAddress}
          initialSelectedCustomerAddressId={initialSelectedCustomerAddressId}
          formState={formState}
          submitInProgress={submitInProgress}
          submitHandler={submitHandler}
        />
      )}

      {completed && (
        <CompletedContent
          formState={formState}
        />
      )}

    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({

});

const mapDispatchToProps = (dispatch: (action: any) => void): StoreDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAddressForm);
