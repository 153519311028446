import { SERVICE_BASE_URL } from "../config/properties";
import { HttpClient } from "../core/api/HttpClient";
import { StoreDetails, WhiteLabelAcquirerConfigResponse } from "../entities/StoreDetails";

export interface GetStoreDetailsResponse {
  data: StoreDetails;
}

export interface SearchedStore {
  name: string;
  urlEndpoint: string;
}
export interface SearchStoreResponse {
  data: SearchedStore[];
}

export interface IStoreRepository {
  getStoreDetails(storeUrlEndpoint: string): Promise<GetStoreDetailsResponse>;
}

export class StoreRepository implements IStoreRepository {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getStoreDetails(storeUrlEndpoint: string): Promise<GetStoreDetailsResponse> {
    const response = await this.httpClient.get<GetStoreDetailsResponse>(`${SERVICE_BASE_URL}/storefront/api/v1/stores/public/url/${storeUrlEndpoint}`);

    const merchantResponse = await this.httpClient.get(`${SERVICE_BASE_URL}/storefront/api/v1/merchants/public/merchant/${response.data.data.merchantCode}`);
    //TODO: remove
    if(merchantResponse.data.data.acquiredBy){
      response.data.data.acquiredBy = merchantResponse.data.data.acquiredBy;

    }
    
    if(response.data.data.acquiredBy){
      const whiteLabelResponse = await this.httpClient.get<WhiteLabelAcquirerConfigResponse>(`${ENV_QTB_BASE_URL}/v2/whitelabel-acquirer-config?acquiredBy=${response.data.data.acquiredBy}`);
      response.data.data.whiteLabelAcquirer = whiteLabelResponse.data;
    }
    return response.data;
  }

  async searchStoreByName(search: string) {
    const response = await this.httpClient.get<SearchStoreResponse>(
      `${SERVICE_BASE_URL}/storefront/api/v1/stores/public/name`,
      { params: { query: search } }
    );

    return response.data;
  }
}
