import {
  ADD_TOAST,
  REMOVE_TOAST,
  ToastBoardActionType,
  ToastBoardState
} from "./types";

const initialState: ToastBoardState = {
  toasts: []
}

export function ToastBoardReducer(
  state: ToastBoardState = initialState,
  action: ToastBoardActionType
): ToastBoardState {
  switch (action.type) {
    case ADD_TOAST: {
      const { payload: { toast } } = action;
      const { toasts } = state;

      const updatedToasts = [toast, ...toasts];

      return {
        ...state,
        toasts: updatedToasts
      };
    }

    case REMOVE_TOAST: {
      const { payload: { toastId } } = action;
      const { toasts } = state;

      const updatedToasts = toasts.filter((toast => {
        return toast.id != toastId
      }));

      return {
        ...state,
        toasts: updatedToasts
      };
    }

    default: {
      return state;
    }
  }
}
