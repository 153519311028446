import { createHttpClient } from "../core/api/HttpClient";
import { StoreRepository } from "./StoreRepository";
import { ProductRepository } from "./ProductRepository";
import { OrderRepository } from "./OrderRepository";
import { AuthRepository } from "./AuthRepository";
import { CustomerRepository } from "./CustomerRepository";


const httpClient = createHttpClient();
export const storeRepository = new StoreRepository(httpClient);
export const productRepository = new ProductRepository(httpClient);
export const orderRepository = new OrderRepository(httpClient);
export const authRepository = new AuthRepository(httpClient);
export const customerRepository = new CustomerRepository(httpClient);
