import React, { CSSProperties, ChangeEvent, RefObject, DetailedHTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { ColorTheme, FormControlsTheme } from '../../styling/constants';

interface ContainerProps {
  error: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 120px;
  height: ${FormControlsTheme.controlHeight.default}px;
  display: flex;
  flex-direction: row;

  input {
    height: 100%;
    width: 100%;
    border: 1px solid ${ColorTheme.formControls.borderColor};
    box-sizing: border-box;
    border-radius: ${FormControlsTheme.borderRadius}px;
    padding: 0px ${FormControlsTheme.padding.side}px;
    outline-color: ${ColorTheme.primaryColor};
    font-family: inherit;
  }

  ${({ error }) =>
    error &&
    css`
      input {
        border-color: red;
      }
    `}
`;

interface OwnProps {
  error?: boolean;
  errorText?: string;
  controlRef?: RefObject<HTMLInputElement>;
  containerStyle?: CSSProperties;
}

type Props = OwnProps & DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export default function TextControl(props: Props) {
  const { error = false, errorText, controlRef, containerStyle, ...inputProps } = props;

  return (
    <Container style={{ ...containerStyle }} error={error}>
      <input ref={controlRef} {...inputProps} />
    </Container>
  );
}