import React from 'react';
import styled from "styled-components";

import { ColorTheme, ScreenSize } from '../../../styling/constants';
import { DeliveryAddressFormState } from '..';


const Container = styled.div``;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  padding-bottom: 20px;
  margin-bottom: 25px;
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  @media (min-width: ${ScreenSize.XS}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CompletedContainer = styled(FlexContainer)`
`;


const NameContainer = styled.div`
  margin-bottom: 5px;

  p {
    color: ${ColorTheme.primaryColorDark};
  }
`;

const AddressContainer = styled.div`
  margin-bottom: 15px;

  p {
    white-space: pre;
  }
`;


const PhoneNumberContainer = styled.div``;


interface Props {
  formState: DeliveryAddressFormState;
}

export default function CompletedContent(props: Props) {
  const {
    formState } = props;

  const { id, fullName, address, city, state, country, phoneNumber } = formState;

  let addressText = '';
  addressText += `${address},`;

  if (city) addressText += `\n${city},`;
  if (state) addressText += `\n${state},`;

  addressText += `\n${country}`;

  return (
    <>
      <CompletedContainer>
        <Container>

          <NameContainer>
            <p>{fullName}</p>
          </NameContainer>

          <AddressContainer>
            <p>{addressText}</p>
          </AddressContainer>

          <PhoneNumberContainer>
            <p>{phoneNumber}</p>
          </PhoneNumberContainer>

        </Container>

      </CompletedContainer>
    </>
  );
}
