import React from 'react';
import styled, { css } from "styled-components";

type SupportedColor = 'RED' | 'YELLOW';

interface ContainerProps {
  color: SupportedColor;
}

const Container = styled.div<ContainerProps>`
  padding: 3px 10px;
  display: inline-block;

  p {
    font-size: 1.2rem;
    font-weight: bold;
  }

  ${({ color }) => color === 'RED' && css`
    background-color: red;
    p {
      color: white;
    }
  `}

  ${({ color }) => color === 'YELLOW' && css`
    background-color: red;
    p {
      color: white;
    }
  `}
`;

interface Props extends ContainerProps {
  text: string;
}

export default function StatusBadge(props: Props) {
  const { color, text } = props;

  return (
    <Container color={color}>
      <p>{text}</p>
    </Container>
  );
}
