import { v4 as uuidv4 } from 'uuid';
import { put, delay, takeEvery } from 'redux-saga/effects';
import { Toast } from '../../../components/ToastBoard/types';
import { addToast, removeToast } from './actions';

import {
  TRIGGER_DISPLAY_TOAST,
  TriggerDisplayToast,
} from './types';

const defaultToastDurationMs = 5000;

const defaultSuccessTitle = 'Request Successful';
const defaultSuccessMessage = 'Your last request was completed successfully';

const defaultErrorTitle = 'Request Failed';
const defaultErrorMessage = 'There was a problem completing your last request. Please try again or contact support';

const defaultWarningTitle = 'Notice';
const defaultWarningMessage = 'There was a problem completing your last request. Please try again or contact support';

function* handleTriggerDisplayToast(action: TriggerDisplayToast) {
  const { payload } = action;
  const { title, message, toastType, durationMs } = payload;

  const toastId = `toast-${uuidv4()}`;

  let toastTitle;
  let toastMessage;

  switch (toastType) {
    case 'SUCCESS':
      toastTitle = defaultSuccessTitle;
      toastMessage = defaultSuccessMessage;
      break;
    case 'ERROR':
      toastTitle = defaultErrorTitle;
      toastMessage = defaultErrorMessage;
      break;
    case 'WARNING':
      toastTitle = defaultWarningTitle;
      toastMessage = defaultWarningMessage;
      break;
    default:
      toastTitle = '';
      toastMessage = '';
  }

  const toast: Toast = {
    id: toastId,
    title: title || toastTitle,
    message: message || toastMessage,
    type: toastType
  };


  yield put(addToast(toast));

  yield delay(durationMs || defaultToastDurationMs);

  yield put(removeToast(toast.id));
}

export function* toastBoardSaga() {
  yield takeEvery(TRIGGER_DISPLAY_TOAST, handleTriggerDisplayToast);
}
