import { OrderDetails } from "../../entities/OrderDetails";
import { CreateCheckoutOrderRequest } from "../../repositories/OrderRepository";



export const TRIGGER_CREATE_CHECKOUT_ORDER = 'ORDER.TRIGGER_CREATE_CHECKOUT_ORDER';
export const CREATE_CHECKOUT_ORDER_PENDING = 'ORDER.CREATE_CHECKOUT_ORDER_PENDING';
export const CREATE_CHECKOUT_ORDER_SUCCESS = 'ORDER.CREATE_CHECKOUT_ORDER_SUCCESS';
export const CREATE_CHECKOUT_ORDER_ERROR = 'ORDER.CREATE_CHECKOUT_ORDER_ERROR';



export interface TriggerCreateCheckoutOrder {
  type: typeof TRIGGER_CREATE_CHECKOUT_ORDER;
  payload: {
    request: CreateCheckoutOrderRequest;
  };
}
export interface CreateCheckoutOrderPending {
  type: typeof CREATE_CHECKOUT_ORDER_PENDING;
}
export interface CreateCheckoutOrderSuccess {
  type: typeof CREATE_CHECKOUT_ORDER_SUCCESS;
  payload: {
    orderDetails: OrderDetails
  };
}
export interface CreateCheckoutOrderError {
  type: typeof CREATE_CHECKOUT_ORDER_ERROR;
}




export type OrderActionType =
  TriggerCreateCheckoutOrder
  | CreateCheckoutOrderPending
  | CreateCheckoutOrderSuccess
  | CreateCheckoutOrderError;


export interface OrderState {
  orderDetails: OrderDetails | null;
  creatingOrder: boolean;
  creatingOrderError: boolean;
}