import React, { useState, useEffect, useRef } from "react";
import { StoreProductEntry } from "./StoreProductEntry";
import {
  parse as parsePath,
  stringify as stringifyQueryParams,
} from "query-string";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import { Guard } from "../../core/logic/Guard";
import { AppState } from "../../store/RootReducer";
import { useViewport } from "../../providers/ViewportProvider";
import { ScreenSize } from "../../styling/constants";
import usePrevious from "../../hooks/usePrevious";
import { StoreViewActionType } from "../../store/ui/StoreView/types";
import {
  triggerFetchProducts,
  resetStoreView,
} from "../../store/ui/StoreView/actions";
import {
  GetProductsRequest,
  GetProductsSortType,
} from "../../repositories/ProductRepository";
import {
  CartActionType,
  TriggerAddCartItemPayload,
} from "../../store/cart/types";
import {
  triggerAddCartItem,
  clearAddToCartNotification,
} from "../../store/cart/actions";
import { StoreDetails } from "../../entities/StoreDetails";
import { StoreProduct } from "../../entities/StoreProduct";

import ProductLayoutControl from "./ProductLayoutControl";
import { SearchControl } from "./SearchControl";
import IconButton from "../../components/IconButton";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import Paginator from "../../components/Paginator";
import SortControl from "./SortControl";
import EmptyStoreView from "./EmptyStoreView";
import ErrorFetchingProductsView from "./ErrorFetchingProductsView";

import {
  Container,
  SearchTextContainer,
  StoreItemContainer,
  PaginatorContainer,
  StoreItemsContainer,
  ControlsContainer,
  ItemCountContainer,
  ProductLayoutControlContainer,
  SearchControlContainer,
  SortControlContainer,
  SearchWidgetTriggerContainer,
  EmptyStoreViewContainer,
  StoreViewBody,
  StoreViewCategoriesContainer,
} from "./style";
import { getPageTitlteSuffix } from "../util";
import Categoriesfilter from "./CategoriesFilter";
import MobileCategoryFilter from "./CategoriesFilter/MobileCategoryFilter";

const PRODUCTS_PER_PAGE = 24;

const sortOptions: { label: string; value: GetProductsSortType }[] = [
  { label: "Featured", value: "RECOMMENDED" },
  { label: "Newest Arrivals", value: "NEWEST" },
  { label: "Price: Low to High", value: "LOWEST_PRICE" },
  { label: "Price: High to Low", value: "HIGHEST_PRICE" },
];

interface UrlSearchParams {
  page: number;
  name?: string;
  type?: string;
  status?: string;
  sort?: GetProductsSortType;
  productGroupIds?: number[];
}

interface StoreStateProps {
  storeDetails: StoreDetails;
  products: StoreProduct[];
  totalProductCount: number;
  fetchingProducts: boolean;
  fetchingProductsError: boolean;
}

interface StoreDispatchProps {
  fetchProducts: (
    storeUrlEndpoint: string,
    request: GetProductsRequest
  ) => void;
  triggerAddCartItem: (payload: TriggerAddCartItemPayload) => void;
  clearAddToCartNotification: () => void;
  resetStoreView: () => void;
}

interface OwnProps {}

type Props = RouteComponentProps<{ storeUrlEndpoint: string }> &
  StoreStateProps &
  StoreDispatchProps &
  OwnProps;

function StoreView(props: Props) {
  const {
    match,
    location,
    history,

    storeDetails,
    products,
    totalProductCount,
    fetchingProducts,
    fetchingProductsError,

    fetchProducts,
    triggerAddCartItem,
    clearAddToCartNotification,
    resetStoreView,
  } = props;


  useEffect(() => {
    // Scroll to top on render
    window.scrollTo(0, 0);

    // Change page title
    const { name } = storeDetails;
    document.title = `${getPageTitlteSuffix(name)}`;

    return () => {
      clearAddToCartNotification();
      resetStoreView();
    };
  }, []);

  const { storeUrlEndpoint } = match.params;

  const prevFetchingProducts = usePrevious(fetchingProducts);

  const viewportSize = useViewport();
  const isMobileView = viewportSize.width <= ScreenSize.XS;

  const [compressProductLayout, setCompressProductLayout] = useState(isMobileView ? false : true);

  const handleChangeCompressProductLayout = (compressLayout: boolean) => {
    setCompressProductLayout(compressLayout);
  };

  const { search: queryParams } = location;

  const resolvedSearchParams = useRef(false);

  const [searchParams, setSearchParams] = useState<UrlSearchParams>({
    page: 1,
    name: "",
    productGroupIds: [],
  });

  const resolveSearchParams = () => {
    const parsedParams = parsePath(location.search);

    const { page, name, type, status, sort, productGroupIds } = parsedParams;

    const sortValid = Guard.isOneOf(
      sort,
      sortOptions.map((item) => item.value),
      "sort"
    );

    const urlSearchParams: UrlSearchParams = {
      page: typeof page === "string" ? Number(page) : 1,
      name: typeof name === "string" ? name : undefined,
      sort: sortValid.succeeded ? (sort as GetProductsSortType) : undefined,
      productGroupIds:
        typeof productGroupIds === "string"
          ? productGroupIds.split(",").map((v) => Number(v))
          : undefined,
    };

    setSearchParams(urlSearchParams);

    // set state filter params
    setSortValue(urlSearchParams.sort || "RECOMMENDED");
    setNameValue(urlSearchParams.name || "");
  };

  const fetchStoreProductItems = () => {
    const { urlEndpoint } = storeDetails;

    if (!resolvedSearchParams.current) {
      resolvedSearchParams.current = true;
      return;
    }

    fetchProducts(urlEndpoint, {
      pageNumber: searchParams.page,
      pageSize: PRODUCTS_PER_PAGE,
      sortBy: searchParams.sort,
      productName: searchParams.name,
      productGroupIds: searchParams.productGroupIds
        ? searchParams.productGroupIds.join(",")
        : undefined,
    });
  };

  // Resolve query search parameters from browser url and assign them to state
  useEffect(() => {
    resolveSearchParams();
  }, [queryParams]);

  // Fetch new product items on searchParams change
  useEffect(() => {
    fetchStoreProductItems();
  }, [searchParams]);

  // scroll to top when products are loading
  useEffect(() => {
    if (!prevFetchingProducts && fetchingProducts) {
      window.scrollTo(0, 0);
    }
  }, [fetchingProducts]);

  // Updates browser search params with new values
  const changeSearchParams = (searchParams: UrlSearchParams) => {
    const searchString = stringifyQueryParams(searchParams, {
      arrayFormat: "comma",
    });

    history.push(`${location.pathname}?${searchString}`);
  };

  // PRDOUCTS FILTER MODIFIERS

  // Product sort type filter
  const [sortValue, setSortValue] = useState<GetProductsSortType>("RECOMMENDED");

  const handleSortParamChange = (value: string) => {
    setSortValue(value as GetProductsSortType);

    const newSearchParams = { ...searchParams };
    newSearchParams.sort = value as GetProductsSortType;

    changeSearchParams(newSearchParams);
  };

  // Page number
  const handlePageParamChange = (pageNumber: number) => {
    const newSearchParams = { ...searchParams };
    newSearchParams.page = pageNumber;

    changeSearchParams(newSearchParams);
  };

  // Product name filter
  const nameInputRef = useRef<HTMLInputElement>(null);

  const [nameValue, setNameValue] = useState<string>("");
  const changeNameValue = (value: string) => {
    setNameValue(value);
  };

  const [showSearchWidget, setShowSearchWidget] = useState(false);

  const searchWidgetTriggerHandler = () => {
    setShowSearchWidget(true);
    if (nameInputRef.current !== null) nameInputRef.current.focus();
  };

  const handleNameParamChange = (isClearAction?: boolean) => {
    setShowSearchWidget(false);

    if (nameInputRef.current !== null) nameInputRef.current.blur();

    const newSearchParams = { ...searchParams };
    newSearchParams.name = isClearAction ? "" : nameValue;

    // reset page number
    newSearchParams.page = 1;

    changeSearchParams(newSearchParams);
  };

  const onCategoryFilterChange = (value: number[]) => {
    const newSearchParams = { ...searchParams, productGroupIds: value };
    changeSearchParams(newSearchParams);
  };

  const viewProductDetails = (productUrlEndpoint: string) => {
    history.push({
      pathname: `/${storeUrlEndpoint}/product/${productUrlEndpoint}`,
    });
  };

  const addProductToCart = (productId: number) => {
    const product = products.find((item) => {
      return item.id === productId;
    });

    if (!product) return;

    const {
      id,
      name,
      price,
      currencyCode,
      imageFileUrl,
      urlEndpoint,
    } = product;

    triggerAddCartItem({
      quantity: 1,
      currencyCode,
      productPrice: price,
      productData: {
        id,
        productUrlEndpoint: urlEndpoint,
        name,
        productImageUrl: imageFileUrl,
      },
    });
  };

  const hideCategoryFilter =
    storeDetails.productGroups.length === 0 ||
    (storeDetails.productGroups.length === 1 &&
      storeDetails.productGroups[0].name.toLocaleLowerCase() === "default");

  return (
    <Container>
      {!hideCategoryFilter && (
        <StoreViewCategoriesContainer>
          <Categoriesfilter
            categories={storeDetails.productGroups}
            onChange={onCategoryFilterChange}
            value={searchParams.productGroupIds || []}
          />
        </StoreViewCategoriesContainer>
      )}
      <StoreViewBody>
        {searchParams.name && (
          <SearchTextContainer>
            <p>Showing results for “{searchParams.name}”</p>
          </SearchTextContainer>
        )}

        {!hideCategoryFilter && (
          <MobileCategoryFilter
            categories={storeDetails.productGroups}
            onChange={onCategoryFilterChange}
            value={searchParams.productGroupIds || []}
          />
        )}
        <ControlsContainer>
          <SortControlContainer>
            <SortControl
              value={sortValue}
              options={sortOptions}
              handleChangeSort={handleSortParamChange}
            />
          </SortControlContainer>

          {totalProductCount > 0 && (
            <ItemCountContainer>
              <p>{totalProductCount} Items</p>
            </ItemCountContainer>
          )}

          <ProductLayoutControlContainer>
            <ProductLayoutControl
              compressLayout={compressProductLayout}
              setCompressedLayout={handleChangeCompressProductLayout}
            />
          </ProductLayoutControlContainer>

          <SearchControlContainer showWidget={showSearchWidget && isMobileView}>
            <SearchControl
              inputRef={nameInputRef}
              searchValue={nameValue}
              onChangeHandler={changeNameValue}
              onSubmitHandler={handleNameParamChange}
              dismissHandler={() => setShowSearchWidget(false)}
            />
          </SearchControlContainer>

          <SearchWidgetTriggerContainer>
            <IconButton
              size="S"
              image={<SearchIcon />}
              onClickHandler={searchWidgetTriggerHandler}
            />
          </SearchWidgetTriggerContainer>
        </ControlsContainer>

        {fetchingProducts && (
          <StoreItemsContainer>
            {Array.from({ length: PRODUCTS_PER_PAGE }).map((product, i) => {
              return (
                <StoreItemContainer
                  key={i}
                  compressedView={compressProductLayout}
                >
                  <StoreProductEntry compressedView={compressProductLayout} />
                </StoreItemContainer>
              );
            })}
          </StoreItemsContainer>
        )}

        {!fetchingProducts && fetchingProductsError && (
          <ErrorFetchingProductsView actionHandler={fetchStoreProductItems} />
        )}

        {!fetchingProducts && !fetchingProductsError && (
          <StoreItemsContainer>
            {products.map((product) => {
              const { id, urlEndpoint } = product;

              return (
                <StoreItemContainer compressedView={compressProductLayout}>
                  <StoreProductEntry
                    data={product}
                    compressedView={compressProductLayout}
                    viewDetailsHandler={() => viewProductDetails(urlEndpoint)}
                    addToCartHandler={() => addProductToCart(id)}
                    productUrlEndpoint={`/${storeUrlEndpoint}/product/${urlEndpoint}`}
                  />
                </StoreItemContainer>
              );
            })}
          </StoreItemsContainer>
        )}

        {!fetchingProducts && !fetchingProductsError && products.length === 0 && (
          <EmptyStoreViewContainer>
            <EmptyStoreView />
          </EmptyStoreViewContainer>
        )}

        {products.length > 0 && totalProductCount > PRODUCTS_PER_PAGE && (
          <PaginatorContainer>
            <Paginator
              pageCount={Math.ceil(totalProductCount / PRODUCTS_PER_PAGE)}
              currentPage={searchParams.page}
              onPageButtonClick={handlePageParamChange}
            />
          </PaginatorContainer>
        )}
      </StoreViewBody>
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  storeDetails: state.store.storeDetails as StoreDetails,
  products: state.ui.storeView.products,
  totalProductCount: state.ui.storeView.totalProductCount,
  fetchingProducts: state.ui.storeView.fetchingProducts,
  fetchingProductsError: state.ui.storeView.fetchingProductsError,
});

const mapDispatchToProps = (dispath: (action: StoreViewActionType | CartActionType) => void): StoreDispatchProps => ({
  fetchProducts(storeUrlEndpoint: string, request: GetProductsRequest) {
    dispath(triggerFetchProducts(storeUrlEndpoint, request));
  },
  triggerAddCartItem(payload: TriggerAddCartItemPayload) {
    dispath(triggerAddCartItem(payload));
  },
  clearAddToCartNotification() {
    dispath(clearAddToCartNotification());
  },
  resetStoreView() {
    dispath(resetStoreView());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoreView));
