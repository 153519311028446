import { Toast, ToastType } from "../../../components/ToastBoard/types";

export const TRIGGER_DISPLAY_TOAST = 'TOAST_BOARD.TRIGGER_DISPLAY_TOAST';
export const ADD_TOAST = 'TOAST_BOARD.ADD_TOAST';
export const REMOVE_TOAST = 'TOAST_BOARD.REMOVE_TOAST';


export interface TriggerDisplayToastPayload {
  title?: string;
  message?: string;
  toastType: ToastType;
  durationMs?: number;
}

export interface TriggerDisplayToast {
  type: typeof TRIGGER_DISPLAY_TOAST;
  payload: TriggerDisplayToastPayload;
}

export interface AddToast {
  type: typeof ADD_TOAST;
  payload: {
    toast: Toast;
  }
}

export interface RemoveToast {
  type: typeof REMOVE_TOAST;
  payload: {
    toastId: string;
  };
}


export type ToastBoardActionType =
  TriggerDisplayToast
  | AddToast
  | RemoveToast;


export interface ToastBoardState {
  toasts: Toast[];
}