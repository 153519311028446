import React from 'react';
import styled from "styled-components";
import { CARD_BORDER_COLOR, CARD_CONTENT_PADDING } from './style';

import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as DeliveryIcon } from '../../assets/icons/delivery.svg';
import { ScreenSize } from '../../styling/constants';
import { DeliveryMethod } from '../../repositories/OrderRepository';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${CARD_BORDER_COLOR};

  @media (min-width: ${ScreenSize.XS}px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: ${CARD_CONTENT_PADDING}px;
  box-sizing: box-sizing;
  border-bottom: 1px solid ${CARD_BORDER_COLOR};

  &:last-child {
    border-right: none;
    border-bottom: none;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    border-bottom: none;
    border-right: 1px solid ${CARD_BORDER_COLOR};
  }
`;

const IconContainer = styled.div`
  margin-bottom: 20px;
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
  
  h6 {
    font-size: 1.6rem;
  }
`;

const DataContainer = styled.div`
  
`;

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  deliveryMethod: DeliveryMethod;
  name: string;
  address: string;
  phoneNumber: string;
  cardType: string;
  cardNumber: string;
  channel: any;
}

export default function DeliveryPaymentContent(props: Props) {
  const { deliveryMethod, name, address, phoneNumber, cardType, cardNumber, channel } = props;

  return (
    <Container>

      <ContentContainer>

        <IconContainer>
          <LocationIcon />
        </IconContainer>

        <TitleContainer>
          {deliveryMethod == "PICKUP_FROM_STORE" && (
            <h6>Customer Information</h6>
          )}
          {deliveryMethod == "DOOR_DELIVERY" && (
            <h6>Delivery Address</h6>
          )}
        </TitleContainer>

        <DataContainer>
          <p>{name}</p>
          <p>{address}</p>
          <p>{phoneNumber}</p>
        </DataContainer>

      </ContentContainer>


      <ContentContainer>

        <IconContainer>
          <DeliveryIcon />
        </IconContainer>

        <TitleContainer>
          <h6>Payment Information</h6>
        </TitleContainer>

        <DataContainer>
          {(channel === "WEB" || channel === "API") && (
            <>
              {/* <DataRow>
                <p>Method</p>
                <p>{cardType}</p>
              </DataRow> */}

              <DataRow>
                <p>Card Number</p>
                <p>{cardNumber}</p>
              </DataRow>
            </>
          )}

          {channel != "WEB" && channel != "API" && (
            <DataRow>
              <p>{channel}</p>
            </DataRow>
          )}

        </DataContainer>

      </ContentContainer>

    </Container>
  );
} 