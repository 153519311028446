import * as React from "react";

import DropdownWrapper from "components/DropdownWrapper";
import {
  Categories,
  FilterHeaderButton,
  MobileFilterContainer,
  MobileFilterDropdown,
} from "./styles";
import { ReactComponent as DownArrowIcon } from "../../../assets/icons/down-arrow.svg";
import { Button } from "components/Button";
import ProductGroup from "entities/ProductGroup";
import Checkbox from "components/Checkbox";

interface Props {
  categories: ProductGroup[];
  value: number[];
  onChange: (v: number[]) => void;
}
export default function MobileCategoryFilter(props: Props) {
  const { categories, onChange, value } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleFilter = () => setIsOpen((v) => !v);

  const [selectedCategories, setSelctedCategories] = React.useState<number[]>(
    value
  );

  const onCategorySelcted = (category: ProductGroup) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setSelctedCategories((s) => [...s, category.id]);
    } else {
      setSelctedCategories((s) => s.filter((c) => c !== category.id));
    }
  };

  React.useEffect(() => {
    setSelctedCategories(value);
  }, [value]);

  return (
    <MobileFilterContainer>
      <DropdownWrapper
        showDropdown={isOpen}
        dropdownContainerClassName="dropdown-container"
        outerClickHandler={() => setIsOpen(false)}
        controlContent={
          <FilterHeaderButton
            onClick={toggleFilter}
            className={`mobile ${isOpen ? "close" : ""}`}
          >
            <span>Categories</span>
            <DownArrowIcon />
          </FilterHeaderButton>
        }
        dropdownContent={
          <MobileFilterDropdown>
            <Categories className="categories">
              {categories.map(
                (category) =>
                  category.name.toLowerCase() !== "default" && (
                    <div key={category.id} className="category-item">
                      <Checkbox
                        containerClassname="checkbox"
                        label={category.name}
                        checked={selectedCategories.indexOf(category.id) !== -1}
                        value={category.id}
                        onChange={onCategorySelcted(category)}
                      />
                    </div>
                  )
              )}
            </Categories>
            <div className="footer">
              <Button
                color="SECONDARY"
                text="Clear"
                onClick={() => setSelctedCategories([])}
              />
              <Button
                color="PRIMARY"
                text="Apply filter"
                onClick={() => onChange(selectedCategories)}
              />
            </div>
          </MobileFilterDropdown>
        }
      />
    </MobileFilterContainer>
  );
}
