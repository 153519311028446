import { SignInRequest, SignInResponse } from '../../../repositories/AuthRepository';

import {
  TriggerSignInUser,
  TRIGGER_SIGN_IN,

  SignInPending,
  SIGN_IN_PENDING,

  SignInSuccess,
  SIGN_IN_SUCCESS,

  SignInError,
  SIGN_IN_ERROR
} from './types';

export const triggerSignInUser = (payload: SignInRequest): TriggerSignInUser => ({
  type: TRIGGER_SIGN_IN,
  payload
});

export const signInPending = (): SignInPending => ({
  type: SIGN_IN_PENDING,
});

export const signInSuccess = (signInResponse: SignInResponse): SignInSuccess => ({
  type: SIGN_IN_SUCCESS,
  payload: { signInResponse }
});

export const signInError = (): SignInError => ({
  type: SIGN_IN_ERROR,
});
