import React from 'react';
import styled, { css } from 'styled-components';
import AsyncImage from '../../components/AsyncImage';

import { Button } from '../../components/Button';
import { ScreenSize, ColorTheme } from '../../styling/constants';
import { getFormattedPrice } from '../../util/currencyUtil';
import { SkeletonBox, SkeletonText } from '../../components/Skeleton';
import StatusBadge from '../StatusBadge';
import { StoreProduct } from '../../entities/StoreProduct';
import { Link } from 'react-router-dom';
import { AppState } from 'store/RootReducer';
import { connect, useSelector } from 'react-redux';
import { WhiteLabelAcquirerConfigResponse } from 'entities/StoreDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface ImageContainerProps {
  compressedView: boolean;
}

const ImageContainer = styled(Link) <ImageContainerProps>`
  display: block;
  position: relative;
  height: 250px;
  margin-bottom: 20px;
  cursor: pointer;

  ${({ compressedView }) => compressedView && css`
    height: 120px;
  `}

  @media (min-width: ${ScreenSize.XS}px) {
    height: 260px;

    ${({ compressedView }) => compressedView && css`
      height: 250px;
    `}
  }
`;



const StatusBadgesContainer = styled.div`
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
`;

const NameContainer = styled.div`
  margin-bottom: 10px;

  a {
    cursor: pointer;
    color: ${ColorTheme.primaryColorLight};
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 100;
  }
`;

const AmountContainer = styled.div`
  margin-bottom: 20px;

  p {
    font-weight: bold;
    color: #4f4f4f;
    font-size: 1.7rem;
    line-height: 1.8rem;
  }
`;

const ControlSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 45px;

  @media (min-width: ${ScreenSize.XS}px) {
    width: 140px;
    height: 40px;
  }
`;


interface Props {
  data?: StoreProduct;
  compressedView: boolean;
  productUrlEndpoint?: string;
  viewDetailsHandler?: () => void;
  addToCartHandler?: () => void;
}





const StoreProductEntry = (props: Props) => {
  const {
    data,
    compressedView,
    productUrlEndpoint,
    viewDetailsHandler,
    addToCartHandler
  } = props;

  const { whiteLabelAcquirer } = useSelector((state: AppState) => ({
    whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
  }));

  const formatProductName = (name: string) => {
    if (name.length <= 40) return name;

    return `${name.slice(0, 40)}...`;
  }

  if (!data) {
    return (
      <Container>
        <ImageContainer to="#" compressedView={compressedView}>
          <SkeletonBox />
        </ImageContainer>

        <NameContainer>
          <Link to="#"><SkeletonText style={{ width: '140px' }} /></Link>
        </NameContainer>

        <AmountContainer>
          <p><SkeletonText style={{ width: '85px' }} /></p>
        </AmountContainer>

        <ControlSection>

          <ButtonContainer>
            <SkeletonBox />
          </ButtonContainer>

        </ControlSection>
      </Container>
    );
  }

  const { name, price, currencyCode, imageFileUrl, quantity, hasVariant } = data;

  return (
    <Container>
      <ImageContainer to={productUrlEndpoint || '#'} compressedView={compressedView}>

        <StatusBadgesContainer>
          {quantity === 0 && (
            <StatusBadge color="RED" text="SOLD OUT" />
          )}
        </StatusBadgesContainer>

        <AsyncImage
          src={imageFileUrl}
          alt="Product Image"
          imageStyle={{ objectFit: 'contain' }}
        />
      </ImageContainer>

      <NameContainer>
        <Link to={productUrlEndpoint || '#'} style={whiteLabelAcquirer && { color: whiteLabelAcquirer.secondaryColor }}>
          {formatProductName(name)}
      </Link>
      </NameContainer>

      <AmountContainer>
        <p>
          {getFormattedPrice(price, currencyCode)}
        </p>
      </AmountContainer>

      <ControlSection>
        <ButtonContainer>
          <Button
            text={hasVariant ? 'Choose Options' : 'Add to Cart'}
            color="PRIMARY"
            onClick={hasVariant ? viewDetailsHandler : addToCartHandler}
            disabled={quantity === 0}
            containerStyle={{
              width: '100%',
              height: '100%',
            }}
          />
        </ButtonContainer>
      </ControlSection>

    </Container>
  );
};

export {StoreProductEntry}

