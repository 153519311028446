import {
  FETCH_PRODUCT_DETAILS_PENDING,
  FETCH_PRODUCT_DETAILS_SUCCESS,
  FETCH_PRODUCT_DETAILS_ERROR,
  RESET_STORE_PRODUCT_VIEW,
  StoreProductViewState, StoreProductViewActionType
} from "./types";

const initialState: StoreProductViewState = {
  productDetails: null,
  fetchingProductDetails: true,
  fetchingProductDetailsError: false
}

export function StoreProductViewReducer(
  state: StoreProductViewState = initialState,
  action: StoreProductViewActionType
): StoreProductViewState {
  switch (action.type) {

    case FETCH_PRODUCT_DETAILS_PENDING: {
      return {
        ...state,
        fetchingProductDetails: true
      };
    }

    case FETCH_PRODUCT_DETAILS_SUCCESS: {
      const { payload: { productDetails } } = action;

      return {
        ...state,
        productDetails,
        fetchingProductDetails: false,
        fetchingProductDetailsError: false
      };
    }

    case FETCH_PRODUCT_DETAILS_ERROR: {
      return {
        ...state,
        fetchingProductDetails: false,
        fetchingProductDetailsError: true
      };
    }

    case RESET_STORE_PRODUCT_VIEW: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
