import React from 'react';
import { Button } from './Button';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';

interface Props {
  text: string;
  onClick: () => void;
}

export default function BackButton(props: Props) {
  const { text, onClick } = props;

  return (
    <Button
      type="OUTLINE"
      color="PRIMARY"
      onClick={onClick}
      text={text}
      icon={<BackIcon />}
    />
  );
}