import { WhiteLabelAcquirerConfigResponse } from "entities/StoreDetails";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { AppState } from "store/RootReducer";
import styled from "styled-components";
import AsyncImage from "../../../components/AsyncImage";
import { ColorTheme } from "../../../styling/constants";
import { getFormattedPrice } from "../../../util/currencyUtil";
import VariantValue from "../../VariantValue";


const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const QuantityContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  min-width: 25px;
  height: 23px;
  padding-left: 3px;
  padding-right: 3px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 10;
  border: 1px solid #b9b9b9;
  border-radius: 3px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.13);  

  p {
    font-size: 1.4rem;
    color: black;
  }
`;

const ImageContainer = styled.div`
  height: 80px;
  width: 80px;
  margin-right: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  margin-right: 20px;
`;

const NameContainer = styled.div`
  margin-bottom: 5px;

  h6 {
    font-size: 1.6rem;
    color: ${ColorTheme.primaryColorLight};
    font-weight: 100;
  }
`;

const AmountContainer = styled.div`
  margin-bottom: 5px;
  
  p {
    font-size: 1.4rem;
    color: #000000;
    font-weight: 600;
    letter-spacing: 1px;
  }
`;

const VariationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-end;
`;

const Variation = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 15px;

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;

    &:last-child {
      margin-left: 5px;
    }
  }
`;

interface Props {
  name: string;
  price: number;
  currencyCode: string;
  quantity: number;
  variantName?: string;
  variantValue?: string;
  imageSrcUrl?: string;
  whiteLabelAcquirer?: WhiteLabelAcquirerConfigResponse
}


function OrderItem(props: Props) {
  const { name, price, currencyCode, quantity, variantName, variantValue, imageSrcUrl, whiteLabelAcquirer } = props;

  return (
    <Container>

      {quantity > 1 && (
        <QuantityContainer>
          <p>{quantity}</p>
        </QuantityContainer>
      )}

      <ImageContainer>
        <AsyncImage
          src={imageSrcUrl}
          alt="Product Image"
          imageStyle={{ objectFit: 'contain' }}
        />
      </ImageContainer>

      <DetailsContainer>

        <AmountContainer>
          <p>{getFormattedPrice(price, currencyCode)}</p>
        </AmountContainer>

        <NameContainer>
          <h6 style={whiteLabelAcquirer && {color: whiteLabelAcquirer.primaryColor }}>{name}</h6>
        </NameContainer>

        {variantName && variantValue && (
          <VariationsContainer>
            <VariantValue
              variantName={variantName}
              variantValue={variantValue}
            />
          </VariationsContainer>
        )}

      </DetailsContainer>

    </Container>
  );
}

const mapStateToProps = (state: AppState) => ({
  whiteLabelAcquirer: state.store.storeDetails?.whiteLabelAcquirer
})

export default connect(mapStateToProps)(OrderItem);