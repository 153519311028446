import React from 'react';
import styled from 'styled-components';

interface Props {
  isGuestCheckout: boolean;
  customerEmail?: string;
  editControlHandler: () => void;
}

const Container = styled.div``;

export default function CompletedContent(props: Props) {
  const { isGuestCheckout, customerEmail, editControlHandler } = props
  return (
    <Container>
      {isGuestCheckout && (
        <p>Proceeding as Guest. <a href="#" onClick={editControlHandler}>Change</a></p>
      )}

      {!isGuestCheckout && (
        <p>Signed in as {customerEmail}. <a href="#" onClick={editControlHandler}>Sign out</a></p>
      )}
    </Container>
  );
}