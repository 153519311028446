import { GetProductsRequest, GetProductsResponse } from "../../../repositories/ProductRepository";

import {
  TRIGGER_FETCH_PRODUCT_DETAILS,
  TriggerFetchProductDetails,

  FETCH_PRODUCT_DETAILS_PENDING,
  FetchProductDetailsPending,

  FETCH_PRODUCT_DETAILS_SUCCESS,
  FetchProductDetailsSuccess,

  FETCH_PRODUCT_DETAILS_ERROR,
  FetchProductDetailsError,

  RESET_STORE_PRODUCT_VIEW,
  ResetStoreProductView
} from "./types";

import { StoreProductDetails } from "../../../entities/StoreProductDetails";


export const triggerFetchProductDetails = (productUrlEndpoint: string): TriggerFetchProductDetails => {
  return {
    type: TRIGGER_FETCH_PRODUCT_DETAILS,
    payload: { productUrlEndpoint }
  };
};

export const fetchProductDetailsPending = (): FetchProductDetailsPending => {
  return {
    type: FETCH_PRODUCT_DETAILS_PENDING
  };
};

export const fetchProductDetailsSuccess = (
  productDetails: StoreProductDetails
): FetchProductDetailsSuccess => {

  return {
    type: FETCH_PRODUCT_DETAILS_SUCCESS,
    payload: { productDetails }
  };
};

export const fetchProductDetailsError = (): FetchProductDetailsError => {
  return {
    type: FETCH_PRODUCT_DETAILS_ERROR
  };
};

export const resetStoreProductView = (): ResetStoreProductView => {
  return {
    type: RESET_STORE_PRODUCT_VIEW
  };
};
