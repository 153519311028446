import React, { useState } from 'react';
import styled from "styled-components";
import { ColorTheme, PAGE_SIDE_PADDING, PAGE_TOP_PADDING, ScreenSize } from '../styling/constants';

export const RouteView = styled.div`
  width: 100%;
  padding: ${PAGE_TOP_PADDING}px ${PAGE_SIDE_PADDING}px;
`;


export const FormControl = styled.div`
  margin-bottom: 20px;
`;

export const FormControlGroup = styled.div`
  flex-direction: column;
  flex-direction: row;

  @media (min-width: ${ScreenSize.XS}px) {
    display: flex;

    & ${FormControl} {
      width: 50%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }  
`;

export const FormControlLabel = styled.div`
  margin-bottom: 8px;

  p {
    font-size: 1.4rem;
    color: ${ColorTheme.primaryColorDark};
  }
`;
