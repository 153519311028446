export const TRIGGER_RESTORE_CART_ITEMS = 'CART.TRIGGER_RESTORE_CART_ITEMS';
export const RESTORE_CART_ITEMS = 'CART.RESTORE_CART_ITEMS';

export const SET_CART_ITEMS = 'CART.SET_CART_ITEMS';

export const TRIGGER_ADD_CART_ITEM = 'CART.TRIGGER_ADD_CART_ITEM';
export const ADD_CART_ITEM_PENDING = 'CART.ADD_CART_ITEM_PENDING';
export const ADD_CART_ITEM_SUCCESS = 'CART.ADD_CART_ITEM_SUCCESS';

export const ADD_CART_ITEM = 'CART.ADD_CART_ITEM';
export const ADD_CART_ITEMS = 'CART.ADD_CART_ITEMS';
export const REMOVE_CART_ITEM = 'CART.REMOVE_CART_ITEM';
export const SET_CART_ITEM_QUANTITY = 'CART.SET_CART_ITEM_QUANTITY';
export const CLEAR_CART = 'CART.CLEAR_CART';

export const TRIGGER_SHOW_ADD_TO_CART_NOTIFICATION = 'CART.TRIGGER_SHOW_ADD_TO_CART_NOTIFICATION';
export const SET_ADD_TO_CART_NOTIFICATION = 'CART.SET_ADD_TO_CART_NOTIFICATION';
export const CLEAR_ADD_TO_CART_NOTIFICATION = 'CART.CLEAR_ADD_TO_CART_NOTIFICATION';

interface CartProductData {
  id: number;
  productUrlEndpoint: string;
  name: string;
  productImageUrl?: string;
}

export interface CartItem {
  id: string;
  quantity: number;
  currencyCode: string;
  productPrice: number;
  productData: CartProductData;
  variantName?: string;
  variantValue?: string;
}

export interface TriggerRestoreCartItems {
  type: typeof TRIGGER_RESTORE_CART_ITEMS;
  payload: {
    storeUrlEndpoint: string;
  }
}

export interface RestoreCartItems {
  type: typeof RESTORE_CART_ITEMS;
  payload: {
    cartItems: CartItem[];
  }
}

export interface SetCartItems {
  type: typeof SET_CART_ITEMS;
  payload: {
    cartItems: CartItem[];
  }
}

export interface TriggerAddCartItemPayload {
  quantity: number;
  currencyCode: string;
  productPrice: number;
  productData: CartProductData;
  variantName?: string;
  variantValue?: string;
}

export interface TriggerAddCartItem {
  type: typeof TRIGGER_ADD_CART_ITEM;
  payload: TriggerAddCartItemPayload;
}

export interface AddCartItem {
  type: typeof ADD_CART_ITEM;
  payload: {
    cartItem: CartItem;
  };
}

export interface AddCartItems {
  type: typeof ADD_CART_ITEMS;
  payload: {
    cartItems: CartItem[];
  };
}

export interface RemoveCartItem {
  type: typeof REMOVE_CART_ITEM;
  payload: {
    cartItemId: string;
  };
}

export interface SetCartItemQuantity {
  type: typeof SET_CART_ITEM_QUANTITY;
  payload: {
    cartItemId: string;
    quantity: number;
  };
}

export interface ClearCart {
  type: typeof CLEAR_CART;
}

export interface TriggerShowAddToCartNotification {
  type: typeof TRIGGER_SHOW_ADD_TO_CART_NOTIFICATION;
  payload: {
    cartItem: CartItem;
  };
}

export interface SetAddToCartNotification {
  type: typeof SET_ADD_TO_CART_NOTIFICATION;
  payload: {
    cartItem: CartItem;
  };
}

export interface ClearAddToCartNotification {
  type: typeof CLEAR_ADD_TO_CART_NOTIFICATION;
}



export type CartActionType =
  TriggerRestoreCartItems
  | RestoreCartItems
  | SetCartItems
  | TriggerAddCartItem
  | AddCartItem
  | AddCartItems
  | RemoveCartItem
  | SetCartItemQuantity
  | ClearCart
  | TriggerShowAddToCartNotification
  | SetAddToCartNotification
  | ClearAddToCartNotification;

export interface CartState {
  cartItems: CartItem[];
  cartInitialised: boolean;
  addToCartNotificationDetails: CartItem | null;
}